import { useForm } from "react-hook-form";
import * as yup from "yup";
import { todayAtMidnight } from "../../utils/dateFormatters";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { yupResolver } from "@hookform/resolvers/yup";

export type GigFormValues = {
    title: string,
    hasClientHistory: string
    industryId: string
    opportunityLink?: string
    skills: { id: string, name: string }[]
    languageId: string
    isAudienceExecutive: string
    delegateInformation?: string
    isDesignFeeTypeRequired: string
    designFeeTypeHours?: number
    isConsultancyFeeTypeRequired: string
    consultancyFeeTypeHours?: number
    isPrepAndEvalFeeTypeRequired: string
    prepAndEvalFeeTypeHours?: number
    expectedNumberParticipants: number
    description?: string,
    startDate: Date | null,
    expectedDurationId: number,
    gigStatusId: number,
    deliveryTypeId: number,
    locationId: string | null,
    localityId: string | null,
    notes?: string,
    ianaId?: string
};

export const buildGigFormValidationSchemaFields = ({
    title: yup.string().max(128, "Name must be less than 128 characters.").required("Name is required."),
    hasClientHistory: yup.string().required("This field is required."),
    industryId: yup.string().required("Please select Industry"),
    opportunityLink: yup.string(),
    isAudienceExecutive: yup.string().required(),
    languageId: yup.string().required(),
    delegateInformation: yup.string(),
    isDesignFeeTypeRequired: yup.string().required("This field is required."),
    designFeeTypeHours: yup.number().when("isDesignFeeTypeRequired", {
        is: "true",
        then: yup.number()
            .typeError("Number of hours is required.")
            .moreThan(0, "Number of hours must be 1 or greater.")
            .lessThan(101, "Number of hours must not exceed 100.")
            .required("Number of hours is required"),
        otherwise: yup.number().optional().nullable(),
    }),
    isConsultancyFeeTypeRequired: yup.string().required("This field is required."),
    consultancyFeeTypeHours: yup.number().when("isConsultancyFeeTypeRequired", {
        is: "true",
        then: yup.number()
            .typeError("Number of hours is required.")
            .moreThan(0, "Number of hours must be 1 or greater.")
            .lessThan(101, "Number of hours must not exceed 100.")
            .required("Number of hours is required"), 
        otherwise: yup.number().optional().nullable(),
    }),
    isPrepAndEvalFeeTypeRequired: yup.string().required("This field is required."),
    prepAndEvalFeeTypeHours: yup.number().when("isPrepAndEvalFeeTypeRequired", {
        is: "true",
        then: yup.number()
            .typeError("Number of hours is required.")
            .moreThan(0, "Number of hours must be 1 or greater.")
            .lessThan(101, "Number of hours must not exceed 100.")
            .required("Number of hours is required"),
        otherwise: yup.number().optional().nullable(),
    }),
    expectedNumberParticipants: yup.number()
        .typeError("Number of participants is required")
        .min(1, "Number of participants must be 1 or greater.")
        .lessThan(1001, "Number must not exceed 1000")
        .required("Number of participants is required"),
    description: yup.string(),
    expectedDurationId: yup.number().required().min(0, "Duration is required."),
    deliveryTypeId: yup.number().required().min(0, "Delivery format is required."),
    startDate: yup.date()
        .required("Start date is required.")
        .min(todayAtMidnight(), "Start date cannot be in the past.")
        .nullable()
        .typeError("Start date must be a valid date."),
    deadlineDate: yup.date().test("deadline-greater-than-start", (value, context) => {
        if (!value) return true;

        if (value <= context.parent.startDate) {
            return context.createError({ message: "Deadline must be after start." });
        }

        return true;
    }).nullable().optional().typeError("Deadline must be a valid date."),
    skills: yup
        .array()
        .length(1, "You must select an Insights workshop type.")
        .of(
            yup.object().shape({
                id: yup.string(),
                name: yup.string(),
            }),
        ),
    locationId: yup.string().required("Country is required."),
    localityId: yup.string().required("City is required."),
    notes: yup.string().optional(),
    ianaId: yup.string().optional(),
});

const schema = yup.object(buildGigFormValidationSchemaFields);

export const useGigForm = (
    defaultValues?: GigFormValues,
) => {
    return useForm<GigFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            title: defaultValues?.title || "",
            hasClientHistory: defaultValues?.hasClientHistory || "",
            industryId: defaultValues?.industryId ? defaultValues.industryId : "",
            opportunityLink: defaultValues?.opportunityLink || "",
            skills: defaultValues?.skills || [],
            languageId: defaultValues?.languageId || "",
            isAudienceExecutive: defaultValues?.isAudienceExecutive || "",
            delegateInformation: defaultValues?.delegateInformation || "",
            startDate: defaultValues?.startDate || null,
            isDesignFeeTypeRequired: defaultValues?.isDesignFeeTypeRequired || "",
            designFeeTypeHours: defaultValues?.designFeeTypeHours || undefined,
            isConsultancyFeeTypeRequired: defaultValues?.isConsultancyFeeTypeRequired || "",
            consultancyFeeTypeHours: defaultValues?.consultancyFeeTypeHours || undefined,
            isPrepAndEvalFeeTypeRequired: defaultValues?.isPrepAndEvalFeeTypeRequired || "",
            prepAndEvalFeeTypeHours: defaultValues?.prepAndEvalFeeTypeHours || undefined,
            expectedNumberParticipants: defaultValues?.expectedNumberParticipants || 0,
            description: defaultValues?.description || "",
            expectedDurationId: defaultIfUndefined(defaultValues?.expectedDurationId, -1),
            deliveryTypeId: defaultIfUndefined(defaultValues?.deliveryTypeId, -1),
            locationId: defaultValues?.locationId ? defaultValues?.locationId : "",
            localityId: defaultValues?.localityId ? defaultValues?.localityId : "",
            notes: defaultValues?.notes || "",
            gigStatusId: defaultValues?.gigStatusId || 1,
            ianaId: defaultValues?.ianaId || "",
        },
    });
};