import { hasClaim } from "../auth/hasClaim";
import useCurrentUser from "../auth/useCurrentUser";
import { GigDetails, deliveryTypeOptions } from "../models/app/gig";
import { UserRole } from "../models/app/userMe";
import AuditDataList from "./AuditData/AuditDataList";
import Card, { CardDataFieldLabel, CardDataField } from "./Card";
import TitleUnderline from "./TitleUnderline";
import Tooltip from "./Tooltip";
import Typography from "./Typography";
import { formatLocationDateTimeToString } from "../utils/dateFormatters";
import DateDisplayLocale from "./DateDisplayLocale";
import GigCloneButton from "./GigCloneButton";

export type GigDetailsCardProps = {
    gig: GigDetails
}

const GigDetailsCard = ({
    gig,
}: GigDetailsCardProps) => {
    const { claims } = useCurrentUser();
    const isDefaultClient = hasClaim(UserRole.Default, claims);
    const isResources = hasClaim(UserRole.Resources, claims);
    const hasCustomRequirements = gig.isDesignFeeTypeRequired || gig.isConsultancyFeeTypeRequired || gig.isPrepAndEvalFeeTypeRequired;

    return (
        <Card key={gig.id} className="space-y-6">
            <div className="md:flex break-words space-y-8 md:space-y-0">
                <div className="w-full md:mr-8 overflow-hidden relative space-y-6">
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <div className="flex flex-col-reverse md:flex-row gap-6 items-start md:items-center">
                                <Typography variant="display-medium" component="h1">{gig?.title}</Typography>
                                {isResources && <GigCloneButton gig={gig} />}
                            </div>
                            <Typography variant="title-medium" component="h3">{gig.skills[0]?.name}</Typography>
                            <Typography variant="title-small" component="h3">{`${gig.location?.locality.locality}${gig.location?.locality.region ? ` (${gig.location?.locality.region})` : ""}, ${gig.location?.country}`}</Typography>
                        </div>
                        <div className="space-y-4">
                            <div>
                                <div className="flex flex-row gap-2">
                                    <CardDataFieldLabel>Confirmation status: </CardDataFieldLabel><Typography variant="body" component="p">{gig.isConfirmed ? "Confirmed" : "Awaiting confirmation"}</Typography>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <CardDataFieldLabel>Start date: </CardDataFieldLabel><Typography variant="body" component="p">
                                        <div className="flex items-center">
                                            {formatLocationDateTimeToString(gig.startDate, gig.ianaId)}
                                            <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                                        </div>
                                    </Typography>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <CardDataFieldLabel>Duration: </CardDataFieldLabel><Typography variant="body" component="p">{gig.expectedDurationLabel}</Typography>
                                </div>
                            </div>
                            {gig.description && (
                                <div className="flex flex-col">
                                    <CardDataFieldLabel>Program Objectives: </CardDataFieldLabel><Typography variant="body" component="p" className="whitespace-pre-wrap">{gig.description}</Typography>
                                </div>
                            )}

                            {gig.delegateInformation && (
                                <div className="flex flex-col">
                                    <CardDataFieldLabel>Delegate Information: </CardDataFieldLabel>
                                    <Typography variant="body" component="p" className="whitespace-pre-wrap">
                                        {gig.delegateInformation}
                                    </Typography>
                                </div>

                            )}
                            {hasCustomRequirements && (
                                <div className="flex flex-col">
                                    <CardDataFieldLabel>Event Customisation Requirements: </CardDataFieldLabel>
                                    <ol className="list-disc ml-4 gap-2">
                                        {gig.isDesignFeeTypeRequired && (
                                            <li>
                                                <div className="flex flex-row gap-2">
                                                    <CardDataFieldLabel textClass="font-normal">Customisation of content and/or addition of new content</CardDataFieldLabel>
                                                    <Typography variant="body" component="p">{`(${gig.designFeeTypeHours} hours)`}</Typography>
                                                </div>
                                            </li>
                                        )}
                                        {gig.isConsultancyFeeTypeRequired && (
                                            <li>
                                                <div className="flex flex-row gap-2">
                                                    <CardDataFieldLabel textClass="font-normal">Consultancy time required with client</CardDataFieldLabel>
                                                    <Typography variant="body" component="p">{`(${gig.consultancyFeeTypeHours} hours)`}</Typography>
                                                </div>
                                            </li>
                                        )}
                                        {gig.isPrepAndEvalFeeTypeRequired && (
                                            <li>
                                                <div className="flex flex-row gap-2">
                                                    <CardDataFieldLabel textClass="font-normal">Additional prep & evaluation time required</CardDataFieldLabel>
                                                    <Typography variant="body" component="p">{`(${gig.prepAndEvalFeeTypeHours} hours)`}</Typography>
                                                </div>
                                            </li>
                                        )}
                                    </ol>
                                </div>
                            )}
                        </div>

                        {isDefaultClient && (
                            <>
                                <TitleUnderline fullWidth />
                                <div className="space-y-2">
                                    <Typography variant="body" component="p" className="underline">Addtional information:</Typography>
                                    <div>
                                        <div className="flex flex-row gap-2">
                                            <CardDataFieldLabel>Client history: </CardDataFieldLabel><Typography variant="body" component="p">{gig.hasClientHistory ? "Yes" : "No"}</Typography>
                                        </div>
                                        {gig.opportunityLink && <div className="flex flex-row gap-2"><CardDataFieldLabel>Opportunity link: </CardDataFieldLabel><Typography variant="body" component="p">{gig.opportunityLink}</Typography></div>}
                                    </div>
                                    {gig.notes && <div className="flex flex-col">
                                        <CardDataFieldLabel>Notes: </CardDataFieldLabel><Typography variant="body" component="p">{gig.notes}</Typography>
                                    </div>}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="md:ml-auto md:self-start md:shrink-0 space-y-8">
                    <div className="child:p-4 child:border-surface border border-surface rounded-lg">
                        <CardDataField className="border-b" label="Industry">{gig.industry?.name}</CardDataField>
                        <CardDataField className="border-b" label="Delivery Format">{deliveryTypeOptions[gig.deliveryTypeId].label}</CardDataField>
                        <CardDataField className="border-b" label="No. of participants">{gig.expectedNumberParticipants}</CardDataField>
                        <CardDataField className="border-b" label="Executive Audience">{gig.isAudienceExecutive ? "Yes" : "No"}</CardDataField>
                        <CardDataField className="" label="Language">{gig.language?.name}</CardDataField>
                    </div>
                </div>
            </div>

            <div className="border-b border-surface" />
            <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4" />
        </Card>

    );
};

export default GigDetailsCard;
