import { SortOrder } from "../../api/common/sortOrder";
import { CalendarInformation } from "../api/nylas";
import { ApiTalentWheelPositionDto } from "../api/talent";
import { ApiTalentDeliveryTypeDto } from "../api/talentDeliveryType";
import { ApiTalentLanguageDto } from "../api/talentLanguage";
import { ApiTalentLocationDto } from "../api/talentPersonalInformation";
import { AddedExtrasDto } from "./addedExtras";
import { TalentBadgeDetails } from "./badge";
import { TalentCertificationDto } from "./talentCertification";
import { TalentIndustrialExperienceDto } from "./talentIndustrialExperience";

export type TalentSkillDto = {
    skillId: string
    skillName: string
}

export type TalentSummary = {
    id: string
    firstName: string
    lastName: string
    fullName: string
    tradingName?: string
    email: string
    profileImageThumbnailUrl?: string
    skills: TalentSkillDto[]
    completedGigsCount: number
    agency?: TalentAgencyDto
    badges: TalentBadgeDetails[]
    wheelPosition?: ApiTalentWheelPositionDto
    knownCompetitors?: string
    gigCompletedHours: number
    isCalendarConnected: boolean
}

export type TalentDetails = Omit<TalentSummary, "profileImageThumbnailUrl"> & {
    profileImageUrl?: string
    profileImageBase64Content?: string
    portfolioUrl?: string
    aboutMe?: string
    ongoingGigsCount: number
    cancelledGigsCount: number
    memberSince: string
    addedExtras: AddedExtrasDto
    certifications: TalentCertificationDto[]
    industrialExperiences: TalentIndustrialExperienceDto[]
    profileVideoUrls: string[]
    badges: TalentBadgeDetails[]
    location?: ApiTalentLocationDto
    languages?: ApiTalentLanguageDto[]
    deliveryTypes?: ApiTalentDeliveryTypeDto[]
    phoneNumber?: string
    genderTypeId?: number
    insuranceExpiryDate?: string
    notes?: string
    ratePack?: IdNameDto
    competencyLevel?: IdNameDto
    documentationLanguage?: IdNameDto
    knownCompetitors?: string
    rlc?: string
    isQualiopiRequired?: boolean
    gigCompletedHours?: number
    professionalBackground?: string
};

export type IdNameDto = {
    id: string;
    name: string;
  }

export type TalentAgencyDto = {
    id: string
    name: string
    description?: string
    url?: string
}

const talentOrderByFields = [
    { value: "FirstName", label: "First Name" },
    { value: "LastName", label: "Last Name" },
    { value: "TradingName", label: "Company Name" },
] as const;

export type TalentOrderByFieldKeys = typeof talentOrderByFields[number]["value"];

export const talentOrderByOptions = talentOrderByFields.flatMap(({ value, label }): {
    value: `${TalentOrderByFieldKeys}:${SortOrder}`
    label: string
}[] => {
    return [
        {
            value: `${value}:asc`,
            label: `Sort by ${label.toLowerCase()} ASC`,
        },
        {
            value: `${value}:desc`,
            label: `Sort by ${label.toLowerCase()} DESC`,
        },
    ];
});

export const genderTypeOptions = [
    { label: "Female", value: 0 },
    { label: "Male", value: 1 },
    { label: "Other", value: 2 },
    { label: "Prefer not to say", value: 3 },
];