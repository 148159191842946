import { useEffect, useState } from "react";


export type MatchingProgressStepProps = {
    title: string;
    isActive: boolean;
};

export const MatchingProgressStep = ({
    title,
    isActive,
}: MatchingProgressStepProps) => {
    const [loadingDots, setLoadingDots] = useState(1);

    useEffect(() => {
        let count = 0; 
        const interval = setInterval(() => {
            count++;
            if (count > 3) count = 0;
            setLoadingDots(count);
        }, 750); 

        return () => clearInterval(interval); 
    }, []);

    return (
        <div className="font-bold flex">
            {isActive && (
                <>
                    <span>{title}</span>
                    <span className="w-[15px] text-left flex flex-nowrap">
                        {".".repeat(loadingDots)}
                    </span>
                </>
            )}
        </div>
    );
};

export default MatchingProgressStep;
