import clsx from "clsx";
import Card from "../../components/Card";
import TalentSkill from "../../components/TalentSkill";
import Typography from "../../components/Typography";
import { TalentDetails } from "../../models/app/talent";
import { getClickableLink } from "../../utils/getClickableLink";
import { Globe, LinkedinLogo } from "phosphor-react";

export type SkillsAndExperienceCardProps = {
    talent: TalentDetails
    className?: string
}

const SkillsAndExperienceCard = ({
    talent,
    className,
}: SkillsAndExperienceCardProps) => {
    return (
        <Card className={clsx("space-y-8", className)}>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">My Insights workshop types</Typography>
                {talent.skills.length === 0 ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="space-y-4">
                        {talent.skills.map(skill => (
                            <TalentSkill key={skill.skillId} {...skill} />
                        ))}
                    </div>
                )}
            </div>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Industry experience</Typography>
                {talent.industrialExperiences.length === 0 ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="space-y-4">
                        {talent.industrialExperiences.map(industrialExperience => (
                            <TalentSkill
                                key={industrialExperience.industrialExperienceId}
                                {...industrialExperience}
                                skillName={industrialExperience.industrialExperienceName}
                            />
                        ))}
                    </div>
                )}
            </div>
            {/* <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">External links</Typography>
                {!talent.addedExtras.linkedInUrl && !talent.addedExtras.portfolioUrl ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="flex flex-col gap-2">
                        {talent.addedExtras.linkedInUrl && 
                            <a target="_blank" className="flex flex-row items-center gap-2 hover:underline" title="LinkedIn profile" href={getClickableLink(talent.addedExtras.linkedInUrl)}>
                                <LinkedinLogo size={32} className="text-primary-text" weight="fill" />
                                LinkedIn
                            </a>
                        }
                        {talent.addedExtras.portfolioUrl && 
                            <a target="_blank" title="Portfolio" className="flex flex-row items-center gap-2 hover:underline" href={getClickableLink(talent.addedExtras.portfolioUrl)}>
                                <Globe size={32} className="text-primary-text" weight="fill" />
                                Portfolio
                            </a>
                        }
                    </div>
                )}
            </div> */}
        </Card>
    );
};

export default SkillsAndExperienceCard;
