import { Client, PushNotification } from "@twilio/conversations";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import config from "../configuration";

export const initFirebaseServiceWorker = async (): Promise<void> => {
    firebase.initializeApp(config.firebase);

    try {
        const registration = await navigator.serviceWorker.register(
            "firebase-messaging-sw.js"
        );
        console.log("ServiceWorker registered with scope:", registration.scope);
    } catch (e) {
        console.error("ServiceWorker registration failed:", e);
    }
};

export const subscribeFirebaseNotifications = async (client: Client): Promise<void> => {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
        console.warn("FcmNotifications: can't request permission:", permission);
        return;
    }

    const messaging = firebase.messaging();
    const fcmToken = await messaging.getToken();
    if (!fcmToken) {
        console.warn("FcmNotifications: can't get fcm token");
        return;
    }

    console.log("FcmNotifications: got fcm token");
    client.setPushRegistrationId("fcm", fcmToken);
    
    messaging.onMessage((payload) => {
        if (client) {
            client.handlePushNotification(payload);
        }
    });
};

export const showNotification = (
    pushNotification: PushNotification,
    title: string,
    onClick: () => void
): void => {
    const notificationOptions = {
        body: pushNotification.body ?? "",
        icon: "favicon.ico"
    };

    const notification = new Notification(title, notificationOptions);

    notification.onclick = () => {
        onClick();
        notification.close();
    };
};