import { Dispatch } from "react";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { Conversation, Participant, Message } from "@twilio/conversations";
import { ReduxMessage } from "../reducers/messageListReducer";
import { NotificationsType } from "../reducers/notificationsReducer";

export const messagesLogin = (token: string) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.LOGIN,
            payload: token,
        });
    };
};

export const addConversation = (conversation: Conversation) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.ADD_CONVERSATION,
            payload: conversation,
        });
    };
};

export const removeConversation = (sid: string) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.REMOVE_CONVERSATION,
            payload: sid,
        });
    };
};

export const updateParticipants = (
    participants: Participant[],
    sid: string
) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.UPDATE_PARTICIPANTS,
            payload: { participants, sid },
        });
    };
};

export const addMessages = (
    channelSid: string,
    messages: (Message | ReduxMessage)[]
) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.ADD_MESSAGES,
            payload: { channelSid, messages },
        });
    };
};

export const updateUnreadMessages = (
    channelSid: string,
    unreadCount: number
) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.UPDATE_UNREAD_MESSAGES,
            payload: { channelSid, unreadCount },
        });
    };
};

export const addNotifications = (notifications: NotificationsType) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.ADD_NOTIFICATIONS,
            payload: notifications,
        });
    };
};
  
export const removeNotifications = (toIndex: number) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.REMOVE_NOTIFICATIONS,
            payload: toIndex,
        });
    };
};

export const updateCurrentConversation = (sid: string) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.UPDATE_CURRENT_CONVERSATION,
            payload: sid,
        });
    };
};

export const addAttachment = (
    channelSid: string,
    messageSid: string,
    mediaSid: string,
    attachment: Blob
) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.ADD_ATTACHMENT,
            payload: { channelSid, messageSid, mediaSid, attachment },
        });
    };
};
  
export const clearAttachments = (channelSid: string, messageSid: string) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.CLEAR_ATTACHMENTS,
            payload: { channelSid, messageSid },
        });
    };
};

export const removeMessages = (channelSid: string, messages: Message[]) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.REMOVE_MESSAGES,
            payload: { channelSid, messages },
        });
    };
};

export const updateLoadingState = (loadingStatus: boolean) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.UPDATE_LOADING_STATE,
            payload: loadingStatus,
        });
    };
};

export const setLastReadIndex = (index: number) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.CONVERSATION_LAST_READ_INDEX,
            payload: index,
        });
    };
};

export const pushMessages = (channelSid: string, messages: Message[]) => {
    return (dispatch: Dispatch<Action>): void => {
        dispatch({
            type: ActionType.PUSH_MESSAGES,
            payload: { channelSid, messages },
        });
    };
};