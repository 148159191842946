import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { MagnifyingGlass, X } from "phosphor-react";

import Button from "../../components/Button";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FindPagePanelSection, { FindPagePanelSectionProps } from "../../components/FindPage/FindPagePanelSection";
import FormCheckboxGroup from "../../components/FormCheckboxGroup";
import SearchableSkillsCheckBoxGroup from "../../components/SearchableSkillsCheckboxGroup";
import { useFindAGigContext } from "../FindAGig/findAGigContext";
import { fetchSkillOptions } from "../../api/skills";
import { deliveryTypeOptions, expectedDurationOptions, gigStatuses } from "../../models/app/gig";
import ClearFiltersButton from "../../components/FindPage/ClearFiltersButton";
import FormTextInput from "../../components/FormTextInput";
import { FormDropdownOption, LocalityDropdownOption } from "../../components/FormDropdown";
import SearchableLocationsCheckBoxGroup from "./SearchableLocationsCheckBoxGroup";
import Chip from "../../components/Chip";
import FormDateInput from "../../components/FormDateInput";
import { formatDateToShortString, parseShortDateString } from "../../utils/formatDateString";
import SortOrderDropdown from "../FindAGig/SortOrderDropdown";

export type FilterDrawerProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

const MyGigsFilterDrawer = ({
    isOpen,
    setIsOpen,
}: FilterDrawerProps) => {
    const { gigTerminologyPlural, talentTerminology } = useOrganizationContext();
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<LocalityDropdownOption<string>[]>([]);
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<FormDropdownOption<string>[]>([]);
    const [refNumberChip, setRefNumberChip] = useState<string | null>(null);
    const [gigTitleChip, setGigTitleChip] = useState<string | null>(null);
    const [createdByChip, setCreatedByChip] = useState<string | null>(null);
    const [inviteCreatedByChip, setInviteCreatedByChip] = useState<string | null>(null);
    const [talentNameChip, setTalentNameChip] = useState<string | null>(null);

    const {
        referenceNumber,
        gigTitle,
        skillIds,
        expectedDurationIds,
        anyActiveFilters,
        locationIds,
        localityIds,
        deliveryTypeIds,
        createdBy,
        inviteCreatedBy,
        talentName,
        gigStatusIds,
        startDate,
        setReferenceNumber,
        setGigTitle,
        clearAllFilters,
        setSkillIds,
        setExpectedDurationIds,
        searchNow,
        setLocalityIds,
        setDeliveryTypeIds,
        setCreatedBy,
        setInviteCreatedBy,
        setTalentName,
        refetchGigs,
        setStartDate,
    } = useFindAGigContext();

    useEffect(function getAllSelectedLocations() {
        const selectedLocations = allLocationOptions.filter(location => locationIds.includes(location.value));
        setSelectedLocations(selectedLocations);
    }, [allLocationOptions, locationIds]);

    useEffect(function setLocalitiesDropdownBasedOnSelectedLocations() {
        const selectedLocalities = selectedLocations
            .flatMap(location => location?.localities || [])
            .sort((a, b) => a.label.localeCompare(b.label));
        setSelectedLocationLocalities(selectedLocalities);
    }, [selectedLocations]);

    useEffect(function updateLocalitiesDropdownOnLocationChange() {
        const updatedLocalityIds = localityIds.filter((localityId: string) => 
            selectedLocations.some(location => 
                location.localities?.some(locality => locality.value === localityId)
            )
        );
        setLocalityIds(updatedLocalityIds);
    }, [locationIds, setLocalityIds, selectedLocations]);

    const handleAddRefNumber = (e?: React.FormEvent) => {
        e?.preventDefault();
        if (refNumberChip) {
            setReferenceNumber(refNumberChip);
            setRefNumberChip(null);
        }
    };

    const handleAddGigTitle = (e?: React.FormEvent) => {
        e?.preventDefault();
        if (gigTitleChip) {
            setGigTitle(gigTitleChip);
            setGigTitleChip(null);
        }
    };

    const handleAddCreatedBy = (e?: React.FormEvent) => {
        e?.preventDefault();
        if (createdByChip) {
            setCreatedBy(createdByChip);
            setCreatedByChip(null);
        }
    };

    const handleAddInviteCreatedBy = (e?: React.FormEvent) => {
        e?.preventDefault();
        if (inviteCreatedByChip) {
            setInviteCreatedBy(inviteCreatedByChip);
            setInviteCreatedByChip(null);
        }
    };

    const handleAddTalentName = (e?: React.FormEvent) => {
        e?.preventDefault();
        if (talentNameChip) {
            setTalentName(talentNameChip);
            setTalentNameChip(null);
        }
    };

    const filterPanelSections: FindPagePanelSectionProps[] = [
        {
            title: "Sort by",
            defaultOpen: true,
            content: <SortOrderDropdown formClassName="!w-full" />
        },
        {
            title: "Insights workshop types",
            subtitle: `(${skillIds.length} selected)`,
            isActive: skillIds.length > 0,
            content: (
                <SearchableSkillsCheckBoxGroup
                    selectedSkillIds={skillIds}
                    searchPlaceholder="Search by workshop type"
                    onChange={setSkillIds}
                    fetchSkills={fetchSkillOptions}
                    accessTokenNotRequired
                />
            )
        },
        {
            title: "Expected duration",
            subtitle: `(${expectedDurationIds.length} selected)`,
            isActive: expectedDurationIds.length > 0,
            content: (
                <FormCheckboxGroup
                    allOptions={expectedDurationOptions}
                    selectedOptionIds={expectedDurationIds}
                    onChange={setExpectedDurationIds}
                />
            )
        },
        {
            title: "Start date",
            subtitle: startDate ? `(${startDate} selected)` : "(No date selected)",
            isActive: startDate !== "",
            content: (
                <FormDateInput
                    id="start-date-input"
                    value={startDate ? parseShortDateString(startDate) : null}
                    onChange={(value) => setStartDate(value ? formatDateToShortString(value) : "")}
                    hideHelpText
                />
            )
        },
        {
            title: "Delivery type",
            subtitle: `(${deliveryTypeIds.length} selected)`,
            isActive: deliveryTypeIds.length > 0,
            content: (
                <FormCheckboxGroup
                    allOptions={deliveryTypeOptions}
                    selectedOptionIds={deliveryTypeIds}
                    onChange={setDeliveryTypeIds}
                    disabled={deliveryTypeIds.length === 1}
                />
            )
        },
        {
            title: "Country",
            subtitle: `(${locationIds.length} selected)`,
            isActive: locationIds.length > 0,
            content: (
                <SearchableLocationsCheckBoxGroup
                    setAllLocationOptions={setAllLocationOptions}
                />
            )
        }
    ];

    if (locationIds.length > 0) {
        filterPanelSections.push({
            title: "City",
            subtitle: `(${localityIds.length} selected)`,
            isActive: localityIds.length > 0,
            content: (
                <FormCheckboxGroup
                    allOptions={selectedLocationLocalities}
                    selectedOptionIds={localityIds}
                    onChange={setLocalityIds}
                    className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar pr-3 -mr-2"
                />
            )
        });
    }

    filterPanelSections.push({
        title: "Client name",
        subtitle: gigTitle ? `(Client name: '${gigTitle}')` : "(Not added)",
        isActive: gigTitle !== "",
        content: (
            <div className="flex flex-col gap-2">
                {!gigTitle ? (
                    <form onSubmit={handleAddGigTitle} className="flex flex-row gap-2">
                        <FormTextInput
                            value={gigTitleChip || ""}
                            placeholder="Type a client name"
                            labelClassName="ml-0 font-bold"
                            onChange={event => setGigTitleChip(event.target.value)}
                            StartAdornmentIcon={MagnifyingGlass}
                        />
                        <Button
                            className="h-fit w-fit mt-auto"
                            variant="secondary"
                            type="submit"
                            onClick={handleAddGigTitle}
                        >
                            Add
                        </Button>
                    </form>
                ) : <Chip className="bg-[#0082b7] !text-white" label={`Client name: ${gigTitle}`} onDelete={() => setGigTitle("")} />}
            </div>
        )
    },
    {
        title: "Created by",
        subtitle: createdBy ? `(Created by: '${createdBy}')` : "(Not added)",
        isActive: createdBy !== "",
        content: (
            <div className="flex flex-col gap-2">
                {!createdBy ? (
                    <form onSubmit={handleAddCreatedBy} className="flex flex-row gap-2">
                        <FormTextInput
                            value={createdByChip || ""}
                            placeholder="Type a name"
                            labelClassName="ml-0 font-bold"
                            onChange={event => setCreatedByChip(event.target.value)}
                            StartAdornmentIcon={MagnifyingGlass}
                        />
                        <Button
                            className="h-fit w-fit mt-auto"
                            variant="secondary"
                            type="submit"
                            onClick={handleAddCreatedBy}
                        >
                            Add
                        </Button>
                    </form>
                ) : <Chip className="bg-[#0082b7] !text-white" label={`Created by: ${createdBy}`} onDelete={() => setCreatedBy("")} />}
            </div>
        )
    },
    {
        title: "Resource team member",
        subtitle: inviteCreatedBy ? `(Resource team member: '${inviteCreatedBy}')` : "(Not added)",
        isActive: inviteCreatedBy !== "",
        content: (
            <div className="flex flex-col gap-2">
                {!inviteCreatedBy ? (
                    <form onSubmit={handleAddInviteCreatedBy} className="flex flex-row gap-2">
                        <FormTextInput
                            value={inviteCreatedByChip || ""}
                            placeholder="Type a name"
                            labelClassName="ml-0 font-bold"
                            onChange={event => setInviteCreatedByChip(event.target.value)}
                            StartAdornmentIcon={MagnifyingGlass}
                        />
                        <Button
                            className="h-fit w-fit mt-auto"
                            variant="secondary"
                            type="submit"
                            onClick={handleAddInviteCreatedBy}
                        >
                            Add
                        </Button>
                    </form>
                ) : <Chip className="bg-[#0082b7] !text-white" label={`Resource team member: ${inviteCreatedBy}`} onDelete={() => setInviteCreatedBy("")} />}
            </div>
        )
    },
    {
        title: `${talentTerminology} name`,
        subtitle: talentName ? `(${talentTerminology} name: '${talentName}')` : "(Not added)",
        isActive: talentName !== "",
        isVisible: [gigStatuses.pending, gigStatuses.hired, gigStatuses.completed].includes(gigStatusIds[0]),
        content: (
            <div className="flex flex-col gap-2">
                {!talentName ? (
                    <form onSubmit={handleAddTalentName} className="flex flex-row gap-2">
                        <FormTextInput
                            value={talentNameChip || ""}
                            placeholder="Type a name"
                            labelClassName="ml-0 font-bold"
                            onChange={event => setTalentNameChip(event.target.value)}
                            StartAdornmentIcon={MagnifyingGlass}
                        />
                        <Button
                            className="h-fit w-fit mt-auto"
                            variant="secondary"
                            type="submit"
                            onClick={handleAddTalentName}
                        >
                            Add
                        </Button>
                    </form>
                ) : <Chip className="bg-[#0082b7] !text-white" label={`Name: ${talentName}`} onDelete={() => setTalentName("")} />}
            </div>
        )
    },
    {
        title: "Reference number",
        subtitle: referenceNumber ? `(Ref: '${referenceNumber}')` : "(Not added)",
        isActive: referenceNumber !== "",
        content: (
            <div className="flex flex-col gap-2">
                {!referenceNumber ? (
                    <form onSubmit={handleAddRefNumber} className="flex flex-row gap-2">
                        <FormTextInput
                            value={refNumberChip || ""}
                            placeholder="Type a reference number"
                            labelClassName="ml-0 font-bold"
                            onChange={event => setRefNumberChip(event.target.value)}
                            StartAdornmentIcon={MagnifyingGlass}
                        />
                        <Button
                            className="h-fit w-fit mt-auto"
                            variant="secondary"
                            type="submit"
                            onClick={handleAddRefNumber}
                        >
                            Add
                        </Button>
                    </form>
                ) : <Chip className="bg-[#0082b7] !text-white" label={`Ref number: ${referenceNumber}`} onDelete={() => setReferenceNumber("")} />}
            </div>
        )
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        searchNow();
        setIsOpen(false);
    };

    const handleClearFilters = () => {
        try {
            clearAllFilters();
            refetchGigs();
        } catch (error) {
            console.error("Error clearing filters:", error);
        }
    };

    return (
        <Transition.Root show={isOpen} as={Fragment} >
            <Dialog as="div" className="relative z-[1005]" onClose={setIsOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md transform bg-white shadow-xl">
                                    <form className="flex h-full flex-col divide-y divide-gray-200" onSubmit={handleSubmit}>
                                        <div className="h-0 flex-1 overflow-y-auto">
                                            <div className="bg-[#0082b7] px-4 py-6 sticky top-0 z-10">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-tertiary-bg-text font-semibold leading-6">
                                                        Filter {gigTerminologyPlural.toLowerCase()}
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => setIsOpen(false)}
                                                            className="relative rounded-md text-primary-bg-text"
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <X aria-hidden="true" className="h-6 w-6 hover:font-bold text-white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {filterPanelSections.map((panelSectionProps) => (
                                                <FindPagePanelSection key={panelSectionProps.title} {...panelSectionProps} />
                                            ))}
                                        </div>
                                        <div className="flex flex-col-reverse md:flex-row flex-shrink-0 justify-between px-4 py-4">
                                            <ClearFiltersButton
                                                disabled={!anyActiveFilters && !window.location.search}
                                                onClick={() => handleClearFilters()}
                                            />
                                            <Button
                                                type="submit"
                                                className="w-full md:w-auto"
                                                variant="secondary"
                                            >
                                                Apply filters
                                            </Button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
};

export default MyGigsFilterDrawer;