import { Link } from "react-router-dom";
import AuditDataList from "../../components/AuditData/AuditDataList";
import Card, { CardDataFieldLabel } from "../../components/Card";
import ConfirmGigButton from "../../components/ConfirmGigButton";
import DateDisplayLocale from "../../components/DateDisplayLocale";
import LinkButton from "../../components/LinkButton";
import Tooltip from "../../components/Tooltip";
import Typography from "../../components/Typography";
import { GigSummary } from "../../models/app/gig";
import { useAppPaths } from "../../Routes";
import { formatLocationDateToString, formatLocationTimeToString } from "../../utils/dateFormatters";
import UserAvatar from "../../components/UserAvatar";

export type OngoingGigsClientCardProps = {
    gig: GigSummary;
}

const OngoingGigsClientCard = ({
    gig
}: OngoingGigsClientCardProps) => {
    const appPaths = useAppPaths();

    return (
        <div key={gig.id} className="md:flex md:items-stretch break-words">
            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                <div className="space-y-2 md:mr-8">
                    <Typography variant="title-large" component="h3" className="max-w-md">{gig.title}</Typography>
                    <Typography variant="title-small" component="h3">{gig.skills[0].name}</Typography>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 pt-2 justify-items-center md:justify-items-start">
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Calendar" textClass="font-normal">{formatLocationDateToString(gig.startDate, gig.ianaId)}</CardDataFieldLabel>
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Clock" textClass="font-normal">
                            <div className="flex items-center">
                                {formatLocationTimeToString(gig.startDate, gig.ianaId)}
                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                            </div>
                        </CardDataFieldLabel>
                    </div>
                    <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-2 md:ml-auto items-center ">
                    <LinkButton variant="tertiary" to={appPaths.gigs.edit(gig.id)}>Edit</LinkButton>
                    <LinkButton to={appPaths.gigs.details(gig.id)} variant="tertiary">View details</LinkButton>
                    <ConfirmGigButton gigId={gig.id} />
                </div>
            </Card>
            {gig.acceptedTalentId && gig.acceptedTalentName && (
                <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                    <Link to={appPaths.talents.findTalentDetails(gig.acceptedTalentId)} target="_blank"><UserAvatar name={gig.acceptedTalentName} userImageUrl={gig.acceptedTalentProfileImageThumbnailUrl} /></Link>
                </Card>
            )}
        </div>
    );
};

export default OngoingGigsClientCard;