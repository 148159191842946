import { useState } from "react";
import { Controller } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Button from "../Button";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import FormDialogBox, { FormDialogBoxProps } from "../FormDialogBox";
import FormDropdown from "../FormDropdown";
import FormTextAreaInput from "../FormTextAreaInput";
import { CreateReportFormValues, useCreateReportForm } from "./CreateReportFormValues";

export type CreateReportFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: CreateReportFormValues
    resetOnSubmit?: boolean
    onSubmit: (values: CreateReportFormValues) => Promise<GigApiFetcherResponse<unknown>>,
    reasons: string[]
}

export const CreateReportFormDialog = ({
    initialValues,
    resetOnSubmit,
    onSubmit,
    reasons,
    ...dialogProps
}: CreateReportFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const methods = useCreateReportForm(initialValues);

    const reportDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const handleSubmit = async (values: CreateReportFormValues) => {
        setIsSubmitting(true);

        const response = await onSubmit(values);
        if (response.success && resetOnSubmit) {
            methods.reset();
        }

        setIsSubmitting(false);
    };

    const handleCancelButton = () => {
        if (methods.formState.isDirty) {
            reportDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        reportDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        onClose();
    };

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={dialogProps.isOpen && !reportDialogState.isOpen}
                onClose={handleCancelButton}
                title="Report"
            >
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="space-y-4">
                        <div className="w-full">
                            <Controller
                                name="reason"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="Select reason"
                                        options={reasons.map(reason => ({
                                            value: reason,
                                            label: reason
                                        }))}
                                        error={methods.formState.errors.reason}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isSubmitting}
                                    />
                                )}
                            />
                        </div>
                        <div className="w-full">
                            <FormTextAreaInput
                                required
                                id="create-dispute-form-description"
                                label="Description"
                                placeholder="Add description"
                                error={methods.formState.errors.description}
                                register={methods.register("description")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center sm:justify-end sm:space-x-4 flex-col-reverse sm:flex-row">
                        <Button type="button" className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isSubmitting} variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button type="submit" className="w-full sm:w-fit" disabled={!methods.formState.isDirty} loading={isSubmitting} variant="primary">Submit report</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...reportDialogState} title="Discard changes?">
                <div className="flex justify-between">
                    <Button type="button" variant="secondary" onClick={reportDialogState.close}>Back</Button>
                    <Button type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};