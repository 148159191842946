import { AccountInfo } from "@azure/msal-browser";
import { b2cPolicies } from "../auth/authConfig";

// Example homeAccountId value: 90b1269b-d8f2-438f-8732-a01a24786e48-b2c_1a_signup_signin.d580e4e7-f326-426d-9013-13e1f4d2b5f5
export const getMsalAccountAuthority = (account: AccountInfo) => {
    if (account.homeAccountId.includes("talent_signup")) {
        return b2cPolicies.authorities.talentSignUp.authority;
    }

    if (account.homeAccountId.includes("giggedclient_signup")) {
        return b2cPolicies.authorities.giggedClientSignUp.authority;
    }

    if (account.homeAccountId.includes("giggedclient_invitation_signup")) {
        return b2cPolicies.authorities.giggedClientInvitation.authority;
    }

    if (account.homeAccountId.includes("changeemailaddress")) {
        return b2cPolicies.authorities.changeEmail.authority;
    }

    return b2cPolicies.authorities.signUpSignIn.authority;
};