import React, { useEffect, useState } from "react";
import { ApiClientPersonalInformationDto, ApiUpdateClientPersonalInformationDto } from "../models/api/clientPersonalInformation";
import contextPlaceholderFunc from "../utils/contextPlaceholderFun";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

const useClientUpdatePersonalInformation = () => useAuthenticatedGigApiFetcher<ApiUpdateClientPersonalInformationDto, void, ApiUpdateClientPersonalInformationDto>("PUT",
    (dto: ApiUpdateClientPersonalInformationDto) => ({
        url: "api/me/gigged-client",
        body: dto,
    }));

export type UseClientPersonalInformationReturn = {
    clientPersonalInformation?: ApiClientPersonalInformationDto,
    updateClientPersonalInformation: (dto: ApiUpdateClientPersonalInformationDto) => Promise<GigApiFetcherResponse<void>>,
    isUpdatingClientPersonalInformation: boolean,
    isLoading: boolean,
    isValidating: boolean,
    reload: () => void
    clientProfileCompletionStatus: boolean
    setClientProfileCompletionStatus: React.Dispatch<React.SetStateAction<boolean>>
}


export const useClientPersonalInformation = (): UseClientPersonalInformationReturn => {
    const apiCall = useGiggedApiSWR<ApiClientPersonalInformationDto>("api/me/gigged-client-profile");

    const [updateClientPersonalInformation, isUpdatingClientPersonalInformation] = useClientUpdatePersonalInformation();
    const [clientProfileCompletionStatus, setClientProfileCompletionStatus] = useState<boolean>(false);

    useEffect(() => {
        if (apiCall.data?.isProfileComplete !== undefined) {
            setClientProfileCompletionStatus(apiCall.data.isProfileComplete);
        }
    }, [apiCall.data]);

    return {
        clientPersonalInformation: apiCall.data,
        updateClientPersonalInformation: async (dto: ApiUpdateClientPersonalInformationDto) => {
            const response = await updateClientPersonalInformation(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        isUpdatingClientPersonalInformation,
        isLoading: apiCall.isLoading,
        isValidating: apiCall.isLoading,
        reload: apiCall.refetch,
        clientProfileCompletionStatus,
        setClientProfileCompletionStatus
    };
};

const ClientPersonalInformationContext = React.createContext<UseClientPersonalInformationReturn>({
    clientPersonalInformation: undefined,
    updateClientPersonalInformation: contextPlaceholderFunc,
    isUpdatingClientPersonalInformation: false,
    isLoading: false,
    isValidating: false,
    reload: contextPlaceholderFunc,
    clientProfileCompletionStatus: false,
    setClientProfileCompletionStatus: contextPlaceholderFunc
});

export const useClientPersonalInformationContext = () => React.useContext(ClientPersonalInformationContext);

export const ClientPersonalInformationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const clientPersonalInformation = useClientPersonalInformation();

    return (
        <ClientPersonalInformationContext.Provider value={clientPersonalInformation}>
            {children}
        </ClientPersonalInformationContext.Provider>
    );
};