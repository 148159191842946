import React from "react";
import contextPlaceholderFunc from "../../utils/contextPlaceholderFun";

export type FindPageContextType = {
    isFilterPanelOpen: boolean
    setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const FindPageContext = React.createContext<FindPageContextType>({
    isFilterPanelOpen: false,
    setIsFilterPanelOpen: contextPlaceholderFunc,
});

export const useFindPageContext: () => FindPageContextType = () => React.useContext(FindPageContext);

export default FindPageContext;
