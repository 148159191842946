import clsx from "clsx";
import { ApiArchivedChatDto, ApiArchivedChatMessageDto } from "../../../models/api/chatArchive";
import formatDateOnlyString from "../../../utils/formatDateString";

export type ArchiveMessageListProps = {
    archivedChat: ApiArchivedChatDto
    chatMessage: ApiArchivedChatMessageDto
};

const ArchiveMessageListItem = ({ archivedChat, chatMessage }: ArchiveMessageListProps) => {
    const isMyMessage = chatMessage.messageDirection === "Outgoing";

    const otherUserName = `${archivedChat.user.firstName} ${archivedChat.user.lastName}`;

    return ( 
        <div className={clsx("flex flex-col m-8", isMyMessage ? "items-end text-right" : "items-start text-left")}>
            <div className="flex flex-col space-y-2 max-w-[80%]">
                <p>{isMyMessage ? "You" : otherUserName}</p>
                <div
                    className={clsx("p-4 bg-white rounded-lg  min-w-[6rem] drop-shadow-lg text-left",
                        isMyMessage ? "rounded-br-none" : "rounded-bl-none"
                    )}
                >
                    {chatMessage.messageText}
                </div>
                <div className="flex justify-end text-right text-secondary-text gap-3">
                    {formatDateOnlyString(chatMessage.sentDate)}
                </div>
            </div>
        </div>
    );
};

export default ArchiveMessageListItem;