import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Typography from "../Typography";

const Footer = () => {
    const { organizationConfig } = useOrganizationContext();

    if (organizationConfig.hidePoweredByGiggedAi) {
        return null;
    }

    return (
        <>
            <div className="grow" />
            <div className="flex justify-center h-32 p-8 ">
                <a
                    href="https://gigged.ai/"
                    target="_blank"
                    rel="noopener"
                >
                    <Typography component="span" variant="body" className="font-bold text-chip">Powered by</Typography>
                    <img
                        className="max-h-[30px] w-auto mx-auto"
                        src="/gigged-logo.svg"
                        alt="Gigged.ai logo"
                    />
                </a>
            </div>
        </>
    );
};

export default Footer;