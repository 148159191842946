import { MagnifyingGlass } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import FormCheckboxGroup, { FormCheckboxGroupOption } from "./FormCheckboxGroup";
import FormTextInput from "./FormTextInput";
import { fetchSkillsCount } from "../api/skills";

export type SearchableCheckBoxGroupProps = {
    selectedSkillIds: string[],
    searchPlaceholder: string,
    onChange: (selectedSkillIds: string[]) => void
    fetchSkills: (query: string, accessToken: string, pageIndex: number) => Promise<FormCheckboxGroupOption<string>[]>
    accessTokenNotRequired?: boolean
}

const SearchableCheckBoxGroup = ({
    selectedSkillIds,
    searchPlaceholder,
    onChange,
    fetchSkills,
    accessTokenNotRequired = false,
}: SearchableCheckBoxGroupProps) => {
    const accessToken = useAccessToken();
    const [searchTerm, setSearchTerm] = useState("");
    const [skillOptions, setSkillOptions] = useState<FormCheckboxGroupOption<string>[]>([]);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);

    useEffect(() => {
        if (!accessToken || !accessTokenNotRequired || !hasMore) return;

        const loadInitialSkills = async () => {
            setLoading(true);
            try {
                const initialSkills = await fetchSkills("", accessToken, pageIndex);
                setSkillOptions(existingSkills => [...existingSkills, ...initialSkills]);
            } finally {
                setLoading(false);
            }
        };

        loadInitialSkills();
    }, [accessToken, pageIndex]);

    useEffect(() => {
        if (!accessToken || !accessTokenNotRequired) return;

        const loadSkillOptions = async () => {
            setLoading(true);
            try {
                const skills = await fetchSkills(debouncedSearchTerm, accessToken, 0);
                setSkillOptions(skills);
                setNoResultsFound(skills.length === 0);
                setPageIndex(0);
            } finally {
                setLoading(false);
            }
        };

        loadSkillOptions();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!accessToken || !accessTokenNotRequired) return;

        fetchSkillsCount(accessToken)
            .then(count => setHasMore(count != skillOptions.length));

    }, [accessToken, accessTokenNotRequired, hasMore, skillOptions]);

    const fetchMore = () => {
        setPageIndex(prev => prev + 1);
    };

    return (
        <div className="space-y-4">
            <FormTextInput
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                placeholder={searchPlaceholder}
                StartAdornmentIcon={MagnifyingGlass}
            />
            <FormCheckboxGroup
                allOptions={skillOptions}
                selectedOptionIds={selectedSkillIds}
                onChange={onChange}
                className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar pr-3 -mr-2"
                loading={loading}
                fetchMore={fetchMore}
                hasMore={hasMore && !debouncedSearchTerm.length}
                noResultsMessage={noResultsFound && !loading ? "No results" : null}
            />
        </div>
    );
};

export default SearchableCheckBoxGroup;