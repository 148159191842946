import Card from "./Card";
import { CardDataField } from "./Card";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";
import { ApiExternalMatch } from "../models/api/gigMatch";

export type GigTalentExternalMatchCardProps = {
    match: ApiExternalMatch
}

const GigTalentExternalMatchCard = ({
    match
}: GigTalentExternalMatchCardProps) => {
    const matchValue = `${Math.round(match.score * 100)}%`;

    return (
        <Card className={"max-w-full md:flex md:items-center flex-col space-x-0 space-y-2"} >
            <div className="md:w-[230px]">
                <UserAvatar name={match.talentName} />
            </div>
            <div className={clsx("grow space-y-3 text-center")}>
                <CardDataField label="Match level">
                    {matchValue}
                </CardDataField>
                
            </div>
        </Card>
    );
};

export default GigTalentExternalMatchCard;
