import { useEffect, useState } from "react";
import FindPageContext from "./findPageContext";

export type FindPageContextProviderProps = {
    children: React.ReactNode
}

const FindPageContextProvider = ({
    children
}: FindPageContextProviderProps) => {
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

    useEffect(() => {
        if (isFilterPanelOpen) {
            document.body.style.overflowY = "hidden";
        }
        else {
            document.body.style.overflowY = "auto";
        }
    }, [isFilterPanelOpen]);

    return (
        <FindPageContext.Provider value={{
            isFilterPanelOpen,
            setIsFilterPanelOpen,
        }}>
            {children}
        </FindPageContext.Provider>
    );
};

export default FindPageContextProvider;
