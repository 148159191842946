import clsx from "clsx";
import { useState } from "react";
import config from "../../configuration";
import { ApiFile } from "../../models/api/file";
import fileHasAllowedExtension from "../../utils/fileHasAllowedExtension";
import { formatFileSize } from "../../utils/formatFileSizeInKb";
import getFileExtensionFromName from "../../utils/getFileExtensionFromName";
import FormInputMessage from "../FormInputError";
import FormInputLabel from "../FormInputLabel";
import ExistingFile from "./ExistingFile";

export type FileManagerProps = {
    label?: string
    helpText?: string
    inputId: string
    existingFiles?: ApiFile[]
    tooltip?: string
    maxFileSizeKb: number
    onFileDrop: (file: File) => void
    onFileDelete: (fileId: string) => void
}

const FileManager = ({
    label,
    helpText,
    inputId,
    existingFiles,
    tooltip,
    maxFileSizeKb,
    onFileDrop,
    onFileDelete
}: FileManagerProps) => {
    const [error, setError] = useState<string>("");
    const [dragActive, setDragActive] = useState(false);
    const allowedFileTypes = config.allowedImageFileExtensions.concat(config.allowedFileExtensions);

    const handleDrag: React.DragEventHandler<HTMLDivElement> = (event) => {
        setError("");
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
        setError("");
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            if (fileHasAllowedExtension(event.dataTransfer.files[0].name, allowedFileTypes)) {
                onFileDrop(event.dataTransfer.files[0]);
            } else {
                setError(invalidFileTypeErrorMessage(event.dataTransfer.files[0].name));
            }
            
        }
    };

    const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setError("");

        const file = event.target?.files && event.target.files[0];

        if (!file) return;

        if (file.size / 1024 > maxFileSizeKb) {
            setError(`The maximum allowed file size is ${maxFileSizeKb}KB and your file is ${formatFileSize(file.size)}.`);
            return;
        }

        if (fileHasAllowedExtension(file.name, allowedFileTypes)) {
            onFileDrop(file);
        } else {
            setError(invalidFileTypeErrorMessage(file.name));
        }
    };

    const invalidFileTypeErrorMessage = (type: string) => 
        `'${getFileExtensionFromName(type)}' is not a valid type, please upload one of these: ${allowedFileTypes.join(", ")}.`;

    return (
        <div>
            {label && (
                <FormInputLabel tooltipText={tooltip} className={clsx("ml-0", helpText && "mb-0")}>{label}</FormInputLabel>
            )}
            {helpText && (
                <p className="mb-2">{helpText}</p>
            )}
            <div
                className={clsx(
                    "relative border-2 border-dashed rounded-lg p-4 mb-4",
                    dragActive && "bg-btn-secondary-bg-hover",
                )}
                onDragEnter={handleDrag}
            >
                <p className="text-center mb-4">Drag files here to upload</p>
                <input id={inputId} className="hidden" type="file" onChange={handleFileInputChange} />
                <div className="flex justify-center">
                    <label
                        htmlFor={inputId}
                        role="button"
                        className={clsx(
                            "cursor-pointer text-primary-text font-bold py-[0.75rem] px-[1.5rem] rounded-full disabled:text-disabled-text focus:outline whitespace-nowrap transition-colors ease-in-out duration-200",
                            "border-2 border-primary-text hover:bg-btn-secondary-bg-hover focus:outline-1"
                        )}
                    >
                        Select file
                    </label>
                </div>
                {dragActive && (
                    <div className="absolute rounded-lg inset-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />
                )}
            </div>
            <FormInputMessage className="mt-0 mb-4" error={error} />
            <div className="flex gap-2 flex-wrap">
                {existingFiles?.map(existingFile => (
                    <ExistingFile 
                        onFileDelete={onFileDelete} 
                        id={existingFile.id} 
                        key={existingFile.id} 
                        url={existingFile.url}
                        name={existingFile.name}
                        totalBytes={existingFile.totalBytes}
                    />
                ))}                
            </div>
        </div>
    );
};

export default FileManager;
