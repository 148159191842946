import clsx from "clsx";
import ApiError from "../api/common/apiError";

export type GeneralFormErrorProps = {
    error?: ApiError
    className?: string
}

const GeneralFormError = ({
    error,
    className,
}: GeneralFormErrorProps) => {
    if (!error) return null;
    return (
        <div>
            {error.userMessages.map((errorMessage, index) => (
                <p className={clsx("text-error py-2", className)} key={index + errorMessage}>{errorMessage}</p>
            ))}
        </div>
    );
};

export default GeneralFormError;
