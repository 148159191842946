import clsx from "clsx";
import { MagnifyingGlass, X } from "phosphor-react";

import Card from "../Card";
import FormTextInput from "../FormTextInput";
import Typography from "../Typography";
import Button from "../Button";
import { useIsLargeScreen, useIsMediumScreen } from "../../hooks/screenSizes";

import ClearFiltersButton from "./ClearFiltersButton";
import FindPagePanelSection, { FindPagePanelSectionProps } from "./FindPagePanelSection";

export type FindPagePanelProps = {
    title: string
    panelSections: FindPagePanelSectionProps[]
    searchTerm: string
    anyActiveFilters: boolean
    searchPlaceholder: string
    isFilterPanelOpen: boolean
    setIsFilterPanelOpen: (open: boolean) => void
    setSearchTerm: (value: string) => void
    clearAllFilters: () => void,
    searchNow: () => void
}

const FindPagePanel = ({
    title,
    panelSections,
    searchTerm,
    anyActiveFilters,
    searchPlaceholder,
    isFilterPanelOpen,
    setIsFilterPanelOpen,
    setSearchTerm,
    clearAllFilters,
    searchNow,
}: FindPagePanelProps) => {
    const isMedium = useIsMediumScreen();
    const isLarge = useIsLargeScreen();

    const handleApplyFiltersClick = () => {
        setIsFilterPanelOpen(false);
        searchNow();
        window.scrollTo(0, 0);
    };

    const handleSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") searchNow();
    };

    if (!isMedium && !isFilterPanelOpen) return null;

    return (
        <Card className={clsx(
            "p-0 shrink-0 self-start border border-surface",
            "md:static absolute inset-0 overflow-y-auto",
            isLarge ? "w-[315px]" : isMedium ? "w-[225px]" : "w-full",
        )}>
            {isMedium && (
                <ClearFiltersButton onClick={clearAllFilters} disabled={!anyActiveFilters} className="m-auto border-b border-surface"/>
            )}
            <div className="flex justify-between border-b border-surface p-4">
                <Typography component="h1" variant="headline-small" className="text-[24px] whitespace-nowrap">{title}</Typography>
                <button
                    className="md:hidden"
                    onClick={() => setIsFilterPanelOpen(false)}
                >
                    <X weight="bold" size="20" />
                </button>
            </div>
            {!isMedium && (
                <ClearFiltersButton onClick={clearAllFilters} disabled={!anyActiveFilters} className="m-auto border-b border-surface"/>
            )}
            <div className="border-b border-surface p-4">
                <FormTextInput
                    label="Start your search"
                    value={searchTerm}
                    placeholder={searchPlaceholder}
                    labelClassName="ml-0 font-bold"
                    onChange={event => setSearchTerm(event.target.value)}
                    onKeyDown={handleSearchKeyDown}
                    StartAdornmentIcon={MagnifyingGlass}
                />
            </div>
            {panelSections.map((panelSectionProps) => (
                <FindPagePanelSection key={panelSectionProps.title} {...panelSectionProps} />
            ))}
            <div className="py-4 mx-4">
                <Button variant="secondary" className="block mx-auto w-full" onClick={handleApplyFiltersClick}>Apply filters</Button>
            </div>
        </Card>
    );
};

export default FindPagePanel;
