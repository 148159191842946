import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

export type ClientPersonalDetailsFormValues = {
    name?: string 
    aboutMe?: string
    companyName?: string | null
    companyTagline?: string | null
    companyWebsite?: string | null
    locationId: string | null
    localityId: string | null
    phoneNumber?: string | null
}

const buildClientPersonalDetailsFormValidationSchema = (
    hideGiggedClientCompanyInfo: boolean
): yup.SchemaOf<ClientPersonalDetailsFormValues> => {
    return yup.object({
        name: !hideGiggedClientCompanyInfo ?
            yup.string() :
            yup.string()
                .required("Name is required.")
                .max(150, "Name cannot be longer than 150 characters."),
        aboutMe: yup.string().typeError("Overview is required.").required("Overview is required."),
        phoneNumber: yup.string().nullable(),

        companyName: yup.string()
            .required("Company name is required.")
            .typeError("Company name is required.")
            .max(100, "Company name cannot be longer than 100 characters."),
        companyTagline: yup.string().nullable().max(60, "Company tagline cannot be longer than 60 characters."),
        companyWebsite: yup.string().nullable().test(isValidOptionalUrl),
        locationId: yup.string().required("Country is required."),
        localityId: yup.string().required("City is required."),
    });
} ;

export const useClientPersonalDetailsForm = (
    giggedClientTerminology: string,
    hideGiggedClientCompanyInfo: boolean,
    defaultValues?: ClientPersonalDetailsFormValues
) => useForm<ClientPersonalDetailsFormValues>({
    resolver: yupResolver(buildClientPersonalDetailsFormValidationSchema(hideGiggedClientCompanyInfo)),
    defaultValues: {
        ...defaultValues,
        name: defaultValues?.name ?? "",
        aboutMe: defaultValues?.aboutMe ?? "",
        companyName: defaultValues?.companyName ?? "",
        companyTagline: defaultValues?.companyTagline ?? "",
        companyWebsite: defaultValues?.companyWebsite ?? "",
        phoneNumber: defaultValues?.phoneNumber ?? "",
        localityId: defaultValues?.localityId ?? "",
        locationId: defaultValues?.locationId ?? ""
    }
});