import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { ApiArchivedChatDto } from "../models/api/chatArchive";

export type UseArchivedChatReturn =
    UseGiggedApiSWRReturn<ApiArchivedChatDto[]>;

export const useArchivedChat = (): UseArchivedChatReturn => {
    const apiCall = useGiggedApiSWR<ApiArchivedChatDto[]>("api/me/chat-archive");

    return apiCall;
};