import { AddedExtrasDto as AddedExtrasDto } from "../models/app/addedExtras";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useUpdateAddedExtras = () => useAuthenticatedGigApiFetcher<AddedExtrasDto, void, AddedExtrasDto>("PUT",
    (dto) => ({
        url: "api/me/added-extras",
        body: dto,
    }));

export type UseAddedExtrasReturn = {
    addedExtras?: AddedExtrasDto
    isLoading: boolean
    isValidating: boolean
    updateAddedExtras: (dto: AddedExtrasDto) => Promise<GigApiFetcherResponse<void>>
    isUpdatingAddedExtras: boolean
}

export const useAddedExtras = (): UseAddedExtrasReturn => {
    const url = "api/me/added-extras";

    const apiCall = useGiggedApiSWR<AddedExtrasDto>(url);
    const [updateAddedExtras, isUpdatingAddedExtras] = useUpdateAddedExtras();

    return {
        addedExtras: apiCall.data,
        isLoading: apiCall.isLoading,
        isValidating: apiCall.isValidating,
        updateAddedExtras: async(dto) => {
            const response = await updateAddedExtras(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingAddedExtras,
    };
};
