import { useEffect, useState } from "react";

import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Button from "../../components/Button";
import FormImageUploadInput, { useFormFileUploadInputState } from "../../components/FormFileUploadInput";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import { successToast } from "../../toast";
import { TalentPersonalDetailsFormValues, usePersonalDetailsForm } from "./TalentPersonalDetailsFormValues";
import { Controller } from "react-hook-form";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { fetchLocationOptions } from "../../api/locations";
import { genderTypeOptions } from "../../models/app/talent";
import FormDateInput from "../../components/FormDateInput";
import WheelPositionDropdown from "../../components/WheelPositionDropdown";
import { getTomorrowsDate } from "../../utils/getTomorrowsDate";

const TalentPersonalDetailsForm = () => {
    const accessToken = useAccessToken();
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);

    const [isPersonalInformationLoaded, setIsPersonalInformationLoaded] = useState(false);
    const {
        talentPersonalInformation,
        updatePersonalInformation,
        isUpdatingPersonalInformation,
        isLoading,
        isValidating,
    } = useTalentPersonalInformationContext();
    const methods = usePersonalDetailsForm();

    const {
        urlOrDataUri: profileImageDataUri,
        loadFile,
        error: profileImageError,
        isDirty: isProfileImageDirty,
        reset: resetImageInput,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: talentPersonalInformation?.profileImageUrl,
    });

    const { reset } = methods;

    useEffect(() => {
        if (isPersonalInformationLoaded) return;

        reset({
            ...talentPersonalInformation,
            tradingName: talentPersonalInformation?.tradingName || "",
            locationId: talentPersonalInformation?.location?.countryId,
            genderTypeId: talentPersonalInformation?.genderTypeId ?? null,
            localityId: talentPersonalInformation?.location?.locality?.localityId ? talentPersonalInformation.location.locality.localityId : "",
            knownCompetitors: talentPersonalInformation?.knownCompetitors ?? "",
            wheelPositionId: talentPersonalInformation?.wheelPositionId ?? null,
            aboutMe: talentPersonalInformation?.aboutMe ?? null
        });

        if (talentPersonalInformation) {
            setIsPersonalInformationLoaded(true);
        }
    }, [reset, talentPersonalInformation, isPersonalInformationLoaded]);

    useEffect(function setLocationOptions() {
        if (!accessToken) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (selectedLocationLocalities.length < 1 || talentPersonalInformation?.location?.countryId === selectedLocationId) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const handleSaveChanges = async (values: TalentPersonalDetailsFormValues) => {

        const sanitizedValues = {
            ...values,
            profileImageDataUri: isProfileImageDirty ? profileImageDataUri : undefined,
            insuranceExpiryDate: values.insuranceExpiryDate ?? null,
            genderTypeId: values.genderTypeId !== null ? values.genderTypeId : null,
            aboutMe: !values.aboutMe || !values.aboutMe.length ? null : values.aboutMe
        };

        const response = await updatePersonalInformation(sanitizedValues);

        if (response.success) {
            resetImageInput();
            reset(sanitizedValues);
            successToast("Personal information successfully updated.");
        }
    };

    if (isLoading || isValidating) {
        return <p>Loading...</p>;
    }

    return (
        <form className="space-y-6" onSubmit={methods.handleSubmit(handleSaveChanges)}>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <FormTextInput
                    required
                    label="First name"
                    placeholder="Enter first name"
                    error={methods.formState.errors.firstName}
                    {...methods.register("firstName")}
                />
                <FormTextInput
                    required
                    label="Last name"
                    placeholder="Enter last name"
                    error={methods.formState.errors.lastName}
                    {...methods.register("lastName")}
                />
            </div>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <div className="w-full md:w-1/2">
                    <Controller
                        name="genderTypeId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                label="Gender"
                                value={value ?? -1}
                                options={genderTypeOptions}
                                disabled={isValidating || isLoading}
                                onChange={onChange}
                                error={methods.formState.errors.genderTypeId}
                            />
                        )}
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <FormTextInput
                        required
                        label="Phone number"
                        placeholder="Enter phone number"
                        error={methods.formState.errors.phoneNumber}
                        {...methods.register("phoneNumber")}
                    />
                </div>
            </div>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <Controller
                    name="insuranceExpiryDate"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDateInput
                            id="personal-information-form-insurance-expiry-date"
                            label="Insurance expiry date"
                            value={value || null}
                            onChange={onChange}
                            minDate={getTomorrowsDate()}
                            error={methods.formState.errors.insuranceExpiryDate}
                            hideHelpText
                        />
                    )}
                />
                <FormTextInput
                    required
                    label="Company name"
                    placeholder="Enter company name"
                    error={methods.formState.errors.tradingName}
                    {...methods.register("tradingName")}
                />
            </div>
            <FormTextAreaInput
                // tooltip={talentProfileEditTooltips.aboutMe}
                id="personal-information-about-me"
                label="Professional summary"
                placeholder="Please provide information here about you as a facilitator, your delivery style and principles. Please avoid use of your business description and focus on you and an individual."
                register={methods.register("aboutMe")}
                error={methods.formState.errors.aboutMe}
            />
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                {allLocationOptions.length > 0 ? (
                    <div className="w-full md:w-1/2">
                        <Controller
                            name="locationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Country"
                                    value={value || ""}
                                    options={allLocationOptions}
                                    disabled={isValidating || isLoading}
                                    onChange={onChange}
                                    error={methods.formState.errors.locationId}
                                    isLoadingOptions={isLoadingLocationOptions}
                                />
                            )}
                        />
                    </div>
                ) : <div>Loading...</div>}
                <div className="w-full md:w-1/2">
                    {selectedLocationLocalities?.length > 0 && (
                        <Controller
                            name="localityId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="City"
                                    value={value || ""}
                                    options={selectedLocationLocalities}
                                    disabled={selectedLocationLocalities.length < 1 || isLoadingLocationOptions}
                                    onChange={onChange}
                                    error={methods.formState.errors.localityId}
                                    isLoadingOptions={selectedLocationLocalities.length < 1}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                <div className="w-full md:w-1/2">
                    <Controller
                        name="wheelPositionId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <WheelPositionDropdown
                                error={methods.formState.errors.wheelPositionId}
                                onChange={onChange}
                                value={value || ""}
                                label="Wheel position"
                            />
                        )}
                    />
                </div>
                <div className="w-full md:w-1/2" />
            </div>

            <FormImageUploadInput
                label="Profile Photo"
                helpText="Recommended min size: 300x300px"
                inputId="profile-image-upload-button"
                onLoadFile={loadFile}
                error={profileImageError}
                {...fileInputProps}
                dataUrl={profileImageDataUri}
                isDirty={isProfileImageDirty}
                previewImgClassName="object-cover"
            />
            <div className="md:flex mt-8">
                <Button
                    className="md:ml-auto w-full md:w-auto"
                    loading={isUpdatingPersonalInformation || isValidating}
                    disabled={(!methods.formState.isDirty && !isProfileImageDirty) || !!profileImageError}
                    type="submit"
                >
                    Save changes
                </Button>
            </div>
        </form>
    );
};

export default TalentPersonalDetailsForm;
