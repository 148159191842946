import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const certificationFormValidationSchema = {
    title: yup.string()
        .required("Title is required.")
        .max(100, "The maximum length for title is 100 characters."),
    achievementDate: yup.date()
        .required("Achievement date is required.")
        .nullable()
        .typeError("Achievement date must be a valid date.")
        .max(new Date(), "Achievement date can't be in the future."),
};

export type CertificationFormValues = {
    title: string
    achievementDate: Date | null
    fileDataUri?: string
}

const validationSchema = yup.object(certificationFormValidationSchema);

export const useCertificationForm = (defaultValues?: CertificationFormValues) => {
    return useForm<CertificationFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            title: defaultValues?.title || "",
            achievementDate: defaultValues?.achievementDate || null,
        }
    });
};

