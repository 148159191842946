import clsx from "clsx";

export type ProfileImageProps = {
    imageUrl?: string
    name: string
    className?: string
    size?: "md" | "lg"
}

const ProfileImage = ({
    imageUrl,
    name,
    className,
    size = "md",
}: ProfileImageProps) => {
    return (
        <div className={clsx(
            "rounded-full border-2 border-primary shrink-0",
            size === "md" ? "h-32 w-32" : "h-40 w-40",
            className,
        )}>
            {imageUrl ? (
                <img
                    src={imageUrl}
                    className={clsx(
                        "rounded-full object-cover border-2 border-transparent w-full h-full",
                    )}
                />
            ) : (
                <div className={clsx(
                    "text-[84px] inline-flex items-center justify-center mx-auto rounded-full bg-white border-2 border-transparent w-full h-full",
                )}>
                    {name[0].toUpperCase()}
                </div>
            )}
        </div>
    );
};

export default ProfileImage;
