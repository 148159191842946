import { Autocomplete, Chip, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export type FormChipInputProps = {
    name: string
    placeholder: string
    errorMessage?: string
    lastErrorIndex?: number
}
const FormChipInput = ({
    name,
    placeholder,
    errorMessage,
    lastErrorIndex
}: FormChipInputProps) => {
    const { control, formState: { isValidating } } = useFormContext();
    
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Autocomplete
                    multiple
                    options={[]}
                    defaultValue={[]}
                    value={value}
                    onBlur={onBlur}
                    freeSolo
                    isOptionEqualToValue={() => false}
                    onChange={(e, data) => onChange(data)}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    {...getTagProps({ index })}
                                    variant="outlined"
                                    key={index}
                                    label={option}
                                    tabIndex={0}
                                    disabled={isValidating}
                                    color={lastErrorIndex === index ? "error" : "default"}
                                />
                            );
                        }
                        )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            error={!isValidating && !!error}
                            helperText={!isValidating ? errorMessage : "Validating..."}
                            required
                        />
                    )}
                />
            )
            }
        />
    );
};

export default FormChipInput;