import { Envelope } from "phosphor-react";
import { toast } from "react-toastify";

export const errorToast = (message: string) => {
    toast(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
        { type: "error" });
};

export const successToast = (message: string) => {
    toast(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
        { type: "success" });
};

export const inboxToast = (message: string, onClick: () => void) => {
    toast(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
        { 
            icon: <Envelope size={20} />,
            onClick: onClick
        }
    );
};
