export type DashboardTableProps<T> = {
    data: T[],
    headings: string[],
    renderRowCells: (rowData: T) => React.ReactNode
}

const DashboardTable = <T extends { id: string }>({
    data,
    headings,
    renderRowCells,
}: DashboardTableProps<T>) => {
    return (
        <div className="overflow-x-auto">
            <table className="rounded-t-lg overflow-hidden w-full text-left">
                <thead className="bg-secondary text-white border-b-[3px] border-b-primary text-[0.875rem] whitespace-nowrap">
                    <tr className="child:px-4 sm:child:px-6 child:py-2 sm:child:py-4 rounded-t-lg border-x">
                        {headings.map(heading => (
                            <th key={heading}>{heading}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="rounded-b-lg overflow-hidden">
                    {data?.map(rowData => (
                        <tr key={rowData.id} className="child:px-4 sm:child:px-6 child:py-2 sm:child:py-4 border-b border-x border-surface text-[0.875rem] break-words">
                            {renderRowCells(rowData)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DashboardTable;