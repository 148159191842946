import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";

import App from "./App";
import "./index.css";

LogRocket.init("ottocn/giggedai-otm", { console: { isEnabled: false } });

const rootElement = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

root.render(
    <App />
);