import { useEffect, useState } from "react";
import SearchableCheckBoxGroup from "../../components/SearchableCheckBoxGroup";
import useAccessToken from "../../auth/useAccessToken";
import { FormDropdownOption } from "../../components/FormDropdown";
import { fetchLocationOptions } from "../../api/locations";
import { useFindAGigContext } from "../FindAGig/findAGigContext";

export type SearchableLocationsCheckBoxGroupProps = {
    setAllLocationOptions: (options: FormDropdownOption<string>[]) => void;
}

const SearchableLocationsCheckBoxGroup = ({
    setAllLocationOptions,
}: SearchableLocationsCheckBoxGroupProps) => {
    const accessToken = useAccessToken();
    const { locationIds, setLocationIds } = useFindAGigContext();

    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);

    useEffect(function setLocationOptions() {
        if (!accessToken) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);
    
    return (
        <SearchableCheckBoxGroup
            selectedOptionIds={locationIds}
            searchPlaceholder="Search by country"
            onChange={setLocationIds}
            fetchOptions={fetchLocationOptions}
            disabled={isLoadingLocationOptions}
        />
    );
};

export default SearchableLocationsCheckBoxGroup;