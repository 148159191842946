import gigApiFetcher, { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { Conversation, Media, Message, Paginator, Participant } from "@twilio/conversations";
import { ReduxMessage, MessageStatus } from "../store/reducers/messageListReducer";
import { ReduxParticipant } from "../store/reducers/participantsReducer";
import { CONVERSATION_PAGE_SIZE, UNEXPECTED_ERROR_MESSAGE } from "../utils/constants";
import { getSdkConversationObject, getSdkMessageObject } from "../utils/conversationsObjects";
import { AddMessagesType, SetParticipantsType, SetSidType } from "../models/app/conversation";
import { ReduxConversation } from "../store/reducers/conversationReducer";
import { NotificationsType } from "../store/reducers/notificationsReducer";
import { unexpectedErrorNotification } from "../utils/conversationsHelpers";
import { SendMessageReceivedEmailDto } from "../models/api/conversations";

export const fetchConversationsToken = async (accessToken: string | null): Promise<string> => {
    const response = await gigApiFetcher<never, string>({
        url: "api/conversations/token",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const getParticipantName = (conversation: ReduxConversation, currentUserRole: string): string => {
    const participants = Array.from(conversation._participants.values());
    const otherParticipant = participants.find((participant) =>
        (currentUserRole === "talent" && participant.identity?.startsWith("giggedclient")) ||
        (currentUserRole === "client" && participant.identity?.startsWith("talent"))
    );
  
    if (otherParticipant) {
        const attributes = otherParticipant.attributes;
        if (attributes && typeof attributes === "object" && "name" in attributes) {
            const name = attributes.name;
            if (typeof name === "string") {
                return name;
            }
        }
    }
  
    return "";
};

export const getConversationParticipants = async (
    conversation: Conversation
): Promise<Participant[]> => await conversation.getParticipants();

export type MessageStatuses = {
    [MessageStatus.Delivered]?: number;
    [MessageStatus.Read]?: number;
    [MessageStatus.Failed]?: number;
    [MessageStatus.Sending]?: number;
  };

  
export async function getMessageStatus(
    message: ReduxMessage,
    channelParticipants: ReduxParticipant[]
): Promise<MessageStatuses> {  
    const statuses = {
        [MessageStatus.Delivered]: 0,
        [MessageStatus.Read]: 0,
        [MessageStatus.Failed]: 0,
        [MessageStatus.Sending]: 0,
    };
  
    if (message.index === -1) {
        return Promise.resolve({
            ...statuses,
            [MessageStatus.Sending]: 1,
        });
    }
  
    channelParticipants.forEach((participant) => {
        if (participant.identity == localStorage.getItem("username") || participant.type !== "chat") {
            return;
        }
  
        if (participant.lastReadMessageIndex && participant.lastReadMessageIndex >= message.index) {
            statuses[MessageStatus.Read] += 1;
        }
    });
  
    if (message.aggregatedDeliveryReceipt) {
        const sdkMessage = getSdkMessageObject(message);
        const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time
    
        receipts.forEach((receipt) => {
            if (receipt.status === "read") {
                statuses[MessageStatus.Read] += 1;
            }
    
            if (receipt.status === "delivered") {
                statuses[MessageStatus.Delivered] += 1;
            }
    
            if (receipt.status === "failed" || receipt.status === "undelivered") {
                statuses[MessageStatus.Failed] += 1;
            }
    
            if (receipt.status === "sent" || receipt.status === "queued") {
                statuses[MessageStatus.Sending] += 1;
            }
        });
    }
  
    return statuses;
}

export const getMessages = async (
    conversation: Conversation
): Promise<Paginator<Message>> =>
    await conversation.getMessages(CONVERSATION_PAGE_SIZE);

export const loadMessages = async (
    conversation: ReduxConversation,
    addMessage: AddMessagesType,
    currentMessages: ReduxMessage[] = []
): Promise<void> => {
    const conversationSid: string = conversation.sid;
    const sidExists = !!currentMessages.filter(({ sid }) => sid === conversationSid).length;
    if (!sidExists) {
        const paginator = await getMessages(getSdkConversationObject(conversation));
        const messages = paginator.items;
        addMessage(conversationSid, messages);
    }
};

export const updateCurrentConversationSdk = async (
    setSid: SetSidType,
    conversation: ReduxConversation,
    updateParticipants: SetParticipantsType
) => {
    setSid(conversation.sid);
  
    try {
        const participants = await getSdkConversationObject(
            conversation
        ).getParticipants();
        updateParticipants(participants, conversation.sid);
    } catch {
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};

export const getBlobFile = async (
    media: Media,
    addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
    try {
        const url = await getFileUrl(media);
        const response = await fetch(url);
        return response.blob();
    } catch (e) {
        unexpectedErrorNotification(addNotifications);
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};
  
export const getFileUrl = async (media: Media): Promise<string> => {
    return await media.getContentTemporaryUrl().then();
};

export const useSendMessageReceivedEmail = () => useAuthenticatedGigApiFetcher<SendMessageReceivedEmailDto, void, string[]>("POST",
    (participantIds: string[]) => ({
        url: "api/conversations/actions/send-message-received-email",
        body: { participantIds }
    }));