import { ApiDisputeEditableFields, ApiDisputeSummaryDto } from "../api/dispute";
import { DisputeEditableFields, DisputeSummaryDto } from "../app/dispute";

export const mapFromApiDispute = (apiDto: ApiDisputeSummaryDto): DisputeSummaryDto => ({
    ...apiDto,
    createdAt: new Date(apiDto.createdAt)
});

export const mapToApiCreateDispute = (dto: DisputeEditableFields): ApiDisputeEditableFields => {
    return dto;
};