import { useIsAuthenticated } from "@azure/msal-react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { useEffect } from "react";

export type RequireAuthenticatedProps = {
    children?: React.ReactNode
}

const RequireAuthenticated = ({ children }: RequireAuthenticatedProps) => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const appPaths = useAppPaths();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(appPaths.login);            
            return;
        }
    }, [isAuthenticated]);

    if (children) return <>{children}</>;

    return <Outlet />;
};

export default RequireAuthenticated;