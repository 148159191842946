import { Conversation, Message, Participant } from "@twilio/conversations";
import { ReduxConversation } from "../store/reducers/conversationReducer";
import { ReduxMessage } from "../store/reducers/messageListReducer";

export const conversationsMap = new Map<string, Conversation>();
export const participantsMap = new Map<string, Participant>();
export const messagesMap = new Map<string, Message>();

const capitalize = (string: string): string =>
    `${string[0].toUpperCase()}${string.substring(1)}`;

const getSdkObject = <T>(
    objectMap: Map<string, T>,
    sid: string,
    type: "conversation" | "message" | "media" | "participant"
): T => {
    const sdkObject = objectMap.get(sid);

    if (!sdkObject) {
        throw new Error(`${capitalize(type)} with SID ${sid} was not found.`);
    }

    return sdkObject;
};

export const getSdkMessageObject = (reduxMessage: ReduxMessage): Message =>
    getSdkObject(messagesMap, reduxMessage.sid, "message");

export const getSdkConversationObject = (
    reduxConversation: ReduxConversation
): Conversation =>
    getSdkObject(conversationsMap, reduxConversation.sid, "conversation");