import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import { DropdownIndicatorProps, GroupBase } from "react-select";

const FormSearchDropdownDropdownIndicator = ({ 
    innerProps,
    selectProps,
    isDisabled
}: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => (
    <span 
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4" 
        {...innerProps}
    >
        {selectProps.menuIsOpen ? 
            <CaretUp
                className={clsx(
                    "h-5 w-5 text-primary-text",
                    isDisabled && "text-disabled-text"
                )}
                aria-hidden="true"            
            /> :
            <CaretDown
                className={clsx(
                    "h-5 w-5 text-primary-text",
                    isDisabled && "text-disabled-text"
                )}
                aria-hidden="true"
            />
        }
    </span>
);

export default FormSearchDropdownDropdownIndicator;