import Card from "../../components/Card";
import Chip, { ChipContainer } from "../../components/Chip";

export const TalentSummaryCardSkeleton = () => {
    return (
        <Card className='animate-pulse bg-surface'>
            <div className="flex flex-row items-center">
                <div className="bg-chip-secondary h-28 w-28 rounded-full bg-shimmer bg-200% animate-shimmer" />
                <div className="flex flex-col space-y-4 p-4 grow">
                    <div className="h-8 w-full rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <div className="h-4 w-full rounded-md bg-chip-secondary text-sm font-medium bg-shimmer bg-200% animate-shimmer" />
                    <ChipContainer>
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 px-8" />
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 px-8" />
                        <Chip label="" size="large" variant="secondary" className="bg-shimmer bg-200% animate-shimmer py-4 px-8" />
                    </ChipContainer>
                </div>

            </div>
        </Card>
    );
};

export default TalentSummaryCardSkeleton;