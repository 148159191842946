import * as yup from "yup";
import { AnyObject } from "yup/lib/types";

export const isValidOptionalUrl = (value: string | undefined | null, context: yup.TestContext<AnyObject>) => {
    if (!value) return true;

    const regEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

    if (!regEx.test(value)) {
        return context.createError({ message: "Must be a valid url." });
    }

    return true;
};