import { BrowserRouter as Router } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppRoutes from "./Routes";
import PageLayout from "./components/PageLayout";
import { store } from "./store";
import { Provider } from "react-redux";
import AuthContextProvider from "./auth/AuthContextProvider";
import AppTheme from "./components/AppTheme";
import { ConversationManager } from "./components/Conversations";
import OrganizationProvider from "./api/current-organization/OrganizationProvider";
import ScrollToTop from "./utils/scrollToTop";
import { LogRocketProvider } from "./components/LogRocketProvider";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MyApp() {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router>
                    <ScrollToTop />
                    <AuthContextProvider>
                        <OrganizationProvider>
                            <LogRocketProvider>
                                <AppTheme>
                                    <Provider store={store}>
                                        <ConversationManager>
                                            <PageLayout
                                                children={
                                                    <AppRoutes />
                                                }
                                            />
                                        </ConversationManager>
                                    </Provider>
                                </AppTheme>
                            </LogRocketProvider>
                        </OrganizationProvider>
                    </AuthContextProvider>
                </Router>
            </LocalizationProvider>
            <ToastContainer />
        </>
    );
}

export default MyApp;
