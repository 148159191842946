import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import Typography from "./Typography";

export type FormAccordionStep = {
    title: string
    content: React.ReactNode,
}

export type FormAccordionProps = {
    steps: FormAccordionStep[]
}

const FormAccordion = ({
    steps
}: FormAccordionProps) => {
    return (
        <div>
            {steps.map((step, index) => (
                <Disclosure key={step.title} defaultOpen={index === 0}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={clsx(
                                "flex items-center w-full text-left font-bold px-4 py-6 md:px-6",
                                !open && "border-b",
                            )}>
                                <Typography variant="title-small" component="h2" className="mr-auto">
                                    {step.title}
                                </Typography>
                                {open ? (
                                    <CaretUp className="h-5 w-5 text-primary-text" weight="bold" aria-hidden />
                                ) : (
                                    <CaretDown className="h-5 w-5 text-primary-text" weight="bold" aria-hidden />
                                )}
                            </Disclosure.Button>
                            <Disclosure.Panel className="pt-8 pb-12 px-2 border-b md:px-6" unmount={false}>
                                {step.content}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))}
        </div>
    );
};

export default FormAccordion;
