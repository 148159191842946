import { ApiBrochureDto, ApiClientBrochureUploadDto } from "../models/api/clientBrochure";
import { useAuthenticatedGigApiFetcher, GigApiFetcherResponse } from "./common/fetching";
import { UseGiggedApiSWRReturn, useGiggedApiSWR } from "./common/giggedApiSWR";

export const useUploadClientBrochure = () => useAuthenticatedGigApiFetcher<ApiClientBrochureUploadDto, string, ApiClientBrochureUploadDto>("POST",
    (dto) => ({
        url: "api/me/brochures",
        body: dto
    })
);

export const useRemoveClientBrochure = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (brochureId: string) => ({
        url: `api/me/brochures/${brochureId}`,
    })
);

export type UseClientBrochuresReturn =
    UseGiggedApiSWRReturn<ApiBrochureDto[]> &
    {
        brochures: ApiBrochureDto[]
        uploadBrochure: (dto: ApiClientBrochureUploadDto) => Promise<GigApiFetcherResponse<string>>
        isUploadingBrochure: boolean
        removeBrochure: (id: string) => Promise<GigApiFetcherResponse<void>>
        isRemovingBrochure: boolean
    }

export const useClientBrochures = (): UseClientBrochuresReturn => {
    const apiCall = useGiggedApiSWR<ApiBrochureDto[]>("api/me/brochures");
    const [uploadBrochure, isUploadingBrochure] = useUploadClientBrochure();
    const [removeBrochure, isRemovingBrochure] = useRemoveClientBrochure();

    return {
        ...apiCall,
        brochures: apiCall?.data || [],
        uploadBrochure: async (dto) => {
            const response = await uploadBrochure(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        isUploadingBrochure,
        removeBrochure: async (id) => {
            const response = await removeBrochure(id);

            if (response.success) apiCall.mutate();

            return response;
        },
        isRemovingBrochure,
    };
};