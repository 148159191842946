import FindPageComponent, { FindPageProps } from "./FindPageComponent";
import FindPageContextProvider from "./FindPageContextProvider";

const FindPage = (props: FindPageProps) => {
    return (
        <FindPageContextProvider>
            <FindPageComponent {...props} />
        </FindPageContextProvider>
    );
};

export default FindPage;
