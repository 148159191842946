import { useEffect, useState } from "react";
import { silentlyReplaceSearchParams } from "../utils/searchParams";

type StateParamsType = "string" | "stringArray" | "number" | "numberArray" | "boolean";

const serializeParamByType = <T, >(type: StateParamsType, value: T): string => {
    // We can assert types based on the StateParamType passed to the method
    if (type === "string") return value as unknown as string;
    if (type === "stringArray") return (value as unknown as string[]).join(",");
    if (type === "number") return (value as unknown as number).toString();
    if (type === "numberArray") return (value as unknown as number[]).join(",");
    if (type === "boolean") return (value as unknown as boolean).toString();

    return value as unknown as string;
};

const deserializeParamByType = <T, >(type: StateParamsType, value: string): T => {
    // We can assert types based on the StateParamType passed to the method
    if (type === "string") return value as unknown as T;
    if (type === "stringArray") return value.split(",") as unknown as T;
    if (type === "number") return parseFloat(value) as unknown as T;
    if (type === "numberArray") return value.split(",").map(value => parseFloat(value)) as unknown as T;
    if (type === "boolean") return Boolean(value) as unknown as T;

    return value as unknown as T;
};

export const useStateParams = <T,>(
    initialState: T,
    paramsName: string,
    type: StateParamsType
): [T, (state: T) => void] => {
    const search = new URLSearchParams(window.location.search);
    const existingValue = search.get(paramsName);
    const [state, setState] = useState<T>(
        existingValue ? deserializeParamByType(type, existingValue) : initialState
    );

    useEffect(() => {
        // Updates state when user navigates backwards or forwards in browser history
        if (existingValue && deserializeParamByType(type, existingValue)  !== state) {
            setState(deserializeParamByType(type, existingValue) );
        }
    }, [existingValue]);

    const onChange = (value: T) => {
        setState(value);

        const searchParams = new URLSearchParams(window.location.search);

        if (value === undefined) {
            searchParams.delete(paramsName);
            silentlyReplaceSearchParams(searchParams);
            return;
        }

        const serializedValue = serializeParamByType(type, value);        
        
        if (serializedValue === "") {
            searchParams.delete(paramsName);
        } else {
            searchParams.set(paramsName, serializedValue);
        }

        silentlyReplaceSearchParams(searchParams);
    };

    return [state, onChange];
};