import clsx from "clsx";

export type PageContentProps = {
    children: React.ReactNode
    className?: string
}

const PageContent = ({
    children,
    className,
}: PageContentProps) => {
    return (
        <div className={clsx(
            "mx-auto max-w-6xl mb-8 md:mb-16 child:mx-2 child:sm:mx-4 child:md:mx-8 bg-app-background",
            className,
        )}>
            {children}
        </div>
    );
};

export default PageContent;
