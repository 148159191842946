import { Navigate } from "react-router-dom";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useAppPaths } from "../../Routes";

export type ClientProfileEditRedirectProps = {
    children: JSX.Element
}

const ClientProfileEditRedirect = ({
    children
}: ClientProfileEditRedirectProps) => {
    const { clientPersonalInformation, clientProfileCompletionStatus } = useClientPersonalInformationContext();
    const appPaths = useAppPaths();

    if (clientPersonalInformation && !clientPersonalInformation.isProfileComplete && !clientProfileCompletionStatus && window.location.pathname !== appPaths.profile.edit ) {
        return (
            <Navigate to={appPaths.profile.edit} />
        );
    }

    return children;
};

export default ClientProfileEditRedirect;
