export type YouTubeThumbnailQuality = "low" | "medium" | "high";

// Got this method from: https://stackoverflow.com/questions/18681788/how-to-get-a-youtube-thumbnail-from-a-youtube-iframe
export const getYouTubeThumbnail = (
    url: string, 
    quality: YouTubeThumbnailQuality
): string | null => {
    let videoId, result;

    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)))
    {
        videoId = result.pop();
    }
    else if ((result = url.match(/youtu.be\/(.{11})/)))
    {
        videoId = result.pop();
    }

    if (!videoId) {
        return null;
    }

    let quality_key = "maxresdefault"; // Max quality

    if (quality === "low") {
        quality_key = "sddefault";
    } else if (quality === "medium") {
        quality_key = "mqdefault";
    } else if (quality === "high") {
        quality_key = "hqdefault";
    }

    const thumbnail = "http://img.youtube.com/vi/" + videoId + "/" + quality_key + ".jpg";

    return thumbnail;    
};