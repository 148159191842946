import { useIsExtraLargeScreen } from "../../hooks/screenSizes";

type ClickAwayOverlayProps = {
    isOpen: boolean
    closeSideMenu: () => void
}

const ClickAwayOverlay = ({
    isOpen: open,
    closeSideMenu: close
}: ClickAwayOverlayProps) => {
    const isExtraLarge = useIsExtraLargeScreen();

    if (!open || isExtraLarge) return null;

    return (
        <div
            className="xl:hidden fixed inset-0 bg-[#aaa] bg-opacity-50 pointer-events-auto z-[1005]"
            onClick={() => close()}
        />
    );
};

export default ClickAwayOverlay;
