import { OrganizationConfigDto } from "../models/api/organization";
import gigApiFetcher from "./common/fetching";

export const getCurrentOrganizationId = async (): Promise<string> => {
    const path = `api/organizations/get-id-by-domain/${window.location.hostname}`;
    const organizationId = await gigApiFetcher<never, string>({
        url: path,
        method: "GET"
    });
    
    return organizationId;
};

export const getOrganizationConfigByCurrentDomain = async (): Promise<OrganizationConfigDto> => {
    const path = `api/organizations/get-config-by-domain/${window.location.hostname}`;
    const organizationId = await gigApiFetcher<never, OrganizationConfigDto>({
        url: path,
        method: "GET"
    });
    
    return organizationId;
};

export const validateClientEmail = async (email: string, token: string): Promise<unknown> => {
    const path = `api/organizations/validate-email?Email=${email}&Type=giggedclient`;
    const result = await gigApiFetcher( {url: path, token} );

    return result;
};
