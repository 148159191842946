import { useNavigate } from "react-router-dom";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Button from "../../components/Button";
import { useAppPaths } from "../../Routes";

const ViewChatArchiveButton = () => {
    const navigate = useNavigate();
    const appPaths = useAppPaths();

    const { 
        talentPersonalInformation, 
        isLoading: isTalentPersonalInformationLoading 
    } = useTalentPersonalInformationContext();

    const { 
        clientPersonalInformation, 
        isLoading: isClientPersonalInformationLoading 
    } = useClientPersonalInformationContext();

    const hasChatArchive = 
        talentPersonalInformation?.hasChatArchive || 
        clientPersonalInformation?.hasChatArchive;

    if (isTalentPersonalInformationLoading || 
        isClientPersonalInformationLoading ||
        !hasChatArchive) {
        return null;
    }
    
    const handleChatArchiveClicked = () => {
        navigate(appPaths.inbox.archive);
    };

    return (
        <div className="my-4">
            <Button 
                variant="secondary"
                className="flex items-center ml-auto"
                onClick={handleChatArchiveClicked}
            >
                View chat archive
            </Button>
        </div>     
    );

};

export default ViewChatArchiveButton;