export type ApiReportEditableFields = {
    reason: string
    description: string
    typeId: number
    reportedEntityId: string
}

export const reportTypes = {
    GiggedClient: 0,
    Talent: 1,
    Gig: 2,
};