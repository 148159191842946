import { InviteClientUserDto } from "../models/api/client";
import { ApiGiggedClientUsersDto } from "../models/api/clientUser";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useInviteClientUser = () => useAuthenticatedGigApiFetcher<InviteClientUserDto, void, { dto: InviteClientUserDto }>("POST",
    ({ dto }) => ({
        url: "api/me/gigged-clients/actions/invite-user",
        body: dto
    }));

export type UseClientUsersReturn = {
    clientUsers?: ApiGiggedClientUsersDto
    isLoading: boolean
    inviteClientUser: (dto: InviteClientUserDto) => Promise<GigApiFetcherResponse<void>>
    isInvitingClientUser: boolean
}

export const useClientUsers = (): UseClientUsersReturn => {
    const apiCall = useGiggedApiSWR<ApiGiggedClientUsersDto>("api/me/gigged-clients-users");

    const [inviteClientUser, isInvitingClientUser] = useInviteClientUser();

    return {
        clientUsers: apiCall.data,
        isLoading: apiCall.isLoading,
        inviteClientUser: async (dto: InviteClientUserDto) => {
            const response = await inviteClientUser({ dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isInvitingClientUser
    };
};