import { combineReducers } from "redux";
import { Action } from "../actions";
import lastReadIndexReducer from "./lastReadIndexReducer";
import loadingReducer from "./loadingReducer";
import notificationsReducer, { NotificationsType } from "./notificationsReducer";
import participantsReducer, { ParticipantsType } from "./participantsReducer";
import tokenReducer from "./tokenReducer";
import unreadMessagesReducer, { UnreadMessagesState } from "./unreadMessagesReducer";
import messageReducer, { ChatMessagesState } from "./messageListReducer";
import conversationReducer, { ReduxConversation } from "./conversationReducer";
import sidReducer from "./currentConversationReducer";
import attachmentsReducer, { AttachmentsState } from "./attachmentsReducer";

export type AppState = {
    token: string
    conversations: ReduxConversation[]
    sid: string
    messages: ChatMessagesState
    unreadMessages: UnreadMessagesState
    loadingStatus: boolean
    participants: ParticipantsType
    lastReadIndex: number
    notifications: NotificationsType
    attachments: AttachmentsState
}

export const initialState = {
    token: "",
    sid: "",
    messages: {},
    participants: {},
    conversations: [],
    unreadMessages: {},
    loadingStatus: true,
    lastReadIndex: -1,
    notifications: [],
    attachments: {}
};

const reducers = (
    state: AppState | undefined,
    action: Action
): ReturnType<typeof appReducer> => {
    return appReducer(state, action);
};

const appReducer = combineReducers({
    token: tokenReducer,
    conversations: conversationReducer,
    sid: sidReducer,
    lastReadIndex: lastReadIndexReducer,
    messages: messageReducer,
    loadingStatus: loadingReducer,
    participants: participantsReducer,
    unreadMessages: unreadMessagesReducer,
    attachments: attachmentsReducer,
    notifications: notificationsReducer,
});
  
export default reducers;