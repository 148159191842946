import { ApiArchivedChatDto } from "../../../models/api/chatArchive";
import ListHeader from "../ListHeader";
import ArchiveConversationListItem from "./ArchiveConversationListItem";

export type ArchiveConversationListProps = {
    archivedChats: ApiArchivedChatDto[]
    onArchivedChatSelected: (userId: string) => void
}

const ArchiveConversationList = ({ archivedChats, onArchivedChatSelected }: ArchiveConversationListProps) => {
    return (
        <div className="md:w-[33%] border-surface text-secondary rounded-l-lg border-b-[1px] border-l-[1px]">
            <ListHeader name="Archive" className="rounded-tl-lg" variant="dark" />
            <div className="h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar rounded-bl-lg">
                {archivedChats
                    .map((archivedChat) => (
                        <ArchiveConversationListItem
                            key={"archivedchat-" + archivedChat.user.id}
                            archivedChat={archivedChat}
                            onClick={() => onArchivedChatSelected(archivedChat.user.id)}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default ArchiveConversationList;