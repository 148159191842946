import clsx from "clsx";
import { TalentBadgeDetails } from "../models/app/badge";
import Badge from "./Badge";

type BadgeListSize = "default" | "sm" | "xs";

type BadgeListProps = {
    badges: TalentBadgeDetails[]
    keyPrefix: string
    className?: string
    size?: BadgeListSize
}

const BadgeList = ({ badges, keyPrefix, className, size = "default" }: BadgeListProps) => {
    return (
        <div className={clsx("flex gap-1 flex-wrap", className)}>
            {badges.map((badge) => (
                <Badge 
                    {...badge} 
                    key={`${keyPrefix}-${badge.badgeId}`} 
                    className={clsx(
                        size === "sm" && "w-8 h-8",
                        size === "xs" && "w-6 h-6"
                    )} 
                />
            ))}
        </div>
    );
};

export default BadgeList;