import { ApiClientProfileDto } from "../models/api/clientProfile";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseClientProfileReturn = {
    clientProfile?: ApiClientProfileDto,
    isLoading: boolean,
}

export const useClientProfile = (clientId?: string): UseClientProfileReturn => {
    const apiCall = useGiggedApiSWR<ApiClientProfileDto>(`api/gigged-clients/${clientId}/profile`);

    return {
        clientProfile: apiCall.data,
        isLoading: apiCall.isLoading,
    };
};