import clsx from "clsx";
import ToolTip from "./Tooltip";

export type FormInputLabelProps = {
    htmlFor?: string
    children: string
    required?: boolean
    tooltipText?: string
    className?: string
}

const FormInputLabel = ({
    htmlFor,
    children,
    required,
    tooltipText,
    className,
}: FormInputLabelProps) => {
    return (
        <label
            htmlFor={htmlFor}
            className={clsx("text-sm mb-2 md:mb-4 font-bold flex flex-row items-center", className)}
        >
            {required && <span className="text-error font-bold text-label mr-2"> * </span>}
            {children}
            {tooltipText && <ToolTip tooltipText={tooltipText} className="ml-2" />}
        </label>
    );
};

export default FormInputLabel;