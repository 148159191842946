import { Link } from "react-router-dom";
import AuditDataList from "../../components/AuditData/AuditDataList";
import Card from "../../components/Card";
import LinkButton from "../../components/LinkButton";
import Typography from "../../components/Typography";
import { GigSummary } from "../../models/app/gig";
import UserAvatar from "../../components/UserAvatar";
import { formatDateTime } from "../../utils/dateFormatters";
import { useAppPaths } from "../../Routes";

export type CompletedGigsClientCardProps = {
    gig: GigSummary
}

export const CompletedGigsClientCard = ({
    gig
}: CompletedGigsClientCardProps) => {
    const appPaths = useAppPaths();

    return (
        <div key={gig.id} className="md:flex md:items-stretch break-words">
            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-4 md:pb-6 space-y-4">
                <div className="flex flex-col w-full space-y-4">
                    <div className="space-y-4 md:mr-8">
                        <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                        <div>Completed: {gig.completedDate && <p className="italic w-full line-clamp-3 md:mr-8">{formatDateTime(new Date(gig.completedDate))}</p>}</div>
                    </div>
                    <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} completedBy={gig.completedBy} className="md:self-start" />
                </div>
                <LinkButton className="block md:ml-auto" to={appPaths.gigs.details(gig.id)} variant="tertiary">View details</LinkButton>
            </Card>
            {gig.acceptedTalentId && gig.acceptedTalentName && (
                <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-0 md:pt-6">
                    <Link to={appPaths.talents.findTalentDetails(gig.acceptedTalentId)} target="_blank"><UserAvatar name={gig.acceptedTalentName} userImageUrl={gig.acceptedTalentProfileImageThumbnailUrl} /></Link>
                </Card>
            )}
        </div>
    );
};

export default CompletedGigsClientCard;