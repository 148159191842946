import { ChatCircle } from "phosphor-react";
import { useOrganizationContext } from "../../../api/current-organization/organizationContext";
import { ApiArchivedChatDto } from "../../../models/api/chatArchive";
import { themeValues } from "../../../muiTheme";
import Typography from "../../Typography";
import ListHeader from "../ListHeader";
import ArchiveMessagesBox from "./ArchiveMessagesBox";

export type ArchiveMessagesContainerProps = {
    selectedArchivedChat?: ApiArchivedChatDto
    hasAnyArchivedChats: boolean
    handleBackClick?: () => void
}

const ArchiveMessagesContainer = ({ 
    selectedArchivedChat,
    hasAnyArchivedChats,
    handleBackClick,
}: ArchiveMessagesContainerProps) => {
    const { theme } = useOrganizationContext();
    
    return (
        <div className="flex flex-1 flex-col justify-between bg-surface text-secondary border-surface border-t-[1px] border-r-[1px] border-b-[1px] rounded-r-lg">
            {!selectedArchivedChat ? (
                <div className="mx-auto my-auto flex flex-col items-center">
                    <ChatCircle size={55} color={themeValues[theme].primaryMain} />
                    <Typography
                        className="mt-4 font-bold" 
                        component="p" 
                        variant="title-small"
                    >
                        {hasAnyArchivedChats ? "Select a conversation to start" : "You currently don't have any archived chats"}
                    </Typography> 
                </div>
            ) : (
                <>
                    <ListHeader 
                        className="rounded-tr-lg" 
                        name={`${selectedArchivedChat.user.firstName} ${selectedArchivedChat.user.lastName}`} 
                        handleBackClick={handleBackClick}                            
                    />
                    <ArchiveMessagesBox
                        archivedChat={selectedArchivedChat}
                    />
                </>
            )}            
        </div>
    );
};

export default ArchiveMessagesContainer;