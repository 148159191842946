import clsx from "clsx";
import { IconProps } from "phosphor-react";

export type DataPointProps = {
    Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>,
    children: React.ReactNode
    className?: string
}

const DataPoint = ({
    Icon,
    children,
    className,
}: DataPointProps) => {
    return (
        <div className={clsx("flex items-center space-x-2", className)}>
            <Icon weight="duotone" size={24} />
            <span className="block">{children}</span>
        </div>
    );
};

export default DataPoint;
