import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";

export type FindPagePanelSectionProps = {
    title: string
    subtitle?: string
    content: React.ReactNode
    isVisible?: boolean
    isActive?: boolean
    defaultOpen?: boolean
}

const FindPagePanelSection = ({
    title,
    subtitle,
    content,
    isVisible = true,
    isActive = false,
    defaultOpen = false
}: FindPagePanelSectionProps) => {

    if (!isVisible) {
        return null;
    }

    return (
        <Disclosure as="div" className={clsx("border-b border-surface p-4", isActive && "bg-[#0082b730] border-gray-400")} defaultOpen={defaultOpen}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="w-full">
                        <div className={clsx("flex items-center", open && "pb-4")}>
                            <div>
                                <h3 className="font-bold text-left">{title}</h3>
                                {subtitle && (
                                    <p className="text-[0.8rem] text-left">{subtitle}</p>
                                )}
                            </div>
                            {open ? (
                                <CaretUp className="ml-auto block text-primary-text" weight="bold" />
                            ) : (
                                <CaretDown className="ml-auto block text-primary-text" weight="bold" />
                            )}
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        {content}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default FindPagePanelSection;
