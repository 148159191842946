import clsx from "clsx";

export type UserAvatarProps = {
    name: string
    userImageUrl?: string
    showName?: boolean
    size?: "default" | "large"
}

const UserAvatar = ({
    name,
    userImageUrl,
    showName = true,
    size="default"
}: UserAvatarProps) => {

    return (
        <div>
            {userImageUrl ? (
                <div className="flex items-center justify-center mx-auto mb-2 rounded-full">
                    <img
                        src={userImageUrl}
                        className={clsx(
                            "rounded-full object-cover",
                            size === "large" && "h-24 w-24",
                            size === "default" && "h-12 w-12"
                        )}
                    />
                </div>
            ) :
                <div className="h-12 w-12 text-[24px] flex items-center justify-center mx-auto mb-2 rounded-full bg-white">
                    {name[0].toUpperCase()}
                </div>
            }
            {showName && (
                <p className="text-center text-title-small font-bold">
                    {name}
                </p>
            )}
        </div>
    );
};

export default UserAvatar;
