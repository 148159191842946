import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";

import FormTextInput from "../FormTextInput";
import { GigFormValues } from "./GigFormValues";
import { deliveryTypeOptions, expectedDurationOptions, radioOptions } from "../../models/app/gig";
import FormTextAreaInput from "../FormTextAreaInput";
import FormDropdown, { FormDropdownOption, LocalityDropdownOption } from "../FormDropdown";
import GigProductTypeInput from "../GigProductTypeInput";
import { clientEditGigTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import pluralizeString from "../../utils/pluralizeString";
import FormRadioGroup from "../FormRadioGroup";
import IndustrialExperienceDropdown from "../IndustrialExperienceDropdown";
import LanguageDropdown from "../LanguageDropdown";
import FormDateTimeInput from "../FormDateTimeInput";
import useAccessToken from "../../auth/useAccessToken";
import { fetchLocationOptions } from "../../api/locations";

export type CreateGigFormStep8SummaryProps = {
    methods: UseFormReturn<GigFormValues>
    initialValues: GigFormValues
    hideSkillsDropdown?: boolean
}

const GigFormFields = ({
    methods,
    initialValues,
    hideSkillsDropdown,
}: CreateGigFormStep8SummaryProps) => {
    const accessToken = useAccessToken();
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<LocalityDropdownOption<string>[]>([]);

    const { gigTerminology, talentTerminology } = useOrganizationContext();
    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigFormValues, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();

    const isDesignFeeRequired = methods.watch("isDesignFeeTypeRequired");
    const isConsultancyFeeRequired = methods.watch("isConsultancyFeeTypeRequired");
    const isPrepAndEvalFeeReqired = methods.watch("isPrepAndEvalFeeTypeRequired");

    useEffect(function resetFeeTypeFieldsWhenFeeIsFalse() {
        if (isDesignFeeRequired === "false") methods.setValue("designFeeTypeHours", undefined);
        if (isConsultancyFeeRequired === "false") methods.setValue("consultancyFeeTypeHours", undefined);
        if (isPrepAndEvalFeeReqired === "false") methods.setValue("prepAndEvalFeeTypeHours", undefined);
    }, [isDesignFeeRequired, isConsultancyFeeRequired, isPrepAndEvalFeeReqired]);

    useEffect(function setLocationOptions() {
        if (!accessToken) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (selectedLocationLocalities.length < 1 || initialValues.locationId === selectedLocationId || isLoadingLocationOptions) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const handleLocalityChange = (value: string) => {
        const selectedLocality = selectedLocationLocalities.find(locality => locality.value === value);
        const selectedIanaId = selectedLocality?.ianaId || undefined;

        methods.setValue("localityId", value);
        methods.setValue("ianaId", selectedIanaId);
    };

    return (
        <>
            <div className="md:flex">
                <div className="space-y-6 md:pr-8 md:w-1/2">
                    <FormTextInput
                        required
                        id="create-gig-form-summary-title"
                        label={"Client name"}
                        placeholder={`Enter name of the ${gigTerminology.toLowerCase()}`}
                        error={methods.formState.errors.title}
                        defaultValue={initialValues.title}
                        // tooltip={clientEditGigTooltips.gigName(gigTerminology)}
                        {...methods.register("title")}
                    />
                    <Controller
                        name="hasClientHistory"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label="Have we worked with this client before?"
                                options={radioOptions}
                                error={methods.formState.errors.hasClientHistory}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        name="industryId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <IndustrialExperienceDropdown
                                required
                                error={methods.formState.errors.industryId}
                                onChange={onChange}
                                value={value || ""}
                            />
                        )}
                    />
                    <FormTextInput
                        id="create-gig-form-opportunity-link"
                        label="Enter opportunity link"
                        placeholder="Please provide the URL from D365"
                        error={methods.formState.errors.opportunityLink}
                        defaultValue={initialValues.opportunityLink}
                        {...methods.register("opportunityLink")}
                    />
                    <Controller
                        name="isAudienceExecutive"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label="Is the audience Senior Executive/C-Suite level?"
                                options={radioOptions}
                                error={methods.formState.errors.isAudienceExecutive}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <FormTextAreaInput
                        id="create-gig-form-delegate-information"
                        label="Delegate information"
                        placeholder="Please provide any additional information which would help us to source the best fit AC for your client's event"
                        register={methods.register("delegateInformation")}
                        error={methods.formState.errors.delegateInformation}
                        defaultValue={initialValues.delegateInformation}
                        rows={4}
                        isResizeable
                    />
                    <Controller
                        name="isDesignFeeTypeRequired"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label="Does the event require customisation of content and/or addition of new content?"
                                options={radioOptions}
                                error={methods.formState.errors.isDesignFeeTypeRequired}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    {isDesignFeeRequired == "true" && (
                        <FormTextInput
                            required={isDesignFeeRequired == "true" ? true : false}
                            id="create-gig-form-design-fee-hours"
                            label="No. of design hours"
                            placeholder="Number of hours"
                            error={methods.formState.errors.designFeeTypeHours}
                            defaultValue={initialValues.designFeeTypeHours}
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            {...methods.register("designFeeTypeHours")}
                        />
                    )}
                    <Controller
                        name="isConsultancyFeeTypeRequired"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label="Does the event require time spent using consultancy expertise to assist the client in achieveing the value or impact required?"
                                options={radioOptions}
                                error={methods.formState.errors.isConsultancyFeeTypeRequired}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    {isConsultancyFeeRequired == "true" && (
                        <FormTextInput
                            required={isConsultancyFeeRequired == "true" ? true : false}
                            id="create-gig-form-consultancy-fee"
                            label="No. of consultancy hours"
                            placeholder="Number of hours"
                            error={methods.formState.errors.consultancyFeeTypeHours}
                            defaultValue={initialValues.consultancyFeeTypeHours}
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            {...methods.register("consultancyFeeTypeHours")}
                        />
                    )}
                    <Controller
                        name="isPrepAndEvalFeeTypeRequired"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label="Does the event require additional time spent in preparation or evaluation to achieve a successful outcome? (prep and eval)?"
                                options={radioOptions}
                                error={methods.formState.errors.isPrepAndEvalFeeTypeRequired}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    {isPrepAndEvalFeeReqired == "true" && (
                        <FormTextInput
                            required={isPrepAndEvalFeeReqired == "true" ? true : false}
                            id="create-gig-form-prep-and-eval-fee"
                            label="No. of prep and eval hours"
                            placeholder="Number of hours"
                            error={methods.formState.errors.prepAndEvalFeeTypeHours}
                            defaultValue={initialValues.prepAndEvalFeeTypeHours}
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            {...methods.register("prepAndEvalFeeTypeHours")}
                        />
                    )}
                </div>
                <div className="space-y-6 md:pl-8 md:w-1/2 mt-8 md:mt-0">
                    <GigProductTypeInput
                        addSkill={appendSkill}
                        removeSkill={removeSkill}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                        hideDropdown={hideSkillsDropdown}
                        tooltip={clientEditGigTooltips.gigSkills(gigTerminology, pluralizeString(talentTerminology))}
                    />
                    <Controller
                        name="languageId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <LanguageDropdown
                                required
                                label={`Language this ${gigTerminology.toLowerCase()} is to be delivered in`}
                                error={methods.formState.errors.languageId}
                                onChange={onChange}
                                value={value || ""}
                            />
                        )}
                    />
                    <FormTextInput
                        required
                        id="create-gig-form-expected-number-participants"
                        label="What are the expected number of participants?"
                        error={methods.formState.errors.expectedNumberParticipants}
                        defaultValue={initialValues.expectedNumberParticipants}
                        type="number"
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        placeholder="Number of particpants"
                        {...methods.register("expectedNumberParticipants")}
                    />
                    <FormTextAreaInput
                        id="create-gig-form-summary-description"
                        label="Are there any set objectives the client wished to get out of the event?"
                        placeholder={`Enter ${gigTerminology.toLowerCase()} description`}
                        register={methods.register("description")}
                        error={methods.formState.errors.description}
                        defaultValue={initialValues.description}
                        tooltip={clientEditGigTooltips.gigDescription(gigTerminology)}
                    />
                    <Controller
                        name="deliveryTypeId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="What is the required delivery format for this event?"
                                options={deliveryTypeOptions}
                                error={methods.formState.errors.deliveryTypeId}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    {allLocationOptions?.length > 0 ? (
                        <Controller
                            name="locationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="What country is the client located in?"
                                    value={value || ""}
                                    options={allLocationOptions}
                                    disabled={isLoadingLocationOptions}
                                    onChange={onChange}
                                    error={methods.formState.errors.locationId}
                                    isLoadingOptions={allLocationOptions.length < 1 || isLoadingLocationOptions}
                                />
                            )}
                        />
                    ) : <div>Loading...</div>}
                    {selectedLocationLocalities?.length > 0 && (
                        <Controller
                            name="localityId"
                            control={methods.control}
                            render={({ field: { value } }) => (
                                <FormDropdown
                                    required
                                    label="City"
                                    value={value || ""}
                                    options={selectedLocationLocalities.map(locality => ({
                                        value: locality.value,
                                        label: locality.label,
                                        ianaId: locality.ianaId
                                    }))}
                                    disabled={selectedLocationLocalities.length < 1 || isLoadingLocationOptions}
                                    onChange={handleLocalityChange}
                                    error={methods.formState.errors.localityId}
                                    isLoadingOptions={selectedLocationLocalities.length < 1}
                                />
                            )}
                        />
                    )}
                    <Controller
                        name="startDate"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateTimeInput
                                required
                                id="create-gig-form-start-date"
                                label="Select date / time of event"
                                value={value}
                                onChange={onChange}
                                error={methods.formState.errors.startDate}
                                minDate={new Date()}
                            />
                        )}
                    />
                    <Controller
                        name="expectedDurationId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="Duration"
                                options={expectedDurationOptions}
                                error={methods.formState.errors.expectedDurationId}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <FormTextAreaInput
                        id="create-gig-form-notes"
                        label="Notes"
                        error={methods.formState.errors.notes}
                        defaultValue={initialValues.notes}
                        placeholder="Add any relevant notes"
                        rows={7}
                        register={methods.register("notes")}
                    />
                </div>
            </div >
        </>
    );
};

export default GigFormFields;
