import React, { useEffect } from "react";
import LogRocket from "logrocket";

import useCurrentUser from "../auth/useCurrentUser";

export type LogRocketProviderProps = {
    children: React.ReactElement
}
export const LogRocketProvider = ({
    children
}: LogRocketProviderProps) => {
    const user = useCurrentUser();

    useEffect(() => {
        if (!user || process.env.NODE_ENV === "development") return;

        if (user.userRole === "client") {
            LogRocket.identify(user.idpUserId, {
                clientId: user.clientId || "",
                userRole: user.userRole,
                name: `${user.userGivenName} ${user.userFamilyName}`,
                email: user.email,
                companyName: user.clientCompanyName || "",
                idpUserId: user.idpUserId,
                isVerified: user.clientIsVerified || false,
                orgName: user.organizationName || ""
            });
        } else if (user.userRole === "talent") {
            LogRocket.identify(user.idpUserId, {
                talentId: user.talentId || "",
                userRole: user.userRole,
                name: `${user.userGivenName} ${user.userFamilyName}`,
                email: user.email,
                tradingName: user.talentTradingName || "",
                idpUserId: user.idpUserId,
                orgName: user.organizationName || "",
            });
        } else {
            LogRocket.identify(user.idpUserId, {
                idpUserId: user.idpUserId,
                userRole: user.userRole || "User role undefined",
                name: `${user.userGivenName} ${user.userFamilyName}`,
                email: user.email,
                orgName: user.organizationName || "",
            });
        }
    }, [user]);

    return (
        <>
            {children}
        </>
    );
};