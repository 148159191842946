import { Message, Paginator } from "@twilio/conversations";
import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { getSdkConversationObject } from "../../../utils/conversationsObjects";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import { ReduxMessage } from "../../../store/reducers/messageListReducer";
import { ReduxParticipant } from "../../../store/reducers/participantsReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageList from "./MessageList";
import { getMessages, loadMessages } from "../../../api/conversations";
import { AddMessagesType } from "../../../models/app/conversation";

export type MessagesBoxProps = {
    conversation: ReduxConversation
    addMessage: AddMessagesType
    messages: ReduxMessage[]
    loadingStatus: boolean
    participants: ReduxParticipant[]
    lastReadIndex: number
};

const MessagesBox = ({
    conversation,
    addMessage,
    messages,
    loadingStatus,
    participants,
    lastReadIndex
}: MessagesBoxProps) => {
    const [paginator, setPaginator] = useState<Paginator<Message> | null>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const sdkConversation = useMemo(() => getSdkConversationObject(conversation), [conversation.sid]);

    useEffect(() => {
        if (!messages && conversation && !loadingStatus) {
            loadMessages(conversation, addMessage);
        }
    }, []);

    useEffect(() => {
        getMessages(sdkConversation).then((paginator) => {
            setPaginator(paginator);
        });
    }, [conversation]);

    useEffect(() => {
        if (messages?.length && messages[messages.length - 1].index !== -1) {
            sdkConversation.updateLastReadMessageIndex(messages[messages.length - 1].index);
        }
    }, [messages, conversation]);

    const lastConversationReadIndex = useMemo(
        () =>
            messages?.length &&
                messages[messages.length - 1].author !== localStorage.getItem("username")
                ? lastReadIndex
                : -1,
        [lastReadIndex, messages]
    );

    const fetchMore = async () => {
        if (!paginator) {
            return;
        }
        const result = await paginator?.prevPage();
        if (!result) {
            return;
        }
        const moreMessages = result.items;

        setPaginator(result);
        addMessage(conversation.sid, moreMessages);
    };

    return (
        <InfiniteScroll
            dataLength={messages?.length ?? 0}
            next={fetchMore}
            hasMore={Boolean(paginator?.hasPrevPage)}
            endMessage={<div className="p-8 mx-auto">No more messages.</div>}
            loader={<div className="p-8 font-bold mx-auto"><CircularProgress size={32} /></div>}
            scrollableTarget="scrollable-div"
            className="flex flex-col-reverse"
            inverse={true}
            scrollThreshold="20px"
        >
            <div ref={listRef} className="flex flex-col justify-end items-end">
                <MessageList
                    messages={messages ?? []}
                    conversation={conversation}
                    participants={participants}
                    lastReadIndex={lastConversationReadIndex}
                />
            </div>
        </InfiniteScroll>
    );
};

export default MessagesBox;