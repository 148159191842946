import React from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { CaretDown, CaretRight } from "phosphor-react";

export type SideNavMenuItemConfig = {
    name: string
    icon?: JSX.Element
    adornment?: JSX.Element
    isCollection: false
    href: string
    productFruitsId?: string
} | {
    name: string
    icon?: JSX.Element
    isCollection: true
    productFruitsId?: string
    subItems: {
        name: string
        href: string
        onClick?: () => void
    }[]
};

export type SideBarMenuItemProps = {
    item: SideNavMenuItemConfig
    onLinkClick: () => void
}

const SideBarMenuItem = React.forwardRef<HTMLAnchorElement, SideBarMenuItemProps>(({
    item,
    onLinkClick,
}, ref) => {
    if (!item.isCollection) {
        return (
            <Disclosure.Button
                key={item.name}
                as={Link}
                to={item.href}
                className="flex items-center text-primary-text px-3 py-2"
                ref={ref}
            >
                {item.icon}
                <p className="ml-4">{item.name}</p> 
                {item.adornment}
            </Disclosure.Button>
        );
    }

    return (
        <div>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className="flex w-full items-center text-primary-text px-3 py-2"
                        >
                            {item.icon}
                            <p className="ml-4" id={item.productFruitsId}>{item.name}</p>
                            {open ? (
                                <CaretDown className="ml-auto" />
                            ) : (
                                <CaretRight className="ml-auto" />
                            )}
                        </Disclosure.Button>
                        <Disclosure.Panel
                            className="pl-6 mt-2"
                        >
                            {item.subItems.map(subItem => (
                                <Disclosure.Button
                                    key={subItem.href}
                                    as={Link}
                                    className="block text-primary-text px-3 py-2"
                                    to={subItem.href}
                                    onClick={() => {
                                        if (subItem.onClick) subItem.onClick();
                                        onLinkClick();
                                    }}
                                >
                                    {subItem.name}
                                </Disclosure.Button>
                            ))}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
});

export default SideBarMenuItem;
