import { FunnelSimple } from "phosphor-react";
import { useEffect, useState } from "react";

import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { gigStatuses } from "../../models/app/gig";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Loader from "../../components/Loader";
import MyGigsNavTabs from "./MyGigsNavTabs";
import PostedGigsCard from "./PostedGigsCard";
import DraftGigsCard from "./DraftGigsCard";
import OngoingGigsClientCard from "./OngoingGigsClientCard";
import CancelledGigsClientCard from "./CancelledGigsClientCard";
import CompletedGigsClientCard from "./CompletedGigsClientCard";
import PendingGigsCard from "./PendingGigsCard";
import { useNavigate, useParams } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import MyGigsFilterDrawer from "./MyGigsFilterDrawer";
import { useFindAGigContext } from "../FindAGig/findAGigContext";

const gigStatusCardComponents = {
    [gigStatuses.draft]: DraftGigsCard,
    [gigStatuses.posted]: PostedGigsCard,
    [gigStatuses.hired]: OngoingGigsClientCard,
    [gigStatuses.cancelled]: CancelledGigsClientCard,
    [gigStatuses.completed]: CompletedGigsClientCard,
    [gigStatuses.pending]: PendingGigsCard
};

const MyGigsList = () => {
    const { gigStatus } = useParams<{ gigStatus: string }>();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const gigStatusId = gigStatus ? gigStatuses[gigStatus] : gigStatuses.draft;
    const { gigTerminologyPlural } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    const {
        isLoading,
        gigs,
        anyActiveFilters,
        setGigStatusIds,
    } = useFindAGigContext();

    useEffect(() => {
        setGigStatusIds([gigStatusId]);
    }, [gigStatusId, setGigStatusIds]);

    useEffect(() => {
        if (!gigStatus || !gigStatuses[gigStatus]) {
            navigate(appPaths.gigs.myGigs("draft") + window.location.search, { replace: true });
        }
    }, [gigStatus, navigate]);

    return (
        <>
            <PageContent>
                <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>{`My ${gigTerminologyPlural}`}</Typography>
                <div className="flex flex-row items-center max-w-6xl mb-6">
                    <div className="flex-grow pr-6">
                        <MyGigsNavTabs currentGigStatus={gigStatusId} />
                    </div>
                    <button
                        className={`flex flex-col items-center justify-center p-4 outline-none ${anyActiveFilters || window.location.search ? "text-primary" : ""}`}
                        onClick={() => setIsDrawerOpen(true)}
                    >
                        <FunnelSimple size={32} weight={anyActiveFilters || window.location.search ? "bold" : "regular"} />
                    </button>
                </div>
                {isLoading ? <Loader /> : gigs.length === 0 ? (
                    <Card>
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </Card>
                ) : (
                    <div className="space-y-8">
                        {gigs.map(gig => {
                            const GigCardComponent = gigStatusCardComponents[gig.gigStatusId];
                            return GigCardComponent ? <GigCardComponent key={gig.id} gig={gig} /> : null;
                        })}
                    </div>
                )}
                <MyGigsFilterDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
            </PageContent>
        </>
    );
};

export default MyGigsList;