import React from "react";
import { SortOrder } from "../../api/common/sortOrder";
import { UseGigsReturn } from "../../api/gigs";
import { GigOrderByFieldKeys } from "../../models/app/gig";

import contextPlaceholderFunc from "../../utils/contextPlaceholderFun";

export type FindAGigContextType = Pick<UseGigsReturn, "gigs" | "isLoading" | "setSearchTerm"> & {
    searchTerm: string
    referenceNumber: string
    gigTitle: string
    skillIds: string[]
    expectedDurationIds: number[]
    hasClientHistory: string
    sortOrder: SortOrder
    orderBy: GigOrderByFieldKeys
    locationIds: string[]
    anyActiveFilters: boolean
    gigStatusIds: number[] 
    localityIds: string[]
    deliveryTypeIds: number[]
    createdBy: string
    inviteCreatedBy: string
    talentName: string
    startDate: string
    allClients: boolean
    setReferenceNumber: (value: string) => void
    setGigTitle: (value: string) => void
    setSkillIds: (skillIds: string[]) => void
    setExpectedDurationIds: (expectedDurationIds: number[]) => void
    setHasClientHistory: (value: string) => void
    setLocationIds: (locationIds: string[]) => void
    setSortOrder: (order: SortOrder) => void
    setOrderBy: (orderBy: GigOrderByFieldKeys) => void
    setGigStatusIds: (gigStatusIds: number[]) => void 
    setLocalityIds: (localityIds: string[]) => void 
    setDeliveryTypeIds: (deliveryTypeIds: number[]) => void
    setCreatedBy: (createdBy: string) => void
    setInviteCreatedBy: (inviteCreatedBy: string) => void
    setTalentName: (talentName: string) => void
    setStartDate: (startDate: string) => void
    clearAllFilters: () => void
    searchNow: () => void
    refetchGigs: () => void
    setAllClients: (allClients: boolean) => void
}

export const FindAGigContext = React.createContext<FindAGigContextType>({
    gigs: [],
    isLoading: false,
    anyActiveFilters: false,
    setSearchTerm: contextPlaceholderFunc,
    setReferenceNumber: contextPlaceholderFunc,
    setGigTitle: contextPlaceholderFunc,
    setSkillIds: contextPlaceholderFunc,
    setExpectedDurationIds: contextPlaceholderFunc,
    setHasClientHistory: contextPlaceholderFunc,
    setSortOrder: contextPlaceholderFunc,
    setOrderBy: contextPlaceholderFunc,
    setGigStatusIds: contextPlaceholderFunc, 
    setLocationIds: contextPlaceholderFunc,
    setLocalityIds: contextPlaceholderFunc,
    setDeliveryTypeIds: contextPlaceholderFunc,
    setCreatedBy: contextPlaceholderFunc,
    setInviteCreatedBy: contextPlaceholderFunc,
    setTalentName: contextPlaceholderFunc,
    setStartDate: contextPlaceholderFunc,
    setAllClients: contextPlaceholderFunc,
    searchTerm: "",
    referenceNumber: "",
    gigTitle: "",
    skillIds: [],
    expectedDurationIds: [],
    hasClientHistory: "",
    gigStatusIds: [], 
    sortOrder: "asc",
    orderBy: "Title",
    locationIds: [],
    localityIds: [],
    deliveryTypeIds: [],
    createdBy: "",
    inviteCreatedBy: "",
    talentName: "",
    startDate: "",
    clearAllFilters: contextPlaceholderFunc,
    searchNow: contextPlaceholderFunc,
    refetchGigs: contextPlaceholderFunc,
    allClients: true
});

export const useFindAGigContext: () => FindAGigContextType = () => React.useContext(FindAGigContext);

export default FindAGigContext;