import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import ExternalLinkButton from "../../components/ExternalLinkButton";
import GigTalentMatchCard from "../../components/GigTalentMatchCard";
import TitleUnderline from "../../components/TitleUnderline";
import Typography from "../../components/Typography";
import { ApiExternalMatch } from "../../models/api/gigMatch";
import { GigMatch } from "../../models/app/gigMatch";
import GigTalentExternalMatchCard from "../../components/GigTalentExternalMatchCard";

export type MatchesListProps = {
    gigId: string
    matches: GigMatch[]
    externalMatches?: ApiExternalMatch[]
}

const MatchesList = ({
    gigId,
    matches,
    externalMatches = []
}: MatchesListProps) => {
    const { organizationConfig: { isExternalMatchingEnabled } } = useOrganizationContext();

    return (
        <>
            {matches.length === 0 ? (
                <Card>
                    <Typography variant="body" component="p">Looks like we couldn't find any matches at this time. Please try again later.</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {matches.map(match => (
                        <GigTalentMatchCard key={match.talentId} gigId={gigId} match={match} />
                    ))}
                </div>
            )}

            {(isExternalMatchingEnabled && externalMatches && externalMatches.length !==0) && (
                <>
                    <div className="flex flex-col space-y-4 text-center items-center">
                        <TitleUnderline />
                        <Typography variant="title-large" component="h3">Not found what you're looking for?</Typography>
                        <Typography variant="title-small" component="h3">Check out these matches from Gigged.AI:</Typography>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8">
                        {externalMatches.map(match => (
                            <GigTalentExternalMatchCard
                                key={match.talentId}
                                match={match}
                            />
                        ))}
                    </div>
                    <div className="mx-auto">
                        <ExternalLinkButton href="https://gigged.ai" target="_blank">
                            Visit Gigged.AI
                        </ExternalLinkButton>
                    </div>
                </>
            )}
        </>
    );
};

export default MatchesList;
