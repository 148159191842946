import clsx from "clsx";
import { GigSummary } from "../../models/app/gig";
import Card from "../../components/Card";
import Typography from "../../components/Typography";
import AuditDataList from "../../components/AuditData/AuditDataList";
import LinkButton from "../../components/LinkButton";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar";
import { useAppPaths } from "../../Routes";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";


export type CancelledGigsClientCardProps = {
    gig: GigSummary
}

export const CancelledGigsClientCard = ({
    gig
}: CancelledGigsClientCardProps) => {
    const appPaths = useAppPaths();
    const { talentTerminology } = useOrganizationContext();

    return (
        <div key={gig.id} className="md:flex md:items-stretch break-words">
            <Card className={clsx(
                "flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 md:rounded-b-lg pb-2 md:pb-6",
                gig.acceptedTalentName && "rounded-b-none"
            )}>
                <div className="space-y-4 md:mr-8">
                    <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                    <div>Cancelled reason: {gig.cancelledDate && <p className="italic line-clamp-3 md:mr-8">{gig.cancelledReason}</p>}</div>
                    <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} cancelledBy={gig.cancelledBy} />
                </div>
                <LinkButton className="block md:ml-auto" to={appPaths.gigs.details(gig.id)} variant="tertiary">View details</LinkButton>
            </Card>
            <Card className="shrink-0 md:w-[230px] flex items-center justify-center text-center rounded-t-none md:rounded-t-lg pt-0 md:pt-6">
                {gig.acceptedTalentName && gig.acceptedTalentId ? (
                    <Link to={appPaths.talents.findTalentDetails(gig.acceptedTalentId)} target="_blank"><UserAvatar name={gig.acceptedTalentName} userImageUrl={gig.acceptedTalentProfileImageThumbnailUrl} /></Link>
                ) : (
                    <p>No hired {talentTerminology.toLowerCase()}</p>
                )}
            </Card>
        </div>
    );
};

export default CancelledGigsClientCard;