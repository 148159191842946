import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InboxContainer } from "../../components/Conversations";
import PageContent from "../../components/PageContent";
import { AppState, actionCreators } from "../../store";
import ViewChatArchiveButton from "./ViewChatArchiveButton";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateCurrentConversationSdk } from "../../api/conversations";
import { ReduxConversation } from "../../store/reducers/conversationReducer";
import { unexpectedErrorNotification } from "../../utils/conversationsHelpers";
import { getSdkConversationObject } from "../../utils/conversationsObjects";

const InboxPage = () => {
    const { conversationId } = useParams();
    const conversations = useSelector((state: AppState) => state.conversations);
    const sid = useSelector((state: AppState) => state.sid);
    const [fromQueryParam, setFromQueryParam] = useState<boolean>(false);

    const messages = useSelector((state: AppState) => state.messages);
    const [messageBoxInView, setMessageBoxInView] = useState(false);

    const dispatch = useDispatch();
    const {
        updateCurrentConversation,
        updateParticipants,
        updateUnreadMessages,
        setLastReadIndex,
        addNotifications,
    } = bindActionCreators(actionCreators, dispatch);


    const openedConversation = useMemo(
        () => conversations.find((conversation) => conversation.sid === sid),
        [sid, conversations]
    );

    const handleConversationClicked = async (conversation: ReduxConversation) => {
        setMessageBoxInView(true);

        try {
            setLastReadIndex(conversation.lastReadMessageIndex ?? -1);

            await updateCurrentConversationSdk(
                updateCurrentConversation,
                conversation,
                updateParticipants
            );

            updateUnreadMessages(conversation.sid, 0);

            const lastMessage =
                messages[conversation.sid].length &&
                messages[conversation.sid][messages[conversation.sid].length - 1];

            if (lastMessage && lastMessage.index !== -1) {
                await getSdkConversationObject(
                    conversation
                ).updateLastReadMessageIndex(lastMessage.index);
            }
        } catch {
            unexpectedErrorNotification(addNotifications);
        }
    };

    useEffect(() => {
        if(conversationId && conversationId.length > 0) {
            const conversation = conversations.find(conversation => conversation.sid === conversationId);

            if(conversation) {
                setFromQueryParam(true);
                handleConversationClicked(conversation);
            }
        }
    }, [conversationId]);

    const handleBackClick = () => {
        setMessageBoxInView(false);
    };

    return (
        <PageContent>    
            <ViewChatArchiveButton />
            <InboxContainer 
                openedConversation={openedConversation} 
                handleConversationClicked={handleConversationClicked} 
                handleBackClick={handleBackClick}
                messageBoxInView={messageBoxInView}
                setFromQueryParam={setFromQueryParam}
                fromQueryParam={fromQueryParam}
            />
        </PageContent>
    );
};

export default InboxPage;