import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { CheckSquare, Square } from "phosphor-react";

export type FormCheckboxGroupOption<TValue extends string | number> = {
    label: string,
    value: TValue,
}

export type FormCheckboxGroupProps<TValue extends string | number> = {
    allOptions: FormCheckboxGroupOption<TValue>[]
    selectedOptionIds: TValue[],
    onChange: (selectedOptionIds: TValue[]) => void
    className?: string
    loading?: boolean
    fetchMore?: () => void
    hasMore?: boolean
    noResultsMessage?: string | null
    disabled?: boolean
}

const FormCheckboxGroup = <TValue extends string | number>({
    allOptions,
    selectedOptionIds,
    onChange,
    className,
    loading,
    fetchMore,
    hasMore,
    noResultsMessage,
    disabled
}: FormCheckboxGroupProps<TValue>) => {
    const buildHandleChange = (optionValue: TValue) => (checked: boolean) => {
        if (checked) {
            onChange([...selectedOptionIds, optionValue]);
        }
        else {
            onChange(selectedOptionIds.filter(_ => _ !== optionValue));
        }
    };

    if (allOptions.length === 0 && !noResultsMessage) return <p>Loading...</p>;
    return (
        <div className={clsx("space-y-1", className)}>
            {allOptions.map(option => {
                const checked = selectedOptionIds.includes(option.value);
                return (
                    <Switch
                        key={option.value}
                        checked={checked}
                        onChange={buildHandleChange(option.value)}
                        className="w-full"
                        disabled={disabled && !checked}
                    >
                        <div className={clsx("flex items-center w-full text-gray-700", disabled && !checked && "opacity-50")}>
                            <label className="block text-left mr-4 grow">{option.label}</label>
                            {checked ? (
                                <CheckSquare size="20" weight="bold" className="shrink-0" />
                            ) : (
                                <Square size="20" weight="bold" className="shrink-0" />
                            )}
                        </div>
                    </Switch>
                );
            })}
            {noResultsMessage ? <p>No options found</p> : loading ? <p>Loading...</p> : null}
            {(hasMore && !loading && allOptions.length != 0) && <div className="text-link text-center underline cursor-pointer" onClick={fetchMore}>Load more</div>}
        </div>
    );
};

export default FormCheckboxGroup;
