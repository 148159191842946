import { Check, X } from "phosphor-react";
import clsx from "clsx";
import { ApiTalentDeliveryTypeDto } from "../../models/api/talentDeliveryType";

export type DeliveryTypeDisplayProps = {
    deliveryTypes: ApiTalentDeliveryTypeDto[]
}

export const DeliveryTypeDisplay = ({
    deliveryTypes
}: DeliveryTypeDisplayProps) => {
    const allDeliveryTypes = [
        { id: 0, name: "Face to Face", isSelected: false },
        { id: 1, name: "Virtual", isSelected: false },
    ];

    allDeliveryTypes.forEach(deliveryType => {
        deliveryType.isSelected = deliveryTypes.some(type => type.deliveryTypeId === deliveryType.id);
    });

    const sortedDeliveryTypes = allDeliveryTypes.sort((a, b) => +b.isSelected - +a.isSelected);

    return (
        <div>
            {sortedDeliveryTypes.map((deliveryType) => (
                <div key={deliveryType.id} className="flex flex-row gap-2 items-center">
                    {deliveryType.isSelected ? <Check size={18} /> : <X size={18} color="gray" />}
                    <p className={clsx(!deliveryType.isSelected && "text-disabled-text line-through")}>{deliveryType.name}</p>
                </div>
            ))}
        </div>
    );
};