import { Button } from "@mui/material";
import { X } from "phosphor-react";
import { ReduxMedia } from "../../../store/reducers/messageListReducer";


type MessageFileProps = {
    media: ReduxMedia | { filename: string; size: number };
    onRemove?: () => void;
}

const MessageFile: React.FC<MessageFileProps> = ({
    media,
    onRemove
}) => {
    const { filename, size } = media;
    const name = filename ?? "";

    return ( 
        <div style={{
            display: "flex",
            alignItems: "center",
            padding: "12px 16px",
            margin: "6px 6px 6px 6px",
            border: "1px solid #CACDD8",
            boxSizing: "border-box",
            borderRadius: "4px",
            width: "calc(25%)",
            maxWidth: "250px",
            minWidth: "150px",
            backgroundColor: "#fff",
            cursor: "default",
        }}>
            <div className="truncate">
                <p className="truncate">{name}</p>
                <p>{Math.round((size / Math.pow(2, 20)) * 100) / 100} MB</p>
            </div>

            {
                onRemove ? (
                    <Button onClick={onRemove}>
                        <X size={20}/>
                    </Button>
                ) : null
            }
        </div>
    );
};
 
export default MessageFile;