import clsx from "clsx";
import { AuditDto } from "../../models/app/audit";
import AuditDataListItem from "./AuditDataListItem";

export type AuditDataListProps = {
    className?: string
    createdBy?: AuditDto
    sentBy?: AuditDto
    lastUpdatedBy?: AuditDto
    cancelledBy?: AuditDto
    completedBy?: AuditDto
    onHoldBy?: AuditDto
    postedBy?: AuditDto
    referenceNumber?: string
}

const AuditDataList = ({
    className,
    createdBy,
    lastUpdatedBy,
    cancelledBy,
    completedBy,
    sentBy,
    postedBy,
    referenceNumber
}: AuditDataListProps) => {
    return (
        <div className={clsx("text-[0.75rem] italic text-[#666]", className)}>
            {referenceNumber && <span className="space-x-4"><strong>Reference No:</strong> {referenceNumber}</span> }
            <AuditDataListItem
                auditData={createdBy}
                label="Created by"
            />
            <AuditDataListItem
                auditData={lastUpdatedBy}
                label="Last updated"
            />
            <AuditDataListItem
                auditData={cancelledBy}
                label="Cancelled by"
            />
            <AuditDataListItem
                auditData={sentBy}
                label="Sent by"
            />
            <AuditDataListItem
                auditData={completedBy}
                label="Completed by"
            />
            <AuditDataListItem
                auditData={postedBy}
                label="Posted by"
            />
            {!createdBy && !lastUpdatedBy && !cancelledBy && !completedBy && !sentBy && (
                <div>
                    No audit information available.
                </div>
            )}
        </div>
    );
};

export default AuditDataList;