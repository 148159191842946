import { ThemeProvider } from "@emotion/react";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import theme from "../muiTheme";

type AppThemeProps = {
    children: React.ReactNode
}

const AppTheme: React.FC<AppThemeProps> = ({
    children
}) => {
    const { theme: appTheme } = useOrganizationContext();
    const muiTheme = theme(appTheme);

    return (
        <div data-theme={appTheme}>
            <ThemeProvider theme={muiTheme}>
                {children}
            </ThemeProvider>
        </div>
    );
};

export default AppTheme;