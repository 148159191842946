import { RadioGroup } from "@headlessui/react";
import { FieldError } from "react-hook-form";
import { RadioButton, Circle } from "phosphor-react";

import clsx from "clsx";
import FormInputMessage from "./FormInputError";
import FormInputLabel from "./FormInputLabel";

export type FormRadioGroupOption = {
    label: string,
    value: number | string,
}

export type FormRadioGroupProps = {
    label?: string
    value: number | string,
    onChange: (value: string | number) => void
    options: FormRadioGroupOption[]
    error?: FieldError
    defaultValue: number | string
    required?: boolean
    helpText?: string
};

const FormRadioGroup = ({
    label,
    value,
    onChange,
    options,
    error,
    defaultValue,
    required,
    helpText,
}: FormRadioGroupProps) => {
    return (
        <div className="w-full">
            <RadioGroup defaultValue={defaultValue} value={value} onChange={onChange}>
                {label && (
                    <FormInputLabel required={required}>{label}</FormInputLabel>
                )}
                <div className="space-y-4">
                    {options.map(option => (
                        <RadioGroup.Option
                            key={option.value}
                            value={option.value}
                            className="relative flex cursor-pointer focus:outline-none"
                        >
                            {({ checked }) => (
                                <div className="flex w-full items-center">
                                    <div className="shrink-0">
                                        {checked ? (
                                            <RadioButton className="text-primary-text" size={24} weight="fill" />
                                        ) : (
                                            <Circle className="text-primary-text" size={24} />
                                        )}
                                    </div>
                                    <div className="text-label ml-4">
                                        <RadioGroup.Label
                                            as="p"
                                            className={clsx("font-medium text-primary-text")}
                                        >
                                            {option.label}
                                        </RadioGroup.Label>
                                    </div>
                                </div>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            <FormInputMessage className="ml-0 mt-8" error={error} helpText={helpText} />
        </div>
    );
};


export default FormRadioGroup;