const formatDateOnlyString = (parseableDateString?: string): string => {
    if (!parseableDateString) return "";

    return new Date(parseableDateString).toLocaleDateString();
};

export default formatDateOnlyString;

export function formatDateToShortString(date: Date | string | null): string {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
}

export function parseShortDateString(dateString: string): Date | null {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
}