const getFileExtensionFromName = (name: string): string => {
    if (!name) return "";
    
    const documentNameSplit = name.split(".");
    const type = documentNameSplit[documentNameSplit.length-1];

    return type;
};

export default getFileExtensionFromName;
