import { MagnifyingGlass } from "phosphor-react";
import FormTextInput from "../../FormTextInput";
import Typography from "../../Typography";

type ConversationListSearchProps = {
    searchTerm: string
    setSearchTerm: (searchTerm:string) => void
};

const ConversationListSearch = ({
    searchTerm,
    setSearchTerm
}: ConversationListSearchProps) => {
    return (
        <div className="px-6 py-6 border-surface border-b-[1px]">
            <Typography className="font-bold " variant="title-small" component="span">Chat</Typography>
            <FormTextInput
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                placeholder="Search here"
                StartAdornmentIcon={MagnifyingGlass}
                className="mt-2"
            />
        </div>
    );
};

export default ConversationListSearch;