import { useState } from "react";
import { useArchivedChat } from "../../api/archivedChat";
import ArchiveConversationList from "../../components/Conversations/Archive/ArchiveConversationList";
import ArchiveMessagesContainer from "../../components/Conversations/Archive/ArchiveMessagesContainer";
import PageContent from "../../components/PageContent";
import { useIsMediumScreen } from "../../hooks/screenSizes";

const InboxArchivePage = () => {
    const isMediumScreen = useIsMediumScreen();
    const { data: archivedChats, isLoading } = useArchivedChat();
    const [selectedArchivedChatUserId, setSelectedArchivedChatUserId] = useState<string | undefined>(undefined);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    const selectedArchivedChat = selectedArchivedChatUserId 
        ? archivedChats?.find(chat => chat.user.id === selectedArchivedChatUserId)
        : undefined;

    const hasAnyArchivedChats = archivedChats !== undefined && archivedChats.length > 0;

    const handleArchivedChatSelected = (userId: string) => {
        setSelectedArchivedChatUserId(userId);
    };

    const handleBackClicked = () => {
        setSelectedArchivedChatUserId(undefined);
    };

    return (
        <PageContent>
            {isMediumScreen ? (
                <div className="flex flex-row overflow-hidden">
                    <ArchiveConversationList
                        archivedChats={archivedChats || []}
                        onArchivedChatSelected={handleArchivedChatSelected}
                    />
                    <ArchiveMessagesContainer 
                        selectedArchivedChat={selectedArchivedChat} 
                        hasAnyArchivedChats={hasAnyArchivedChats}
                    />
                </div>
            ) : (
                <>
                    {selectedArchivedChatUserId ? (
                        <ArchiveMessagesContainer 
                            selectedArchivedChat={selectedArchivedChat}
                            handleBackClick={handleBackClicked}
                            hasAnyArchivedChats={hasAnyArchivedChats}
                        />
                    ) : (
                        <ArchiveConversationList
                            archivedChats={archivedChats || []}
                            onArchivedChatSelected={handleArchivedChatSelected}
                        />
                    )}
                </>
            )}
        </PageContent>
    );
};

export default InboxArchivePage;