import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonProps } from "./Button";

export type LinkButtonProps = Pick<ButtonProps, "variant" | "disabled"> & {
    to: string
    children: React.ReactNode
    className?: string
    scrollToTop?: boolean
    target?: string
}

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(({
    to,
    children,
    className,
    scrollToTop,
    target,
    ...buttonProps
}, ref) => {
    const handleScrollToTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    return (
        <Link
            to={to}
            ref={ref}
            target={target}
            className={clsx(
                className,
                "outline-none block",
            )}>
            <Button
                {...buttonProps}
                className="w-full"
                type="button"
                onClick={scrollToTop ? handleScrollToTop : undefined}
            >
                {children}
            </Button>
        </Link>
    );
});

export default LinkButton;