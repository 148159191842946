import { SortOrder } from "../../api/common/sortOrder";
import { GigOrderByFieldKeys } from "../../models/app/gig";
import FindAGigContextProvider from "../FindAGig/FindAGigContextProvider";
import MyGigsList from "./MyGigsList";

const MyGigsPage = () => {

    const initialOrderBy: GigOrderByFieldKeys = "CreatedAt";
    const initialSortOrder: SortOrder = "desc";

    return (
        <FindAGigContextProvider
            initialOrderBy={initialOrderBy}
            initialSortOrder={initialSortOrder}
            allClients={false}
        >
            <MyGigsList />
        </FindAGigContextProvider>
    );
};

export default MyGigsPage;
