import { CalendarBlank, Trophy } from "phosphor-react";
import Typography from "../../components/Typography";
import { TalentCertificationDto } from "../../models/app/talentCertification";
import DataPoint from "./DataPoint";

export type CertificationProps = {
    certification: TalentCertificationDto
}

const Certification = ({
    certification: {
        id,
        title,
        achievementDate,
        fileUrl,
    }
}: CertificationProps) => {
    const TrophyComponent = fileUrl ? "a" : "span";

    return (
        <div key={id} className="flex items-start">
            <TrophyComponent
                download={!!fileUrl}
                href={fileUrl}
                className="block px-6 py-2 rounded-lg bg-surface-inset mr-4"
            >
                <Trophy weight="duotone" size={44} />
            </TrophyComponent>
            <div>
                <Typography variant="title-small" component="label">{title}</Typography>
                <DataPoint Icon={CalendarBlank}>
                    {achievementDate.toLocaleDateString()}
                </DataPoint>
            </div>
        </div>
    );
};

export default Certification;
