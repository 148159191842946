import { Trash, PencilSimple } from "phosphor-react";
import { useState } from "react";
import Card from "../../components/Card";
import Typography from "../../components/Typography";
import { Trophy } from "phosphor-react";

export type HistoryListItemProps = {
    Icon?: typeof Trophy
    title: string
    startDate: Date
    endDate?: Date | "Current"
    onRemove: () => Promise<unknown>
    onEdit: () => void
}

const HistoryListItem = ({
    Icon,
    title,
    startDate,
    endDate,
    onRemove,
    onEdit,
}: HistoryListItemProps) => {
    const [isRemoving, setIsRemoving] = useState(false);

    const endDateString = !endDate ? "" :
        typeof endDate === "string" ?
            ` - ${endDate}` :
            ` - ${endDate.toLocaleDateString()}`;

    const dates = startDate.toLocaleDateString() + endDateString;

    const handleDelete = async () => {
        setIsRemoving(true);

        try {
            await onRemove();
        }
        finally {
            setIsRemoving(false);
        }
    };

    return (
        <Card className="space-y-4 sm:flex sm:space-y-0 sm:space-x-6 sm:items-center sm:justify-between sm:text-left bg-surface-child-card">
            {Icon && (
                <Icon size={44} weight="duotone" className="hidden sm:block" />
            )}
            <div className="grow">
                <Typography variant="title-medium" component="h3" gutterBottom>{title}</Typography>
                <Typography variant="body" component="p">{dates}</Typography>
            </div>
            <div className="flex justify-end space-x-6">
                <button onClick={(handleDelete)} disabled={isRemoving}>
                    <Trash size={24} weight="fill" className={isRemoving ? "text-disabled-text" : "text-primary-text"} />
                </button>
                <button onClick={onEdit} disabled={isRemoving}>
                    <PencilSimple size={24} weight="fill" className={isRemoving ? "text-disabled-text" : "text-primary-text"} />
                </button>
            </div>
        </Card>
    );
};

export default HistoryListItem;
