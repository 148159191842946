import { SortOrder } from "../../api/common/sortOrder";
import FormDropdown from "../../components/FormDropdown";
import { useFindTalentContext } from "./findTalentContext";
import { TalentOrderByFieldKeys, talentOrderByOptions } from "../../models/app/talent";

const SortOrderDropdown = () => {
    const { sortOrder, orderBy, setSortOrder, setOrderBy } = useFindTalentContext();

    const handleChange = (value: typeof talentOrderByOptions[0]["value"]) => {
        const components = value.split(":");
        setOrderBy(components[0] as TalentOrderByFieldKeys);
        setSortOrder(components[1] as SortOrder);
    };

    return (
        <div className="mb-4 flex justify-center md:justify-end">
            <FormDropdown
                options={talentOrderByOptions}
                value={`${orderBy}:${sortOrder}`}
                onChange={handleChange}
                className="min-w-[286px]"
            />
        </div>
    );
};

export default SortOrderDropdown;