import { useState } from "react";
import { useClientBrochures } from "../../api/clientBrochures";
import ErrorList from "../../components/ErrorList";
import FileManager from "../../components/FileManager";
import { errorToast, successToast } from "../../toast";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";

const Brochures = () => {
    const [errors, setErrors] = useState<string[]>([]);

    const {
        brochures,
        isLoading,
        uploadBrochure,
        isUploadingBrochure,
        removeBrochure,
        isRemovingBrochure,
    } = useClientBrochures();

    const handleFileDrop = async (file: File) => {
        setErrors([]);

        const dataUrl = await convertFileToDataUrl(file);

        const response = await uploadBrochure({ fileDataUri: dataUrl });

        if (response.success) {
            successToast("Brochure updated successfully.");
        } else {
            setErrors(response.error.userMessages);
        }
    };

    const handleFileDelete = async (fileId: string) => {
        setErrors([]);

        const brochure = brochures.find(brochure => brochure.file.id === fileId);

        if (!brochure) {
            errorToast("Invalid brochure.");
            return;
        }

        const response = await removeBrochure(brochure.id);

        if (response.success) {
            successToast("Brochure removed successfully.");
        } else {
            setErrors(response.error.userMessages);
        }
    };

    if (isLoading || isUploadingBrochure || isRemovingBrochure) return <p>Loading...</p>;

    return (
        <div>
            <FileManager
                label="Upload brochures"
                helpText="Upload any brochures that you would like to display on your profile."
                inputId="brochures-upload-button"
                existingFiles={brochures.map(brochure => brochure.file)}
                onFileDrop={handleFileDrop}
                onFileDelete={handleFileDelete}
                maxFileSizeKb={5000}
            />
            <ErrorList errors={errors} />
        </div>
    );
};

export default Brochures;
