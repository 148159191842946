import clsx from "clsx";

export type CardProps = {
    children: React.ReactNode
    className?: string
}

export const cardClasses = "rounded-lg bg-surface p-6";

const Card = ({
    children,
    className,
}: CardProps) => {
    return (
        <div className={clsx(cardClasses, className)}>
            {children}
        </div>
    );
};

export default Card;
