import clsx from "clsx";
import { Briefcase, Eye } from "phosphor-react";
import { Link } from "react-router-dom";
import { useClientDashboard } from "../../api/clientDashboard";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import LargeDashboardButton from "../../components/Dashboard/LargeDashboardButton";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import Loader from "../../components/Loader";
import { useUserGetMe } from "../../api/userGetMe";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import Button from "../../components/Button";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import { deliveryTypeOptions } from "../../models/app/gig";
import { formatLocationDateTimeToString } from "../../utils/dateFormatters";

const ClientDashboardPage = () => {
    const appPaths = useAppPaths();
    const { dashboardData, isLoading } = useClientDashboard();
    const { gigTerminology, gigTerminologyPlural, organizationConfig } = useOrganizationContext();
    const { giggedClientIsVerified, loading } = useUserGetMe();
    const accountVerificationDialog = useDialogBoxState();
    const isOtm = organizationConfig.name === "Gigged.AI";
    const isBadgesEnabled = organizationConfig.isBadgesEnabled;

    if (isLoading || loading || !dashboardData) {
        return (
            <Loader />
        );
    }

    return (
        <>
            <PageContent>
                <Card className="space-y-6">
                    <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                        <LargeDashboardButton
                            variant="dark"
                            Icon={Briefcase}
                            name={`Post a ${gigTerminology.toLowerCase()}`}
                            href={!isOtm || !isBadgesEnabled || giggedClientIsVerified ? appPaths.gigs.createIntro : "#"}
                            handleClick={isOtm && isBadgesEnabled && !giggedClientIsVerified ? accountVerificationDialog.open : undefined}
                        />
                    </div>
                    <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                        <SmallDashboardButton
                            Icon={Briefcase}
                            name={`Posted ${gigTerminologyPlural}`}
                            count={dashboardData.postedGigsCount}
                            href={appPaths.gigs.myGigs("posted")}
                        />
                        <SmallDashboardButton
                            Icon={Briefcase}
                            name={`Booked ${gigTerminologyPlural}`}
                            count={dashboardData.ongoingGigsCount}
                            href={appPaths.gigs.myGigs("hired")}
                        />
                        <SmallDashboardButton
                            Icon={Briefcase}
                            name={`Completed ${gigTerminologyPlural}`}
                            count={dashboardData.completedGigsCount}
                            href={appPaths.gigs.myGigs("completed")}
                        />
                        <SmallDashboardButton
                            Icon={Briefcase}
                            name={`Draft ${gigTerminologyPlural}`}
                            count={dashboardData.draftGigsCount}
                            href={appPaths.gigs.myGigs("draft")}
                        />
                    </div>
                    <Card className={clsx("!bg-white", dashboardData.ongoingGigs.length === 0 && "flex flex-col min-h-[360px]")}>
                        <Typography variant="headline-small" component="h2" className="mb-6 flex-none">Booked {gigTerminologyPlural}</Typography>
                        {dashboardData.ongoingGigs.length === 0 ? (
                            <div className="flex-1 flex justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <img
                                        src="/gigs-empty-state-icon.svg"
                                        alt="No ongoing gigs icon"
                                    />
                                    <p className="mt-2 opacity-60">No ongoing gigs</p>
                                </div>
                            </div>
                        ) : (
                            <DashboardTable
                                data={dashboardData?.ongoingGigs}
                                headings={[
                                    "Client name",
                                    "Product",
                                    "Start date",
                                    "Expected duration",
                                    "Delivery type",
                                    "Location",
                                    "",
                                ]}
                                renderRowCells={(gig) => (
                                    <>
                                        <td>{gig.title}</td>
                                        <td>{gig.skills[0].name}</td>
                                        <td>{formatLocationDateTimeToString(gig.startDate, gig.ianaId)}</td>
                                        <td>{gig.expectedDurationLabel}</td>
                                        <td>{deliveryTypeOptions[gig.deliveryTypeId].label}</td>
                                        <td>{`${gig.location?.locality.locality}${gig.location?.locality.region ? ` (${gig.location?.locality.region})` : ""}, ${gig.location?.country}`}</td>
                                        <td>
                                            <Link to={appPaths.gigs.details(gig.id)}>
                                                <Eye size={20} />
                                            </Link>
                                        </td>
                                    </>
                                )}
                            />
                        )}
                    </Card>
                </Card>
            </PageContent>
            <DialogBox
                {...accountVerificationDialog}
                title="Account not verified"
                maxWidth={dialogWidths.medium}
            >
                <div className="flex flex-col gap-4">
                    <p>To help us maintain the quality and security of our platform, all new accounts go through a verification process.</p>
                    <p>We aim to complete this process within 24 hours, but occasionally it can take a little longer.</p>
                    <p>If you have an urgent {gigTerminology.toLowerCase()} requirement and your account is still unverified, please contact our support team and we'll be happy to help.</p>
                    <Button type="button" variant="primary" className="mt-2"><a href="mailto:customersuccess@gigged.ai">Contact support</a></Button>
                </div>
            </DialogBox>
        </>
    );
};

export default ClientDashboardPage;