import { ApiDisputeEditableFields, ApiDisputeSummaryDto } from "../models/api/dispute";
import { DisputeEditableFields, DisputeSummaryDto } from "../models/app/dispute";
import { mapFromApiDispute } from "../models/mappers/dispute";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useCreateDispute = () => useAuthenticatedGigApiFetcher<ApiDisputeEditableFields, string, DisputeEditableFields>("POST",
    (dto) => ({
        url: "api/disputes",
        body: dto
    })
);


export type UseDisputesReturn = {
    disputes: DisputeSummaryDto[]
    isLoading: boolean
    createDispute: (dto: DisputeEditableFields) => Promise<GigApiFetcherResponse<string>>
    isCreatingDispute: boolean
};

export const useDisputes = (): UseDisputesReturn => {
    const url = "api/me/disputes";

    const [createDispute, isCreatingDispute] = useCreateDispute();

    const apiCall = useGiggedApiSWR<ApiDisputeSummaryDto[]>(url);

    return {
        disputes: (apiCall.data || []).map(mapFromApiDispute),
        isLoading: apiCall.isLoading,
        createDispute: async (dto) => {
            const response = await createDispute(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        isCreatingDispute
    };
};