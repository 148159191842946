import { NavLink, useNavigate } from "react-router-dom";

import { gigStatusesReverseMap } from "../../models/app/gig";
import { useAppPaths } from "../../Routes";

export type MyGigsNavTabsProps = {
    currentGigStatus: number;
};

const MyGigsNavTabs = ({ 
    currentGigStatus 
}: MyGigsNavTabsProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();

    const tabs = [
        { label: "Draft", value: 0 },
        { label: "Posted", value: 1 },
        { label: "Pending", value: 5 },
        { label: "Booked", value: 2 },
        { label: "Completed", value: 4 },
        { label: "Cancelled", value: 3 },
    ];

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md outline-none border-primary shadow p-4"
                    onChange={(e) => {
                        const selectedTab = tabs.find((tab) => tab.value === Number(e.target.value));
                        if (selectedTab) {
                            const gigStatusKey = gigStatusesReverseMap[selectedTab.value];
                            navigate(
                                appPaths.gigs.myGigs(gigStatusKey) + window.location.search,
                                { replace: true }
                            );
                        }
                    }}
                    value={currentGigStatus}
                >
                    {tabs.map((tab) => (
                        <option key={tab.label} value={tab.value}>
                            {tab.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav
                    aria-label="Tabs"
                    className="isolate flex divide-x divide-surface rounded-lg shadow"
                >
                    {tabs.map((tab, tabIdx) => (
                        <NavLink
                            key={tab.label}
                            to={
                                appPaths.gigs.myGigs(gigStatusesReverseMap[tab.value]) +
                                window.location.search
                            }
                            className={({ isActive }) =>
                                classNames(
                                    isActive
                                        ? "text-primary-text"
                                        : "text-gray-500 hover:text-gray-700",
                                    tabIdx === 0 ? "rounded-l-lg" : "",
                                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                                    "group relative min-w-0 flex-1 overflow-hidden bg-white p-4 text-center text-sm font-medium focus:z-10"
                                )
                            }
                        >
                            <span>{tab.label}</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    currentGigStatus === tab.value ? "bg-primary" : "bg-transparent",
                                    "absolute inset-x-0 bottom-0 h-0.5"
                                )}
                            />
                        </NavLink>
                    ))}
                </nav>
            </div>
        </div>
    );
};

export default MyGigsNavTabs;