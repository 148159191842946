import { useEffect } from "react";

export type WithTitleProps = {
    pageTitle: string
    C: () => JSX.Element | null
}

const WithTitle = ({
    pageTitle,
    C
}: WithTitleProps) => {

    useEffect(() => {
        document.title = pageTitle ?? "Gigged.AI";

    }, [pageTitle]);

    return <C />;
};

export default WithTitle;