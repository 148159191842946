import React, { useState } from "react";
import { useGigs } from "../../api/gigs";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import FindAGigContext from "./findAGigContext";
import { GigOrderByFieldKeys } from "../../models/app/gig";
import { SortOrder } from "../../api/common/sortOrder";

export type FindAGigContextProviderProps = {
    children: React.ReactNode
    gigStatusIds?: number[]
    initialOrderBy?: GigOrderByFieldKeys
    initialSortOrder?: SortOrder
    allClients?: boolean
}

const FindAGigContextProvider = ({
    children,
    gigStatusIds: initialGigStatusIds,
    initialOrderBy,
    initialSortOrder,
    allClients: initialAllClients
}: FindAGigContextProviderProps) => {
    const [gigStatusIds, setGigStatusIds] = useState<number[]>(initialGigStatusIds ?? [0]);
    const [allClients, setAllClients] = useState<boolean>(initialAllClients ?? true);

    const {
        gigs,
        isLoading,
        setSearchTerm: setUseGigsSearchTerm,
        setReferenceNumber: setUseGigsReferenceNumber,
        setGigTitle: setUseGigsGigTitle,
        setSkillIds: setUseGigsSkillIds,
        setExpectedDurationIds: setUseGigsExpectedDurationIds,
        setHasClientHistory: setUseHasClientHistory,
        setLocationIds: setUseGigsLocationIds,
        setLocalityIds: setUseGigsLocalityIds,
        setDeliveryTypeIds: setUseGigsDeliveryTypeIds,
        setCreatedBy: setUseGigsCreatedBy,
        setInviteCreatedBy: setUseGigsInviteCreatedBy,
        setTalentName: setUseGigsTalentName,
        setStartDate: setUseGigsStartDate,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        expectedDurationIds: expectedDurationIdsQueryParam,
        referenceNumber: referenceNumberQueryParam,
        gigTitle: gigTitleQueryParam,
        hasClientHistory: hasClientHistoryQueryParam,
        locationIds: locationIdsQueryParam,
        localityIds: localityIdsQueryParam,
        deliveryTypeIds: deliveryTypeIdsQueryParam,
        createdBy: createdByQueryParam,
        inviteCreatedBy: inviteCreatedByQueryParam,
        talentName: talentNameQueryParam,
        startDate: startDateQueryParam,
        setSortOrder,
        setOrderBy,
        refetchGigs,
    } = useGigs({
        gigStatusIds,
        allClients,
        initialOrderBy: initialOrderBy ?? "Title",
        initialSortOrder: initialSortOrder ?? "asc",
    });

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);
    const [expectedDurationIds, setExpectedDurationIds] = useState<number[]>(expectedDurationIdsQueryParam);
    const [hasClientHistory, setHasClientHistory] = useState<string>(hasClientHistoryQueryParam);
    const [referenceNumber, setReferenceNumber] = useState<string>(referenceNumberQueryParam);
    const [gigTitle, setGigTitle] = useState<string>(gigTitleQueryParam);
    const [locationIds, setLocationIds] = useState<string[]>(locationIdsQueryParam);
    const [localityIds, setLocalityIds] = useState<string[]>(localityIdsQueryParam);
    const [deliveryTypeIds, setDeliveryTypeIds] = useState<number[]>(deliveryTypeIdsQueryParam);
    const [createdBy, setCreatedBy] = useState<string>(createdByQueryParam);
    const [inviteCreatedBy, setInviteCreatedBy] = useState<string>(inviteCreatedByQueryParam);
    const [talentName, setTalentName] = useState<string>(talentNameQueryParam);
    const [startDate, setStartDate] = useState<string>(startDateQueryParam);

    const anyActiveFilters =
    searchTerm.length > 0 ||
    referenceNumber.length > 0 ||
    gigTitle.length > 0 ||
    skillIds.length > 0 ||
    expectedDurationIds.length > 0 ||
    hasClientHistory.length > 0 ||
    locationIds.length > 0 ||
    localityIds.length > 0 ||
    deliveryTypeIds.length > 0 ||
    createdBy.length > 0 ||
    inviteCreatedBy.length > 0 ||
    talentName.length > 0 ||
    startDate.length > 0;

    const clearAllFilters = () => {
        setSearchTerm("");
        setReferenceNumber("");
        setGigTitle("");
        setSkillIds([]);
        setExpectedDurationIds([]);
        setHasClientHistory("");
        setLocationIds([]);
        setLocalityIds([]);
        setDeliveryTypeIds([]);
        setCreatedBy("");
        setInviteCreatedBy("");
        setTalentName("");
        setStartDate("");
        setUseHasClientHistory("");
        setUseGigsSearchTerm("");
        setUseGigsSkillIds([]);
        setUseGigsExpectedDurationIds([]);
        setUseGigsLocationIds([]);
        setUseGigsLocalityIds([]);
        setUseGigsDeliveryTypeIds([]);
        setUseGigsReferenceNumber("");
        setUseGigsGigTitle("");
        setUseGigsCreatedBy("");
        setUseGigsInviteCreatedBy("");
        setUseGigsTalentName("");
        setUseGigsStartDate("");
        silentlyReplaceSearchParams("");
    };

    const searchNow = () => {
        setUseGigsSearchTerm(searchTerm);
        setUseGigsSkillIds(skillIds);
        setUseGigsExpectedDurationIds(expectedDurationIds);
        setUseHasClientHistory(hasClientHistory);
        setUseGigsLocationIds(locationIds);
        setUseGigsLocalityIds(localityIds);
        setUseGigsDeliveryTypeIds(deliveryTypeIds);
        setUseGigsReferenceNumber(referenceNumber);
        setUseGigsGigTitle(gigTitle);
        setUseGigsCreatedBy(createdBy);
        setUseGigsInviteCreatedBy(inviteCreatedBy);
        setUseGigsTalentName(talentName);
        setUseGigsStartDate(startDate);
    };

    return (
        <FindAGigContext.Provider value={{
            gigs,
            isLoading,
            searchTerm,
            referenceNumber,
            gigTitle,
            hasClientHistory,
            skillIds,
            gigStatusIds,
            expectedDurationIds,
            locationIds,
            localityIds,
            deliveryTypeIds,
            createdBy,
            inviteCreatedBy,
            talentName,
            startDate,
            sortOrder,
            orderBy,
            anyActiveFilters,
            allClients,
            setSearchTerm,
            setReferenceNumber,
            setGigTitle,
            setSkillIds,
            setGigStatusIds,
            setExpectedDurationIds,
            setHasClientHistory,
            setLocationIds,
            setLocalityIds,
            setDeliveryTypeIds,
            setCreatedBy,
            setInviteCreatedBy,
            setTalentName,
            setStartDate,
            searchNow,
            setSortOrder,
            setOrderBy,
            clearAllFilters,
            refetchGigs,
            setAllClients,
        }}>
            {children}
        </FindAGigContext.Provider>
    );
};

export default FindAGigContextProvider;
