import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import _ from "lodash";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import { ReduxMessage } from "../../../store/reducers/messageListReducer";
import { truncateMiddle, calculateUnreadMessagesWidth, getLastMessageTime } from "../../../utils/conversationsHelpers";
import { getParticipantName } from "../../../api/conversations";
import useCurrentUser from "../../../auth/useCurrentUser";
import clsx from "clsx";

TimeAgo.addDefaultLocale(en);

export type ConversationListItemProps = {
    conversation: ReduxConversation
    messages: ReduxMessage[]
    unreadMessagesCount: number
    currentConversationSid: string
    onClick: () => void
}

const ConversationListItem = ({
    conversation,
    messages,
    unreadMessagesCount,
    currentConversationSid,
    onClick,
}: ConversationListItemProps) => {
    const title = truncateMiddle(
        conversation.friendlyName ?? conversation.sid,
        calculateUnreadMessagesWidth(unreadMessagesCount)
    );

    const time = getLastMessageTime(messages);
    const lastMessage = _.last(messages);
    const { userRole } = useCurrentUser();
    const currentUserRole = userRole || "";
    const participantName = getParticipantName(conversation, currentUserRole);

    return (
        <div
            onClick={onClick}
            className={clsx(
                "flex flex-row justify-between w-full p-6 items-center bg-white border-surface border-b-[1px] min-h-[102px] cursor-pointer space-x-6",
                currentConversationSid === conversation.sid && "!bg-surface"
            )}
        >
            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {title}
                <p className="truncate mt-2 text-secondary-text">{lastMessage?.body}</p>
                {participantName}
            </div>
            <div className="flex flex-col items-end whitespace-nowrap">
                <p className="text-secondary-text">{time}</p>
                {unreadMessagesCount > 0 && (
                    <span className="bg-primary rounded-full text-white text-[1rem] w-[25px] h-[25px] text-center mt-[0.25rem]">{unreadMessagesCount}</span>
                )}
            </div>
        </div>
    );
};

export default ConversationListItem;