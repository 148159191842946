import { ProductFruits } from "react-product-fruits";
import { useClientPersonalInformationContext } from "../api/clientPersonalInformation";
import useCurrentUser from "../auth/useCurrentUser";
import config from "../config.json";

export type ProductTourContainerProps = {
    children: JSX.Element
}

const ProductTourContainer = ({
    children
}: ProductTourContainerProps) => {
    const { clientPersonalInformation } = useClientPersonalInformationContext();

    const windowIsExtraLarge = window.innerWidth >= 1440 ? "true" : "false";

    const user = useCurrentUser();

    const currentUserInfo = {
        username: user.email,
        firstname: user.userGivenName,
        lastname: user.userFamilyName,
        role: user.userRole,
        props: {
            isProfileComplete: clientPersonalInformation?.isProfileComplete ? "true" : "false",
            isExtraLargeScreen: windowIsExtraLarge
        }
    };

    return (
        <>
            {children}
            <ProductFruits
                workspaceCode={config.ProductFruitsWorkspaceCode}
                language="en"
                user={currentUserInfo}
                lifeCycle="unmount"
            />
        </>
    );
};

export default ProductTourContainer;
