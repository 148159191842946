import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const createReportFormValidationSchema = yup.object({
    reason: yup
        .string()
        .max(100, "Reason cannot be longer than 100 characters.")
        .required("Reason is required."),
    description: yup
        .string()
        .max(1000, "Description cannot be longer than 1000 characters.")
        .required("Description is required.")
});

export type CreateReportFormValues = {
    reason: string
    description: string
}

export const useCreateReportForm = (defaultValues?: CreateReportFormValues) => {
    return useForm<CreateReportFormValues>({
        resolver: yupResolver(createReportFormValidationSchema),
        defaultValues: {
            reason: defaultValues?.reason || "",
            description: defaultValues?.description || ""
        }
    });
};