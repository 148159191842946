import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type InviteTalentFormDialogValues = {
    message: string
};

export const inviteToGigFormValidationSchemaFields = {
    message: yup.string().required("Message is required."),
};

const schema = yup.object(inviteToGigFormValidationSchemaFields);

export const useInviteToGigForm = () => {
    return useForm<InviteTalentFormDialogValues>({
        resolver: yupResolver(schema)
    });
};