import { X } from "phosphor-react";
import config from "../../configuration";
import { getSizeDescriptionFromBytes } from "../../utils/getSizeDescriptionFromBytes";
import urlHasAllowedExtension from "../../utils/urlHasAllowedExtension";
import Button from "../Button";
import DialogBox, { useDialogBoxState } from "../DialogBox";

export type ExistingFileProps = {
    id: string
    url: string
    name: string
    totalBytes: number
    onFileDelete: (fileId: string) => void
}

export const ExistingFile = ({
    id,
    url,
    name,
    totalBytes,
    onFileDelete
}: ExistingFileProps) => {
    const dialogState = useDialogBoxState();

    const handleFileDeleteConfirmed = () => {
        onFileDelete(id);
        dialogState.close();
    };

    const isImage = urlHasAllowedExtension(url, config.allowedImageFileExtensions);
    
    return (
        <div className="flex flex-col items-stretch justify-between border border-surface rounded-lg w-full md:w-1/5 sm:w-2/5 bg-white">
            <a 
                href={url}
                target="_blank"
                className="relative h-full bg-black rounded-t-lg"
            >
                <img 
                    src={isImage ? url : "https://via.placeholder.com/300?text=File"} 
                    className="rounded-t-lg object-scale-down max-h-36 min-w-full brightness-90 hover:brightness-100" 
                />                
            </a>
            <div className="flex flex-row px-2 bg-white rounded-b-lg w-full">
                <div className="flex flex-col truncate w-3/4 py-[0.25rem]">
                    <span className="font-bold text-label-small">{name || "File"}</span>
                    <span className="text-label-small">File size: {getSizeDescriptionFromBytes(totalBytes)}</span>
                </div>
                <button 
                    type="button"
                    className="py-1 cursor-pointer rounded-full flex justify-end w-1/4"  
                    onClick={dialogState.open}                  
                >
                    <X
                        weight="bold"
                        className="w-4 h-full"
                        aria-hidden="true"
                    />
                </button>
            </div>

            <DialogBox
                {...dialogState}
                title="Delete file?"
            >
                <div className="flex flex-col-reverse md:flex-row gap-4">
                    <Button className="mx-auto w-full" type="button" variant="secondary" onClick={dialogState.close}>Back</Button>
                    <Button className="mx-auto w-full" type="button" variant="primary" onClick={handleFileDeleteConfirmed}>Delete file</Button>                    
                </div>
            
            </DialogBox>
        </div>
    );
};

export default ExistingFile;
