import { Controller } from "react-hook-form";
import { useInviteToGig } from "../../api/gig";
import { useGigs } from "../../api/gigs";
import { gigStatuses } from "../../models/app/gig";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import { InviteTalentFormDialogValues, useInviteToGigForm } from "./InviteToGigFormDialogValues";
import { useTalent } from "../../api/talent";
import { successToast } from "../../toast";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import pluralizeString from "../../utils/pluralizeString";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";
import { useState } from "react";
import { dialogWidths } from "../../components/DialogBox/DialogBox";

export type InviteToGigButtonProps = {
    talentId: string
    className?: string
}
const InviteToGigButton = ({
    talentId,
    className,
}: InviteToGigButtonProps) => {
    const { talent } = useTalent(talentId);
    const [inviteToGig, isInvitingToGig] = useInviteToGig();
    const { 
        gigs: postedGigs,
        refetchGigs, 
        isLoading: isLoadingGigs, 
        isValidating: isValidatingGigs 
    } = useGigs({ gigStatusIds: [gigStatuses.posted] });
    const dialogState = useDialogBoxState();
    const { gigTerminology, talentTerminology } = useOrganizationContext();
    const methods = useInviteToGigForm(gigTerminology);

    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const gigOptions: FormDropdownOption<string>[] = postedGigs
        .filter(_ => !_.invitedTalentIds.includes(talentId))
        .map(gig => ({
            value: gig.id,
            label: gig.title + ` (Ref: ${gig.referenceNumber})`,
        }));

    const handleConfirmInvite = async (values: InviteTalentFormDialogValues) => {
        const response = await inviteToGig({
            gigId: methods.getValues().gigId as string,
            dto: {
                message: values.message,
                talentId,
            }
        });

        if (response.success) {
            successToast(`${talent?.fullName} has been invited.`);
            dialogState.close();
            methods.reset();
            refetchGigs();
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <>
            <Button onClick={dialogState.onOpen} className={className}>
                Invite to {gigTerminology.toLowerCase()}
            </Button>
            <form onSubmit={methods.handleSubmit(handleConfirmInvite)}>
                <DialogBox
                    {...dialogState}
                    title={`Invite to ${gigTerminology.toLowerCase()}`}
                    minWidth={dialogWidths.medium}
                    
                >
                    {gigOptions.length === 0 ? (
                        <p className="max-w-sm">{`You have no ${pluralizeString(gigTerminology.toLowerCase())} that ${talent?.fullName} can be invited to.`}</p>
                    ) : (
                        <>
                            <Controller
                                name="gigId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        disabled={isLoadingGigs || isValidatingGigs}
                                        pleaseSelectText={`Select a ${gigTerminology.toLowerCase()}`}
                                        options={gigOptions}
                                        error={methods.formState.errors.gigId}
                                        onChange={onChange}
                                        value={value || ""}
                                    />
                                )}
                            />
                            <FormTextAreaInput
                                id="invite-to-gig-message"
                                rows={7}
                                placeholder="You can use this message to provide the AC with any additional information about the event. You may also include reasons why they have been selected."
                                register={methods.register("message")}
                                error={methods.formState.errors.message}
                                disabled={methods.formState.isSubmitting}
                            />
                            <GeneralFormError error={submissionError} />
                            <div className="mt-4">
                                <Button className="w-full" type="submit" loading={isInvitingToGig || isLoadingGigs || isValidatingGigs}>Send invite</Button>
                            </div>
                        </>
                    )}
                </DialogBox>
            </form>
        </>
    );
};

export default InviteToGigButton;