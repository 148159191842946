import { useMsal } from "@azure/msal-react";
import { Briefcase, Envelope, Gauge, Gear, ShieldCheckered, UserCircle } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import { b2cPolicies } from "../../auth/authConfig";
import { useAuthContext } from "../../auth/authContext";
import { useAppPaths } from "../../Routes";
import getUnreadMessagesCount from "../../utils/getUnreadMessagesCount";
import { SideNavMenuItemConfig } from "./SideNavMenuItem";
import useCurrentUser from "../../auth/useCurrentUser";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

export const buildClientNavItems = (clientId?: string): SideNavMenuItemConfig[] => {
    const { instance } = useMsal();
    const unreadMessageCount = getUnreadMessagesCount();
    const appPaths = useAppPaths();
    const { gigTerminologyPlural, organizationConfig } = useOrganizationContext();
    const { loginRequest } = useAuthContext();
    const { claims } = useCurrentUser();
    const isResources = hasClaim(UserRole.Resources, claims);

    const handleChangeMyEmailClicked = () => {
        instance.loginRedirect({
            ...loginRequest,
            authority: b2cPolicies.authorities.changeEmail.authority
        });
    };

    const navItems: SideNavMenuItemConfig[] = [
        {
            name: "Dashboard",
            icon: <Gauge size={20} />,
            isCollection: false,
            href: appPaths.dashboard,
        },
    ];

    if (isResources) {
        navItems.push({
            name: "Inbox",
            adornment: unreadMessageCount < 1 ? undefined :
                <span
                    className="bg-primary rounded-full text-white text-[1rem] px-2 ml-auto text-center"
                >
                    {unreadMessageCount}
                </span>,
            icon: <Envelope size={20} />,
            isCollection: false,
            href: appPaths.inbox.index
        });
    }
    navItems.push(
        {
            name: "My Profile",
            icon: <UserCircle size={20} />,
            isCollection: false,
            href: clientId ?
                appPaths.clients.details(clientId) :
                window.location.pathname, // client is loaded async from api so just make link do nothing till it is loaded.
        },
        {
            name: "Settings",
            icon: <Gear size={20} />,
            isCollection: true,
            productFruitsId: "pf-sidenav-settings",
            subItems: [
                { name: "Edit my profile", href: appPaths.profile.edit },
                { name: "Change my email", href: "", onClick: handleChangeMyEmailClicked },
            ].concat(
                organizationConfig.isAllowGiggedClientUserToInviteEnabled
                    ? [{ name: "Manage users", href: appPaths.account.manageUsers }] : []
            )
        },
        {
            name: `My ${gigTerminologyPlural}`,
            icon: <Briefcase size={20} />,
            isCollection: false,
            href: appPaths.gigs.myGigs("draft")
        },
    );

    if (organizationConfig?.isDisputesEnabled) {
        navItems.push(
            {
                name: "Disputes",
                icon: <ShieldCheckered size={20} />,
                isCollection: false,
                href: appPaths.disputes.index
            });
    }

    return navItems;
};

export const buildTalentNavItems = (talentId?: string): SideNavMenuItemConfig[] => {
    const { instance } = useMsal();
    const appPaths = useAppPaths();
    const { gigTerminologyPlural, organizationConfig } = useOrganizationContext();
    const { loginRequest } = useAuthContext();
    const messageCount = getUnreadMessagesCount();
    const {
        isLoading: isLoadingTalentPersonalInformation
    } = useTalentPersonalInformationContext();

    if (isLoadingTalentPersonalInformation) {
        return [];
    }

    const handleChangeMyEmailClicked = () => {
        instance.loginRedirect({
            ...loginRequest,
            authority: b2cPolicies.authorities.changeEmail.authority
        });
    };

    const navItems: SideNavMenuItemConfig[] = [
        {
            name: "Dashboard",
            icon: <Gauge size={20} />,
            isCollection: false,
            href: appPaths.dashboard,
        },
        {
            name: "Inbox",
            icon: <Envelope size={20} />,
            adornment: messageCount < 1 ? undefined :
                <span
                    className="bg-primary rounded-full text-white text-[1rem] px-2 ml-auto text-center"
                >
                    {messageCount}
                </span>,
            isCollection: false,
            href: appPaths.inbox.index
        },
        {
            name: "My Profile",
            icon: <UserCircle size={20} />,
            isCollection: false,
            href: talentId ?
                appPaths.talents.findTalentDetails(talentId) :
                window.location.pathname, // talentId is loaded async from api so just make link do nothing till it is loaded.
        },
        {
            name: "Settings",
            icon: <Gear size={20} />,
            isCollection: true,
            subItems: [
                { name: "Edit my profile", href: appPaths.profile.edit },
                { name: "Change my email", href: "", onClick: handleChangeMyEmailClicked },
            ]
        },
        {
            name: `My ${gigTerminologyPlural}`,
            icon: <Briefcase size={20} />,
            isCollection: true,
            subItems: [
                { name: "Invites", href: appPaths.gigs.invites },
                { name: `Accepted ${gigTerminologyPlural}`, href: appPaths.gigs.ongoing },
                { name: `Completed ${gigTerminologyPlural}`, href: appPaths.gigs.completed },
                { name: `Cancelled ${gigTerminologyPlural}`, href: appPaths.gigs.cancelled },
            ]
        },
    ];

    if (organizationConfig?.isDisputesEnabled) {
        navItems.push(
            {
                name: "Disputes",
                icon: <ShieldCheckered size={20} />,
                isCollection: false,
                href: appPaths.disputes.index
            });
    }

    return navItems;
};