import AuditDataList from "../../components/AuditData/AuditDataList";
import Card from "../../components/Card";
import LinkButton from "../../components/LinkButton";
import Typography from "../../components/Typography";
import { GigSummary } from "../../models/app/gig";
import { useAppPaths } from "../../Routes";

export type DraftGigsCardProps = {
    gig: GigSummary
}
const DraftGigsCardProps = ({
    gig
}: DraftGigsCardProps) => {
    const appPaths = useAppPaths();

    return (
        <Card key={gig.id} className="flex items-center flex-col md:flex-row mb-4 break-words">
            <div key={gig.id} className="space-y-4 md:mr-8 text-center md:text-left">
                <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                <p className="italic line-clamp-3 md:mr-8">{gig.description}</p>
                <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} />
            </div>
            <LinkButton className="block md:ml-auto self" to={appPaths.gigs.edit(gig.id)} variant="tertiary">Edit</LinkButton>
        </Card>
    );
};

export default DraftGigsCardProps;