import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type CancelGigFormValues = {
    reason: string
};

export const cancelGigFormValidationSchemaFields = {
    reason: yup.string().required("Reason is required."),
};

const schema = yup.object(cancelGigFormValidationSchemaFields);

export const useCancelGigForm = (requireReason: boolean) => {
    return useForm<CancelGigFormValues>({
        resolver: requireReason ? yupResolver(schema) : undefined,
    });
};