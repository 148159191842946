import clsx from "clsx";
import { X } from "phosphor-react";

export type ClearFiltersButtonProps = {
    onClick: () => void
    disabled?: boolean
    className?: string
}

const ClearFiltersButton = ({
    onClick,
    disabled,
    className
}: ClearFiltersButtonProps) => {
    return (
        <div className={clsx(
            "p-4", className)}>
            <button
                className="flex mx-auto items-center disabled:text-disabled-text"
                onClick={onClick}
                disabled={disabled}
                type="button"
            >
                <X weight="bold" size="20" className="mr-2" />
                Clear filters
            </button>
        </div>
    );
};

export default ClearFiltersButton;
