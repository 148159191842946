import { ApiTalentCertificationCreateUpdateDto, ApiTalentCertificationDto } from "../api/talentCertification";
import { TalentCertificationCreateUpdateDto, TalentCertificationDto } from "../app/talentCertification";

export const mapFromApiTalentCertificationDto = (apiDto: ApiTalentCertificationDto): TalentCertificationDto => ({
    ...apiDto,
    achievementDate: new Date(apiDto.achievementDate),
});

export const mapToApiTalentCertificationCreateUpdateDto = (dto: TalentCertificationCreateUpdateDto): ApiTalentCertificationCreateUpdateDto => {
    return {
        ...dto,
        achievementDate: dto.achievementDate.toISOString(),
    };
};