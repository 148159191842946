import { useSelector } from "react-redux";

import { AppState } from "../../store";
import { ReduxConversation } from "../../store/reducers/conversationReducer";
import MessagesContainer from "./Messages/MessagesContainer";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import ConversationList from "./ConversationList/ConversationList";
import Loader from "../Loader";
import { Dispatch, SetStateAction } from "react";

export type InboxContainerProps = {
    openedConversation?: ReduxConversation
    handleConversationClicked: (conversation: ReduxConversation) => Promise<void>
    handleBackClick: () => void
    messageBoxInView: boolean
    fromQueryParam: boolean
    setFromQueryParam: Dispatch<SetStateAction<boolean>>
}

const InboxContainer = ({
    openedConversation,
    handleConversationClicked,
    handleBackClick,
    messageBoxInView,
    fromQueryParam,
    setFromQueryParam
}: InboxContainerProps) => {
    const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
    const isMediumScreen = useIsMediumScreen();

    if (loadingStatus) {
        return <Loader />;
    }

    return (
        <div data-private>
            {isMediumScreen ? (
                <div className="flex flex-row overflow-hidden h-[85vh]">
                    <ConversationList handleConversationClicked={handleConversationClicked} setFromQueryParam={setFromQueryParam} fromQueryParam={fromQueryParam} />
                    <MessagesContainer conversation={openedConversation} />
                </div>
            ) : (
                <>
                    {messageBoxInView ? (
                        <MessagesContainer
                            conversation={openedConversation}
                            handleBackClick={handleBackClick}
                        />
                    ) : (
                        <div className="h-[85vh] overflow-auto">
                            <ConversationList handleConversationClicked={handleConversationClicked} setFromQueryParam={setFromQueryParam} fromQueryParam={fromQueryParam} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default InboxContainer;