import { useEffect, useRef, useState } from "react";
import { Client, Conversation, Message } from "@twilio/conversations";
import { AppState, actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribedConversations, handleParticipantsUpdate, handlePromiseRejection, loadUnreadMessagesCount } from "../../utils/conversationsHelpers";
import useAccessToken from "../../auth/useAccessToken";
import { fetchConversationsToken, getConversationParticipants } from "../../api/conversations";
import { SetUnreadMessagesType, AddMessagesType } from "../../models/app/conversation";
import { initFirebaseServiceWorker, showNotification, subscribeFirebaseNotifications } from "../../utils/firebaseSupport";
import { useNavigate } from "react-router-dom";
import { inboxToast } from "../../toast";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useAppPaths } from "../../Routes";
import useCurrentUser from "../../auth/useCurrentUser";

export type ConversationManagerProps = {
    children: React.ReactNode;
}

const ConversationManager = ({ children }: ConversationManagerProps) => {
    const appPaths = useAppPaths();
    const accessToken = useAccessToken();
    const navigate = useNavigate();
    const currentUserDetails = useCurrentUser();
    const [messagesTokenAboutToExpire, setMessagesTokenAboutToExpire] = useState(false);
    const conversationsToken = useSelector((state: AppState) => state.token);
    const sid = useSelector((state: AppState) => state.sid);
    const sidRef = useRef("");
    sidRef.current = sid;
    
    const { organizationConfig } = useOrganizationContext();

    const dispatch = useDispatch();

    const {
        addMessages,
        addConversation,
        removeConversation,
        updateUnreadMessages,
        updateCurrentConversation,
        addNotifications,
        removeMessages,
        updateLoadingState,
        messagesLogin,
        updateParticipants,
    } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        if (!accessToken || currentUserDetails?.userRole === undefined) return;

        const loadTokenAndLogin = async () => {
            const messagesToken = await fetchConversationsToken( accessToken);
            messagesLogin(messagesToken);
        };

        loadTokenAndLogin();
    }, [accessToken]);

    useEffect(() => {
        if (!conversationsToken) return;

        const client = new Client(conversationsToken);

        const initFirebase = async () => {
            await initFirebaseServiceWorker();
            await subscribeFirebaseNotifications(client);
        };

        initFirebase().catch((error) => {
            console.error("FCM initialization failed: no push notifications will be available.", error);
        });

        client.on("conversationJoined", (conversation) => {
            addConversation(conversation);
            
            handlePromiseRejection(async () => {
                if (conversation.status === "joined") {
                    const result = await getConversationParticipants(conversation);
                    updateParticipants(result, conversation.sid);

                    const messages = await conversation.getMessages();
                    addMessages(conversation.sid, messages.items);
                    loadUnreadMessagesCount(conversation, updateUnreadMessages);
                }
            }, addNotifications);
        });

        client.on("conversationRemoved", (conversation: Conversation) => {
            updateCurrentConversation("");
            handlePromiseRejection(() => {
                removeConversation(conversation.sid);
                updateParticipants([], conversation.sid);
            }, addNotifications);
        });

        client.on("messageAdded", (message: Message) => {
            addMessage(message, addMessages, updateUnreadMessages);
        });

        client.on("participantLeft", (participant) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(participant, updateParticipants),
                addNotifications
            );
        });

        client.on("participantUpdated", (event) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(event.participant, updateParticipants),
                addNotifications
            );
        });

        client.on("participantJoined", (participant) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(participant, updateParticipants),
                addNotifications
            );
        });

        client.on("conversationUpdated", () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handlePromiseRejection(() => {}, addNotifications);
        });

        client.on("messageUpdated", () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handlePromiseRejection(() => {}, addNotifications);
        });

        client.on("messageRemoved", (message) => {
            handlePromiseRejection(
                () => removeMessages(message.conversation.sid, [message]),
                addNotifications
            );
        });

        client.on("tokenAboutToExpire", () => {
            setMessagesTokenAboutToExpire(true);
        });

        client.on("pushNotification", (event) => {
            if (event.type !== "twilio.conversations.new_message") {
                return;
            }

            if (Notification.permission === "granted") {
                showNotification(event, organizationConfig?.name || "Gigged.AI", () => {
                    navigate(appPaths.inbox.index);
                });
            }

            inboxToast(event.body || "Message received.", () => {
                navigate(appPaths.inbox.index);
            });
        });

        updateLoadingState(false);
        getSubscribedConversations(client, currentUserDetails);

        return () => {
            client?.removeAllListeners();
        };
    }, [conversationsToken]);

    function addMessage(
        message: Message,
        addMessages: AddMessagesType,
        updateUnreadMessages: SetUnreadMessagesType
    ) {
        //transform the message and add it to redux
        handlePromiseRejection(() => {
            if (sidRef.current === message.conversation.sid) {
                message.conversation.updateLastReadMessageIndex(message.index);
            }
            addMessages(message.conversation.sid, [message]);
            loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
        }, addNotifications);
    }

    useEffect(() => {
        if (!accessToken || !messagesTokenAboutToExpire) {
            return;
        }

        const loadTokenAndLogin = async () => {
            const messagesToken = await fetchConversationsToken(accessToken);
            messagesLogin(messagesToken);
            setMessagesTokenAboutToExpire(false);
        };

        loadTokenAndLogin();
    }, [messagesTokenAboutToExpire, accessToken]);

    return <>{children}</>;
};

export default ConversationManager;