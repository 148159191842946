import { File, Image } from "phosphor-react";
import { useEffect } from "react";
import { ReduxMedia } from "../../../store/reducers/messageListReducer";

type MessageMediaProps = {
    messageMedia: ReduxMedia[] | null
    onDownload: () => Promise<Error | undefined>
    onOpen: (mediaSid: string, file: ReduxMedia) => void
}

const MessageMedia: React.FC<MessageMediaProps> = ({
    messageMedia,
    onDownload,
    onOpen
}) => {    
    useEffect(() => {
        onDownload();
    }, []);

    return ( 
        <div>
            {
                messageMedia?.map(media => {
                    const isImage = media.contentType.includes("image");

                    return (
                        <div
                            className="flex cursor-pointer gap-2 items-center mb-2" 
                            onClick={() => onOpen(media.sid, media)}
                        >
                            {
                                isImage ? <Image size={30} />  : <File size={30} />
                            }
                            <p className="font-bold truncate">{media.filename}{media.size}</p>
                        </div>
                    );
                })
            }
        </div>
    );
};
 
export default MessageMedia;