import { Trophy } from "phosphor-react";
import { GigApiFetcherResponse } from "../../api/common/fetching";

import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { TalentCertificationDto } from "../../models/app/talentCertification";
import { CertificationFormDialog } from "./CertificationFormDialog";
import { CertificationFormValues } from "./CertificationFormValues";
import HistoryListItem from "./HistoryListItem";

export type CertificationCardProps = {
    certification: TalentCertificationDto
    onRemove: () => Promise<unknown>
    updateJob: (values: CertificationFormValues) => Promise<GigApiFetcherResponse<unknown>>
}
export const CertificationCard = ({
    certification,
    onRemove,
    updateJob,
}: CertificationCardProps) => {
    const dialogState = useDialogBoxState();

    return (
        <>
            <HistoryListItem
                Icon={Trophy}
                title={certification.title}
                startDate={certification.achievementDate}
                onRemove={onRemove}
                onEdit={dialogState.open}
            />
            <DialogBox {...dialogState} title="Your awards / certifications">
                <CertificationFormDialog
                    {...dialogState}
                    onSubmit={updateJob}
                    isEdit
                    initialValues={certification}
                    existingFileUrl={certification.fileUrl}
                />
            </DialogBox>
        </>
    );
};

export default CertificationCard;