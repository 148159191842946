import sanitizeHtml from "sanitize-html";

const defaultOptions: sanitizeHtml.IOptions = {
    allowedTags: [ "b", "i", "em", "strong", "a", "p" ],
    allowedAttributes: {
        "a": [ "href" ]
    },
};

const sanitize = (html: string) => ({
    __html: sanitizeHtml(html, defaultOptions)
});

export type SanitizeHtmlProps = {
    html: string
    className?: string
};

const SanitizeHtml = ({ html, className }: SanitizeHtmlProps) => {
    return (
        <div dangerouslySetInnerHTML={sanitize(html)} className={className}/>
    );
};

export default SanitizeHtml;