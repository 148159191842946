import clsx from "clsx";

export type TitleUnderlineProps = {
    className?: string
    fullWidth?: boolean
}

const TitleUnderline = ({
    className,
    fullWidth,
}: TitleUnderlineProps) => {
    return (
        <div className={clsx(
            "bg-primary my-4",
            fullWidth ? "h-[3px]" : "w-[100px] h-[5px]",
            className,
        )}/>
    );
};

export default TitleUnderline;
