import clsx from "clsx";
import React, { ReactElement } from "react";

export type ButtonVariant = "primary" | "secondary" | "tertiary";

export type ButtonProps = {
    type?: JSX.IntrinsicElements["button"]["type"]
    children: React.ReactNode
    disabled?: boolean
    onClick?: () => void
    className?: string
    variant?: ButtonVariant
    loading?: boolean
    value?: string
    icon?: ReactElement
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
    type,
    disabled,
    children,
    onClick,
    className,
    variant = "primary",
    loading = false,
    value,
    icon
}, ref) => {
    const _disabled = disabled || loading;
    return (
        <button
            value={value}
            className={clsx(
                "font-bold py-[0.75rem] px-[1.5rem] rounded-full disabled:text-disabled-text focus:outline whitespace-nowrap transition-colors ease-in-out duration-200",
                variant === "primary" && !_disabled && "text-primary-bg-text bg-primary hover:bg-primary-hover focus:outline-3",
                variant === "primary" && _disabled && "text-primary-bg-text bg-disabled-bg",
                variant === "secondary" && !_disabled && "text-primary-text border-2 border-primary-text hover:bg-btn-secondary-bg-hover focus:outline-1",
                variant === "secondary" && _disabled && "text-primary-text border-2 border-disabled",
                variant === "tertiary" && !_disabled && "text-primary-text hover:bg-btn-secondary-bg-hover focus:outline-3",
                className,
            )}
            type={type}
            disabled={_disabled || loading}
            onClick={onClick}
            ref={ref}
        >
            <div className="flex flex-row items-center justify-center">
                {icon && <span className="mr-2">{icon}</span>}
                {loading ? "Loading..." : children}
            </div>
        </button>
    );
});

export default Button;