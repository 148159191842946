import clsx from "clsx";
import { TalentBadgeDetails } from "../models/app/badge";
import Tooltip from "./Tooltip";

type BadgeProps = {
    className?: string
} & TalentBadgeDetails;

const Badge = ({ 
    className, 
    badgeImageFileName, 
    badgeName, 
    badgeDescription 
}: BadgeProps) => {
    return (
        <Tooltip tooltipText={`${badgeName} - ${badgeDescription}`}>
            <div className={clsx("w-10 h-10 rounded-full bg-surface", className)}>
                <img src={`/badges/${badgeImageFileName}`} alt={badgeName} className="w-full h-full object-cover" />
            </div>
        </Tooltip>
    );
};

export default Badge;