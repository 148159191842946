import { SortOrder } from "../../api/common/sortOrder";
import { GigOrderByFieldKeys, gigOrderByOptions } from "../../models/app/gig";
import FormDropdown from "../../components/FormDropdown";
import { useFindAGigContext } from "./findAGigContext";
import clsx from "clsx";

interface SortOrderDropdownProps {
    formClassName?: string;
}

const SortOrderDropdown = ({
    formClassName
}: SortOrderDropdownProps) => {
    const { sortOrder, orderBy, setSortOrder, setOrderBy } = useFindAGigContext();

    const handleChange = (value: typeof gigOrderByOptions[0]["value"]) => {
        const components = value.split(":");
        setOrderBy(components[0] as GigOrderByFieldKeys);
        setSortOrder(components[1] as SortOrder);
    };

    return (
        <div className="mb-4 flex justify-center md:justify-end !w-full">
            <FormDropdown
                options={gigOrderByOptions}
                value={`${orderBy}:${sortOrder}`}
                onChange={handleChange}
                className={clsx("min-w-[286px]", formClassName)}
            />
        </div>
    );
};

export default SortOrderDropdown;
