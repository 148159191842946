import { ApiArchivedChatDto } from "../../../models/api/chatArchive";
import ArchiveMessageListItem from "./ArchiveMessageListItem";

export type ArchiveMessageListProps = {
    archivedChat: ApiArchivedChatDto
}

const ArchiveMessageList = ({ archivedChat }: ArchiveMessageListProps) => {
    return (
        <div className="w-full">
            {archivedChat.archivedChatMessages.map((chatMessage, index) => (
                <div key={"message-" + index}>
                    <ArchiveMessageListItem
                        archivedChat={archivedChat}
                        chatMessage={chatMessage}
                    />
                </div>
            ))}
        </div>
    );
};

export default ArchiveMessageList;