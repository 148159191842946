import { ApiClientDashboardDto } from "../models/api/clientDashboard";
import { mapFromApiClientDashboard } from "../models/mappers/clientDashboard";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useClientDashboard = () => {
    const url = "api/me/gigged-client-dashboard";

    const apiCall = useGiggedApiSWR<ApiClientDashboardDto>(url);

    return {
        dashboardData: apiCall.data ? mapFromApiClientDashboard(apiCall.data) : undefined,
        isLoading: apiCall.isLoading,
    };
};