import { FunnelSimple } from "phosphor-react";
import React from "react";
import ClearFiltersButton from "./ClearFiltersButton";

export type FilterButtonProps = {
    anyActiveFilters: boolean
    clearAllFilters: () => void
    setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const FilterButton = ({
    anyActiveFilters,
    clearAllFilters,
    setIsFilterPanelOpen,
}: FilterButtonProps) => {
    const handleFilterClick = () => {
        setIsFilterPanelOpen(isOpen => !isOpen);
    };

    return (
        <div className="md:hidden mb-4 bg-surface border border-surface rounded-lg">
            <ClearFiltersButton disabled={!anyActiveFilters} onClick={clearAllFilters} className="m-auto border-b border-surface" />
            <button
                className="flex items-center justify-center w-full p-4 font-bold text-title-medium"
                onClick={handleFilterClick}
            >
                <FunnelSimple size="32" className="mr-2" />
                Filter
            </button>
        </div>
    );
};

export default FilterButton;
