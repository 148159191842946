import { useEffect, useState } from "react";
import { OrganizationConfigDto } from "../../models/api/organization";
import pluralizeString from "../../utils/pluralizeString";
import { getOrganizationConfigByCurrentDomain } from "../organizations";
import OrganizationContext from "./organizationContext";
import Loader from "../../components/Loader";

export type OrganizationProviderProps = {
    children: React.ReactNode;
}

const OrganizationProvider = ({ children }: OrganizationProviderProps) => {
    const [organizationConfig, setOrganizationConfig] = useState<OrganizationConfigDto | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchOrganizationConfig = async () => {
            setIsLoading(true);

            const config = await getOrganizationConfigByCurrentDomain();

            setOrganizationConfig(config);
            setIsLoading(false);
        };

        fetchOrganizationConfig();
    }, []);

    if (isLoading || !organizationConfig) {
        return <Loader />;
    }

    const gigTerminology = organizationConfig.gigTerminology;
    const giggedClientTerminology = organizationConfig.giggedClientTerminology;
    const talentTerminology = organizationConfig.talentTerminology;

    return (
        <OrganizationContext.Provider value={{
            organizationConfig,
            theme: organizationConfig.theme,
            gigTerminology: gigTerminology,
            gigTerminologyPlural: pluralizeString(gigTerminology),
            giggedClientTerminology: giggedClientTerminology,
            giggedClientTerminologyPlural: pluralizeString(giggedClientTerminology),
            talentTerminology: talentTerminology,
            talentTerminologyPlural: talentTerminology.toLowerCase() === "talent" ? talentTerminology : pluralizeString(talentTerminology)
        }}>
            {children}
        </OrganizationContext.Provider>
    );
};

export default OrganizationProvider;