import clsx from "clsx";
import { Briefcase, Eye, PencilSimple } from "phosphor-react";
import { Link } from "react-router-dom";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalentDashboard } from "../../api/talentDashboard";
import Card from "../../components/Card";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import Loader from "../../components/Loader";
import ProgressIndicator from "../../components/ProgressIndicator";
import NylasCalendar from "../../components/Nylas/NylasCalendar";
import { deliveryTypeOptions } from "../../models/app/gig";
import NylasAuth from "../../components/Nylas/NylasAuth";
import { formatLocationDateTimeToString } from "../../utils/dateFormatters";

const TalentDashboardPage = () => {
    const appPaths = useAppPaths();
    const {
        dashboardData,
        isLoading,
    } = useTalentDashboard();

    const {
        gigTerminologyPlural,
        organizationConfig: { theme },
    } = useOrganizationContext();

    
    if (isLoading || !dashboardData) {
        return <Loader />;
    }

    const isProfileComplete = dashboardData.profileHealthScore === 100;
    const isCalendarConnected = dashboardData.isCalendarConnected;

    return (
        <PageContent>
            <Card className="space-y-6">
                <Card className="bg-white flex flex-row justify-between items-center md:max-w-[33%]">
                    <div className="space-y-4 w-64">
                        <Typography component="h2" variant="title-large">Hi, {dashboardData.firstName}!</Typography>
                        <div className="flex flex-col space-y-2">
                            <div>
                                <Link to={appPaths.profile.edit} className="flex flex-row space-x-2 items-center">
                                    {isProfileComplete && <PencilSimple size={20} />}
                                    <div className="underline font-bold">{isProfileComplete ? "Edit my profile" : "Complete your profile"}</div>
                                </Link>
                            </div>
                            {!isProfileComplete && <ProgressIndicator value={dashboardData.profileHealthScore} />}
                        </div>
                    </div>
                </Card>
                <div className={`space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow ${theme === "black" ? "text-white" : ""}`}>
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Accepted ${gigTerminologyPlural}`}
                        count={dashboardData.ongoingGigsCount}
                        href={appPaths.gigs.ongoing}
                    />
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Completed ${gigTerminologyPlural}`}
                        count={dashboardData.completedGigsCount}
                        href={appPaths.gigs.completed}
                    />
                </div>
                <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:items-start">
                    <Card className={clsx(
                        "!bg-white",
                        "md:w-full",
                        dashboardData.ongoingGigs.length === 0 && "flex flex-col min-h-[360px]"
                    )}>
                        <Typography variant="headline-small" component="h2" className="mb-6 flex-none">Accepted {gigTerminologyPlural}</Typography>
                        {dashboardData.ongoingGigs.length === 0 ? (
                            <div className="flex-1 flex justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <img
                                        src="/gigs-empty-state-icon.svg"
                                        alt="No ongoing gigs icon"
                                    />
                                    <p className="mt-2 opacity-60">No ongoing gigs</p>
                                </div>
                            </div>
                        ) : (
                            <DashboardTable
                                data={dashboardData.ongoingGigs}
                                headings={[
                                    "Client name",
                                    "Product",
                                    "Start date",
                                    "Expected duration",
                                    "Delivery type",
                                    "Location",
                                    "",
                                ]}
                                renderRowCells={(gig) => (
                                    <>
                                        <td>{gig.title}</td>
                                        <td>{gig.skills[0].name}</td>
                                        <td>{formatLocationDateTimeToString(gig.startDate, gig.ianaId)}</td>
                                        <td>{gig.expectedDurationLabel}</td>
                                        <td>{deliveryTypeOptions[gig.deliveryTypeId].label}</td>
                                        <td>{`${gig.location?.locality.locality}${gig.location?.locality.region ? ` (${gig.location?.locality.region})` : ""}, ${gig.location?.country}`}</td>
                                        <td>
                                            <Link to={appPaths.gigs.details(gig.id)}>
                                                <Eye size={20} />
                                            </Link>
                                        </td>
                                    </>
                                )}
                            />
                        )}
                    </Card>
                </div>
                
                <>
                    {isCalendarConnected && (
                        <Card className="space-y-6 mt-6 bg-white w-full">
                            <NylasCalendar />
                        </Card>
                    )}
                    <NylasAuth isCalendarConnected={isCalendarConnected} />
                </>
            </Card>
        </PageContent>
    );
};

export default TalentDashboardPage;