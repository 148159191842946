import React from "react";
import { OrganizationConfigDto, OrganizationConfigTheme } from "../../models/api/organization";

export type OrganizationContextType = {
    organizationConfig: OrganizationConfigDto
    theme: OrganizationConfigTheme
    gigTerminology: string
    gigTerminologyPlural: string
    giggedClientTerminology: string
    giggedClientTerminologyPlural: string
    talentTerminology: string
    talentTerminologyPlural: string
};

export const OrganizationContext = React.createContext<OrganizationContextType>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    organizationConfig: undefined!,
    theme: "default",
    gigTerminology: "Gig",
    gigTerminologyPlural: "Gigs",
    giggedClientTerminology: "Client",
    giggedClientTerminologyPlural: "Clients",
    talentTerminology: "Talent",
    talentTerminologyPlural: "Talents"
});

export const useOrganizationContext: () => OrganizationContextType = () => React.useContext(OrganizationContext);

export default OrganizationContext;