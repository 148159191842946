import { useState } from "react";

export type DialogBoxState = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    open: () => void,
    close: () => void,
    onOpen: () => void,
    onClose: () => void,
}

const useDialogBoxState = (): DialogBoxState => {
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        setIsOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
    };
};

export default useDialogBoxState;
