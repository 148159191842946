import getFileExtensionFromName from "./getFileExtensionFromName";

const fileHasAllowedExtension = (name: string, allowedExtensions: string[]): boolean => {
    if (!name) return false;
    
    const type = getFileExtensionFromName(name);

    return allowedExtensions.includes(type);
};

export default fileHasAllowedExtension;
