import { DownloadSimple, File } from "phosphor-react";
import config from "../configuration";
import { getSizeDescriptionFromBytes } from "../utils/getSizeDescriptionFromBytes";
import urlHasAllowedExtension from "../utils/urlHasAllowedExtension";

type FilePreviewProps = {
    url: string
    name: string
    totalBytes: number
}

const FilePreview = ({
    url,
    name,
    totalBytes,
}: FilePreviewProps) => {
    const isImage = urlHasAllowedExtension(url, config.allowedImageFileExtensions);

    return ( 
        <div className="h-20 justify-between flex flex-row border border-surface rounded-lg p-5 bg-white w-full">
            <div className="flex justify-center items-center h-10 w-10 bg-white rounded-md mb-4">
                {isImage ? <img src={url} /> : <File size="20" />}
            </div>    
            <div className="text-chip text-left mx-4 flex flex-col max-w-[50%] sm:max-w-[80%]">
                <span className="font-bold">File size: {getSizeDescriptionFromBytes(totalBytes)}</span>
                <span className="truncate">{name || "File"}</span>
            </div>
            <a href={url} className="self-center">
                <DownloadSimple size="20" color="gray"/>
            </a>            
        </div>
    );
};
 
export default FilePreview;