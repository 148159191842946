import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import { formClassNames } from "./classNames";
import { deliveryTypeOptions } from "../../models/app/gig";
import FormDropdown, { FormDropdownOption, LocalityDropdownOption } from "../FormDropdown";
import { useEffect, useState } from "react";
import useAccessToken from "../../auth/useAccessToken";
import { fetchLocationOptions } from "../../api/locations";

export type CreateGigFormStep5Fields = Pick<GigFormValues, "deliveryTypeId" | "locationId" | "localityId" | "ianaId">;

export type CreateGigFormStep5Props = {
    onSubmit: (value: CreateGigFormStep5Fields) => void
    onBackClick: (value: CreateGigFormStep5Fields) => void
    defaultValues: CreateGigFormStep5Fields
}

const CreateGigFormStep5 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep5Props) => {
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<LocalityDropdownOption<string>[]>([]);
    const accessToken = useAccessToken();

    const validationSchema: yup.SchemaOf<CreateGigFormStep5Fields> = yup.object({
        deliveryTypeId: buildGigFormValidationSchemaFields.deliveryTypeId,
        locationId: buildGigFormValidationSchemaFields.locationId,
        localityId: buildGigFormValidationSchemaFields.localityId,
        ianaId: buildGigFormValidationSchemaFields.ianaId
    });

    const methods = useForm<CreateGigFormStep5Fields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    useEffect(function setLocationOptions() {
        if (!accessToken) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (selectedLocationLocalities.length < 1 || defaultValues.locationId === selectedLocationId || isLoadingLocationOptions) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId, defaultValues]);

    const handleLocalityChange = (value: string) => {
        const selectedLocality = selectedLocationLocalities.find(locality => locality.value === value);
        const selectedIanaId = selectedLocality?.ianaId || undefined;
    
        methods.setValue("localityId", value);
        methods.setValue("ianaId", selectedIanaId);
    };

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Location"
                description="Specify the delivery type of the event and where the client is located."
                content={
                    <span className="space-y-6">
                        <Controller
                            name="deliveryTypeId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="What is the required delivery format for this event?"
                                    options={deliveryTypeOptions}
                                    error={methods.formState.errors.deliveryTypeId}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        {allLocationOptions?.length > 0 ? (
                            <Controller
                                name="locationId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="What country is the client located in?"
                                        value={value || ""}
                                        options={allLocationOptions}
                                        disabled={isLoadingLocationOptions}
                                        onChange={onChange}
                                        error={methods.formState.errors.locationId}
                                        isLoadingOptions={allLocationOptions.length < 1 || isLoadingLocationOptions}
                                    />
                                )}
                            />
                        ) : <div>Loading...</div>}
                        {selectedLocationLocalities?.length > 0 && (
                            <Controller
                                name="localityId"
                                control={methods.control}
                                render={({ field: { value } }) => (
                                    <FormDropdown
                                        required
                                        label="City"
                                        value={value || ""}
                                        options={selectedLocationLocalities.map(locality => ({
                                            value: locality.value,
                                            label: locality.label,
                                            ianaId: locality.ianaId
                                        }))}
                                        disabled={selectedLocationLocalities.length < 1 || isLoadingLocationOptions}
                                        onChange={handleLocalityChange}
                                        error={methods.formState.errors.localityId}
                                        isLoadingOptions={selectedLocationLocalities.length < 1}
                                    />
                                )}
                            />
                        )}
                    </span>
                }
                iconName="MapPin"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep5;