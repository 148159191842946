import { useMsalAuthentication } from "@azure/msal-react";
import { b2cPolicies } from "../auth/authConfig";
import { useAuthContext } from "../auth/authContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import Loader from "./Loader";
import useCurrentUser from "../auth/useCurrentUser";
import { InteractionType } from "@azure/msal-browser";

const LoginPage = () => {
    const { loginRequest } = useAuthContext();
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        ...loginRequest,
    });

    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const user = useCurrentUser();
    const { userRole, idpUserId } = user;
    const isAdmin = !userRole && idpUserId;
    const [retryAttempted, setRetryAttempted] = useState(false);

    useEffect(() => {
        if (result) {
            isAdmin ? navigate(appPaths.gigs.findAGig) : navigate(appPaths.dashboard);
        } else if (error && !retryAttempted) {
            setRetryAttempted(true);
            login(InteractionType.Redirect);
        }
    }, [result, error, navigate, isAdmin]);

    if (error) {
        return (
            <div className="flex flex-col">
                <div>Sorry, an error occurred during the authentication process.</div>
                <div>
                    Please refresh your browser, clear your cache, and try again. If you are still experiencing issues, please <a className="underline" href="mailto:customersuccess@gigged.ai">contact support.</a>
                </div>
            </div>
        );
    }

    if (!result) return <Loader />;

    return null;
};

export default LoginPage;