import _ from "lodash";
import { ApiArchivedChatDto } from "../../../models/api/chatArchive";
import { truncateMiddle, calculateUnreadMessagesWidth } from "../../../utils/conversationsHelpers";
import formatDateOnlyString from "../../../utils/formatDateString";

export type ArchiveConversationListItemProps = {
    archivedChat: ApiArchivedChatDto
    onClick: () => void
};

const ArchiveConversationListItem = ({ archivedChat, onClick }: ArchiveConversationListItemProps) => {
    const title = truncateMiddle(
        `${archivedChat.user.firstName} ${archivedChat.user.lastName}`,
        calculateUnreadMessagesWidth(0)
    );

    const lastMessage = _.last(archivedChat.archivedChatMessages);

    return (
        <div
            onClick={onClick}
            className="flex flex-row justify-between w-full p-6 items-center border-surface border-b-[1px] min-h-[102px] cursor-pointer space-x-6"
            key={"archivedchat-" + archivedChat.user.id}
        >
            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {title}
                <p className="truncate mt-2 text-secondary-text">{lastMessage?.messageText}</p>
            </div>
            <div className="flex flex-col items-end whitespace-nowrap">
                <p className="text-secondary-text">{formatDateOnlyString(lastMessage?.sentDate)}</p>
            </div>
        </div>
    );
};

export default ArchiveConversationListItem;