import { ApiTalentCertificationCreateUpdateDto, ApiTalentCertificationDto } from "../models/api/talentCertification";
import { TalentCertificationCreateUpdateDto, TalentCertificationDto } from "../models/app/talentCertification";
import { mapFromApiTalentCertificationDto, mapToApiTalentCertificationCreateUpdateDto } from "../models/mappers/talentCertification";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

export const useAddTalentCertification = () => useAuthenticatedGigApiFetcher<ApiTalentCertificationCreateUpdateDto, string, TalentCertificationCreateUpdateDto>("POST",
    (dto) => ({
        url: "api/me/certifications",
        body: mapToApiTalentCertificationCreateUpdateDto(dto)
    })
);

export const useRemoveTalentCertification = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (certificationId: string) => ({
        url: `api/me/certifications/${certificationId}`,
    })
);

export const useUpdateTalentCertification = () => useAuthenticatedGigApiFetcher<ApiTalentCertificationCreateUpdateDto, void, { certificationId: string, dto: TalentCertificationCreateUpdateDto }>("PUT",
    ({ certificationId, dto }) => ({
        url: `api/me/certifications/${certificationId}`,
        body: mapToApiTalentCertificationCreateUpdateDto(dto)
    }));

export type UseTalentCertificationsReturn =
    UseGiggedApiSWRReturn<ApiTalentCertificationDto[]> &
    {
        certifications: TalentCertificationDto[]
        addCertification: (dto: TalentCertificationCreateUpdateDto) => Promise<GigApiFetcherResponse<string>>
        removeCertification: (id: string) => Promise<GigApiFetcherResponse<void>>
        updateCertification: (id: string, dto: TalentCertificationCreateUpdateDto) => Promise<GigApiFetcherResponse<void>>
    }

export const useTalentCertifications = (): UseTalentCertificationsReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentCertificationDto[]>("api/me/certifications");
    const [addCertification] = useAddTalentCertification();
    const [removeCertification] = useRemoveTalentCertification();
    const [updateCertification] = useUpdateTalentCertification();

    const certifications = apiCall?.data ? apiCall.data.map(mapFromApiTalentCertificationDto) : [];

    return {
        ...apiCall,
        certifications,
        addCertification: async (dto) => {
            const response = await addCertification(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        removeCertification: async (id) => {
            const response = await removeCertification(id);

            if (response.success) apiCall.mutate();

            return response;
        },
        updateCertification: async (certificationId, dto) => {
            const response = await updateCertification({ certificationId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
    };
};