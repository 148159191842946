import { useRef } from "react";
import { ApiArchivedChatDto } from "../../../models/api/chatArchive";
import ArchiveMessageList from "./ArchiveMessageList";

export type ArchiveMessagesBoxProps = {
    archivedChat: ApiArchivedChatDto
};

const ArchiveMessagesBox = ({
    archivedChat,
}: ArchiveMessagesBoxProps) => {
    const listRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className="flex flex-col-reverse overflow-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar"
        >
            <div ref={listRef} className="flex flex-col justify-end items-end h-[540px]">
                <ArchiveMessageList
                    archivedChat={archivedChat}
                />
            </div>
        </div>
    );
};

export default ArchiveMessagesBox;