import FindTalentContextProvider from "./FindTalentContextProvider";
import FindTalentPageComponent from "./FindTalentPageComponent";

const FindTalentPage = () => {
    return (
        <FindTalentContextProvider>
            <FindTalentPageComponent />
        </FindTalentContextProvider>
    );
};

export default FindTalentPage;
