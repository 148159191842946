import { useOrganizationContext } from "../api/current-organization/organizationContext";

const OrganizationLogo = () => {
    const { organizationConfig } = useOrganizationContext();

    if (organizationConfig && organizationConfig?.logoUrl) {
        return (
            <img
                className="block md:max-w-[120px] max-h-[30px] md:max-h-[40px] mr-auto"
                src={organizationConfig.logoUrl}
                alt="Organisation logo"
            />
        );
    }
    return (
        <img
            className="block max-h-[25px] w-auto mr-auto"
            src="/gigged-logo.svg"
            alt="Gigged.ai logo"
        />
    );
};

export default OrganizationLogo;
