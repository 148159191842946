import clsx from "clsx";
import { Check } from "phosphor-react";
import { MessageStatuses } from "../../../../api/conversations";
import { ReduxMedia } from "../../../../store/reducers/messageListReducer";
import MessageMedia from "../MessageMedia";

type MessageTypeDefaultProps = {
    messageContent: JSX.Element[],
    messageTime: string,
    participantName: string,
    isMyMessage?: boolean
    messageStatus: MessageStatuses
    messageMedia: ReduxMedia[] | null
    onDownload: () => Promise<Error | undefined>
    onOpen: (mediaSid: string, file: ReduxMedia) => void
}

const MessageTypeDefault = ({
    messageContent,
    messageTime,
    participantName,
    isMyMessage,
    messageStatus,
    messageMedia,
    onDownload,
    onOpen
}: MessageTypeDefaultProps) => {
    
    return ( 
        <div className={clsx("flex flex-col m-8", isMyMessage ? "items-end text-right" : "items-start text-left")}>
            <div className="flex flex-col space-y-2 max-w-[80%]">
                <p>{participantName}</p>
                <div
                    className={clsx("p-4 bg-white rounded-lg  min-w-[6rem] drop-shadow-lg text-left break-words",
                        isMyMessage ? "rounded-br-none" : "rounded-bl-none"
                    )}
                >
                    {messageContent}
                    {
                        messageMedia && messageMedia?.length > 0 ? 
                            <>
                                <br/>
                                <MessageMedia 
                                    messageMedia={messageMedia} 
                                    onDownload={onDownload}
                                    onOpen={onOpen}
                                />
                            </>
                            :   null
                    }
                </div>
                <div className="flex justify-end text-right text-secondary-text gap-3">
                    {messageTime}
                    {
                        (!messageStatus.Sending || messageStatus.Failed) ? <Check size={25} /> : <></>
                    }
                </div>
            </div>
        </div>
    );
};
 
export default MessageTypeDefault;