import clsx from "clsx";
import { Briefcase } from "phosphor-react";
import { Link } from "react-router-dom";
import Typography from "../Typography";


export type LargeDashboardButtonProps = {
    Icon: typeof Briefcase
    name: React.ReactNode
    href: string
    variant?: "dark" | "light"
    disabled?: boolean
    handleClick?: () => void
}

const LargeDashboardButton = ({
    Icon,
    name,
    href,
    variant,
    disabled,
    handleClick
}: LargeDashboardButtonProps) => {

    return (
        <Link
            to={!disabled ? href : "#"}
            onClick={handleClick}
            className={clsx(
                "flex flex-col bg-white h-48 p-6 rounded-lg child:cursor-pointer w-full md:max-w-[33%]",
                variant === "dark" && "!bg-secondary",
            )}>
            <Icon
                size={32}
                weight="duotone"
                className={clsx(
                    variant === "dark" && "text-secondary-bg-text"
                )}
            />
            <Typography
                variant="headline-small"
                component="label"
                className={clsx(
                    "mt-auto",
                    variant === "dark" && "text-secondary-bg-text",
                )}
            >
                {name}
            </Typography>
        </Link>
    );
};

export default LargeDashboardButton;
