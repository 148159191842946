import { Play, X } from "phosphor-react";
import { getYouTubeThumbnail } from "../../utils/getYouTubeThumbnail";

type TalentProfileVideoProps = {
    url: string
    onRemoveClick?: () => void
}

const TalentProfileVideo = ({
    url,
    onRemoveClick
}: TalentProfileVideoProps) => {
    const thumbnail = getYouTubeThumbnail(url, "low") ?? "https://via.placeholder.com/300?text=Video";

    return ( 
        <div 
            className="flex flex-col items-stretch justify-between border border-surface rounded-lg w-full md:w-1/4 sm:w-2/5 bg-white"
        >
            <a 
                href={url} 
                target="_blank"
                className="relative h-full bg-black rounded-t-lg"
            >
                {thumbnail ? 
                    <img
                        src={thumbnail} 
                        className="rounded-t-lg object-fit max-h-36 min-w-full brightness-75 hover:brightness-90"
                    /> : 
                    <div className="rounded-t-lg object-fit h-32 min-w-full"></div>
                }
                <Play 
                    className="absolute top-1/2 left-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform text-white pointer-events-none"
                    size={32}
                    weight="fill"
                />          
            </a>
            <div className="flex flex-row px-2 bg-white rounded-b-lg w-full">
                <div className="flex flex-col truncate w-3/4 py-[0.25rem]">
                    <span className="font-bold text-label-small">Video</span>
                    <a href={url} className="text-label-small" target="_blank">{url}</a>
                </div>
                {onRemoveClick && (
                    <button 
                        type="button"
                        className="py-1 cursor-pointer rounded-full flex justify-end w-1/4"                    
                    >
                        <X
                            weight="bold"
                            className="w-4 h-full"
                            aria-hidden="true"
                            onClick={onRemoveClick}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};
 
export default TalentProfileVideo;