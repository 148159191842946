export type ExternalLinkButtonProps = {
    children: React.ReactNode
    href: string
    target: string
}

const ExternalLinkButton = ({
    children, 
    href,
    target
}: ExternalLinkButtonProps) => {

    return (
        <a
            className="w-fit font-bold py-[0.75rem] px-[1.5rem] rounded-full disabled:text-disabled-text focus:outline whitespace-nowrap text-primary-bg-text bg-primary hover:bg-primary-hover focus:outline-3 transition-colors ease-in-out duration-200"
            href={href}
            target={target}
        >
            {children}
        </a>
    );
};

export default ExternalLinkButton;