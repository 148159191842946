import { Envelope } from "phosphor-react";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import getUnreadMessagesCount from "../../utils/getUnreadMessagesCount";

const NotificationEnvelope = () => {
    const unreadMessageCount = getUnreadMessagesCount();
    const appPaths = useAppPaths();

    return (
        <div className="relative flex items-center">
            <Link to={unreadMessageCount && unreadMessageCount > 0 ? appPaths.inbox.index : "/"}>
                <Envelope size={26} />
                {unreadMessageCount > 0 && (
                    <span
                        className="bg-primary rounded-full text-white text-[0.8rem] px-1.5 whitespace-nowrap absolute top-[-4px] padding-6 left-3"
                    >
                        {unreadMessageCount}
                    </span>
                )}
            </Link>
        </div>
    );
};

export default NotificationEnvelope;