import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const createDisputeFormValidationSchema = (gigTerminology: string) => {
    return yup.object({
        gigId: yup.string().required(`${gigTerminology} is required.`),
        reason: yup.string().required("Reason is required."),
        description: yup.string().required("Description is required.")
    });
};

export type CreateDisputeFormValues = {
    gigId: string
    reason: string
    description: string
}

export const useCreateDisputeForm = (gigTerminology: string, defaultValues?: CreateDisputeFormValues) => {
    return useForm<CreateDisputeFormValues>({
        resolver: yupResolver(createDisputeFormValidationSchema(gigTerminology)),
        defaultValues: {
            gigId: defaultValues?.gigId || "",
            reason: defaultValues?.reason || "",
            description: defaultValues?.description || "",
        }
    });
};

