import { Link } from "react-router-dom";
import AuditDataList from "../../components/AuditData/AuditDataList";
import Card, { CardDataField, CardDataFieldLabel } from "../../components/Card";
import LinkButton from "../../components/LinkButton";
import Typography from "../../components/Typography";
import { deliveryTypeOptions, GigSummary } from "../../models/app/gig";
import { useAppPaths } from "../../Routes";
import DateDisplayLocale from "../../components/DateDisplayLocale";
import { formatLocationDateToString, formatLocationTimeToString } from "../../utils/dateFormatters";
import Tooltip from "../../components/Tooltip";

export type PostedGigsCardProps = {
    gig: GigSummary
}

const PostedGigsCard = ({
    gig
}: PostedGigsCardProps) => {
    const appPaths = useAppPaths();
    return (
        <div key={gig.id} className="flex flex-col md:flex-row items-stretch break-words">
            <Card key={gig.id} className="flex flex-col text-center md:text-left md:flex-row grow items-center rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                <div className="space-y-2 md:mr-8">
                    <Typography variant="title-large" component="h3" className="max-w-md">{gig.title}</Typography>
                    <Typography variant="title-small" component="h3">{`${gig.location?.locality.locality}${gig.location?.locality.region ? ` (${gig.location?.locality.region})` : ""}, ${gig.location?.country}`}</Typography>
                    <div className="flex sm:space-x-8 flex-col sm:flex-row gap-2 pt-2">
                        <CardDataField label="Insights workshop type">{gig.skills[0]?.name}</CardDataField>
                        <CardDataField label="Delivery format">{deliveryTypeOptions[gig.deliveryTypeId].label}</CardDataField>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 pt-2 justify-items-center md:justify-items-start">
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Calendar" textClass="font-normal">{formatLocationDateToString(gig.startDate, gig.ianaId)}</CardDataFieldLabel>
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Clock" textClass="font-normal">
                            <div className="flex items-center">
                                {formatLocationTimeToString(gig.startDate, gig.ianaId)}
                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                            </div>
                        </CardDataFieldLabel>
                    </div>
                    <AuditDataList referenceNumber={gig.referenceNumber} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} />
                </div>
                <LinkButton className="md:ml-auto" variant="tertiary" to={appPaths.gigs.edit(gig.id)}>Edit</LinkButton>
                <LinkButton className="md:ml-4" variant="secondary" to={appPaths.gigs.matches(gig.id)}>View matches</LinkButton>
            </Card>
            <Link to={appPaths.gigs.gigInvites(gig.id)} className="flex flex-col justify-center md:ml-4 md:min-w-[15%]">
                <Card className="flex items-center h-full justify-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                    <div>
                        <p className="text-primary-hover text-center font-extrabold text-title-large">{gig.invitesCount}</p>
                        <p>{gig.invitesCount === 1 ? "Invite" : "Invites"}</p>
                    </div>
                </Card>
            </Link>
        </div>
    );
};

export default PostedGigsCard;